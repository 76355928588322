
import { Component, Vue } from 'vue-property-decorator';
import { companyRoutes as policyCompanyRoutes } from '@/router/customer-routes/policy/policy-routes';
import { RouteConfig } from 'vue-router';
import SidebarPopup from '@/components/SidebarPopup.vue';
import CompanyInfo from './CompanyInfo.vue';

@Component({ components: { SidebarPopup, CompanyInfo } })
export default class CompanyMenu extends Vue {
  get menuTabs(): Array<RouteConfig & { disabled?: boolean }> {
    const enabledRoutes = this.$store.getters.enabledRouteNames;
    return policyCompanyRoutes.map((route) => {
      const { name } = route;
      if (!enabledRoutes.find((enabledName: string) => enabledName === name)) {
        return { ...route, disabled: true };
      }
      return route;
    });
  }

  get isAdvisory() {
    return this.$store.getters.isAdvisoryUser;
  }

  get companyMenuTitle() {
    return this.isAdvisory ? 'Company Information' : 'Company and Policy Information';
  }

  get currentCompanyId() {
    return this.$store.getters.companyInfo.companyID;
  }

  isHidden(item: RouteConfig) {
    return item?.meta?.tabVisibility === false;
  }
}
