const RANGECOND_cydata = 'RawCyberData#'; // ✅ Cy Date page

import * as ddb from '../ddbUtils-config';
import { CyRow } from '@/types';
import { _queryJSON } from '../utils';

export const queryCompanyCyData = async (companyID: string): Promise<CyRow[]> => {
  const q = _queryJSON(companyID, RANGECOND_cydata);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any): CyRow => {
    const newRow: CyRow = {
      companyID: el.CompanyID,
      timeStamp: new Date(el.ChTimestamp),
      clients: +el.Clients,
      pages: { low: 0, mid: 0, high: 0 },
      markets: { low: +el.MarketsLow, mid: +el.MarketsMed, high: +el.MarketsHigh },
      forums: { low: +el.ForumsLow, mid: +el.ForumsMed, high: +el.ForumsHigh },
      pastes: { low: +el.PastesLow, mid: +el.PastesMed, high: +el.PastesHigh },
      exits: { low: +el.ExitsLow, mid: +el.ExitsMed, high: +el.ExitsHigh },
      osint: { low: +el.OsintLow, mid: +el.OsintMed, high: +el.OsintHigh },
    };
    return newRow;
  });
  return mappedResult;
};
