import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { ChTimeItem, getCbhTimeItem } from '@/utilities';

const RANGECOND_MICRO_TREND = 'MicroTrend#';

export interface MicroTrendItem {
  Sector: string;
  MediumRiskMedian: number;
  RelativeRawRank: number;
  CompanyID: string;
  Trend: number;
  LowRiskMedian: number;
  LowRiskLowerBound: number;
  HighestRiskHigherBound: number;
  HighRiskMedian: number;
  MediumHighRiskMedian: number;
  HighestRiskMedian: number;
  ExpirationTime: number;
  DataType: string;
  Grouping: string;
  CreationTime: number;
  CompanyName: string;
  RelativeRank: number;
  date: ChTimeItem;
  HighToHighestRiskSplit: number;
  MediumHighToHighRiskSplit: number;
  MediumToMediumHighRiskSplit: number;
  LowToMediumRiskSplit: number;
  LowRiskBreachProbability: number;
  MediumRiskBreachProbability: number;
  MediumHighRiskBreachProbability: number;
  HighRiskBreachProbability: number;
  HighestRiskBreachProbability: number;
  LowRiskCount: number;
  MediumRiskCount: number;
  MediumHighRiskCount: number;
  HighRiskCount: number;
  HighestRiskCount: number;
}

const parseMicroTrendItem = (item: any): MicroTrendItem => ({
  ...item,
  date: getCbhTimeItem(item['ChTimestamp']),
  Trend: Number(item['Trend']),
  RelativeRawRank: Number(item['RelativeRawRank']),
  RelativeRank: Number(item['RelativeRank']),
  MediumRiskMedian: Number(item['MediumRiskMedian']),
  LowRiskMedian: Number(item['LowRiskMedian']),
  LowRiskLowerBound: Number(item['LowRiskLowerBound']),
  HighestRiskHigherBound: Number(item['HighestRiskHigherBound']),
  HighRiskMedian: Number(item['HighRiskMedian']),
  MediumHighRiskMedian: Number(item['MediumHighRiskMedian']),
  HighestRiskMedian: Number(item['HighestRiskMedian']),
  HighToHighestRiskSplit: Number(item['HighToHighestRiskSplit']),
  MediumHighToHighRiskSplit: Number(item['MediumHighToHighRiskSplit']),
  MediumToMediumHighRiskSplit: Number(item['MediumToMediumHighRiskSplit']),
  LowToMediumRiskSplit: Number(item['LowToMediumRiskSplit']),
  LowRiskBreachProbability: Number(item['LowRiskBreachProbability']),
  MediumRiskBreachProbability: Number(item['MediumRiskBreachProbability']),
  MediumHighRiskBreachProbability: Number(item['MediumHighRiskBreachProbability']),
  HighRiskBreachProbability: Number(item['HighRiskBreachProbability']),
  HighestRiskBreachProbability: Number(item['HighestRiskBreachProbability']),
  LowRiskCount: Number(item['LowRiskCount']),
  MediumRiskCount: Number(item['MediumRiskCount']),
  MediumHighRiskCount: Number(item['MediumHighRiskCount']),
  HighRiskCount: Number(item['HighRiskCount']),
  HighestRiskCount: Number(item['HighestRiskCount']),
});

@Module
class MicroTrendModule extends VuexModule {
  private trendList: MicroTrendItem[] = [];

  @Action
  public async fetchCompanyMicroTrend() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_MICRO_TREND)
      .then((data) => {
        if (data.length > 0) {
          store.commit('setMicroTrendList', data);
        } else {
          throw new Error(RANGECOND_MICRO_TREND + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        store.commit('setMicroTrendList', []);
      });
  }

  @Mutation
  public setMicroTrendList(data: any[]) {
    if (data.length > 0) {
      const dataMap: Map<string, MicroTrendItem> = data.reduce(
        (pre: Map<string, MicroTrendItem>, cur) => {
          const item = parseMicroTrendItem(cur);
          if (item.date) {
            pre.set(cur.ChTimestamp, item);
          }
          return pre;
        },
        new Map<string, MicroTrendItem>()
      );
      this.trendList.splice(
        0,
        this.trendList.length,
        ...Array.from(dataMap.values()).sort((a, b) => {
          return a.date.timestamp - b.date.timestamp;
        })
      );
    } else {
      this.trendList.splice(0, this.trendList.length);
    }
  }

  get microTrendList(): MicroTrendModule['trendList'] {
    return this.trendList;
  }
}
export default MicroTrendModule;
