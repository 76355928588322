import { Tooltip, tooltipObject } from '../Tooltip';
import { Point } from '../../Point';

export const drawSimpleLabel = function (this: Tooltip, t: tooltipObject) {
  t.ctx.strokeStyle = this._options.lineColor;
  t.ctx.lineWidth = this._options.lineWidth;
  t.ctx.fillStyle = this._options.brushColor;
  t.ctx.setLineDash(this._options.lineDash);
  const labelText = this.labels && this.labels[t.ind] ? this.labels[t.ind] : '';
  const labelCoord = new Point(t.coord.x, t.coord.y);
  this.label.draw(t.ctx, labelCoord, labelText);
};
