import * as ddb from '../ddbUtils-config';
import { CyFiStatsRow } from '@/types';
import { _queryJSON, parseCyFiStatsRowMeta } from '../utils';

const RANGECOND_sectorPeers = 'CompanySectorPeers#current#'; //CompanySectorPeers#2021-08-01#549300RSG5KPUH16WH66_lei

// ✅ Sector Peers
export const querySectorPeers = async (
  companyID: string
): Promise<{ row: CyFiStatsRow; companyName: string }[]> => {
  const q = _queryJSON(companyID, RANGECOND_sectorPeers);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const cyfiStatsList = items.map((row: any) => {
    const newRow = parseCyFiStatsRowMeta(row, q);
    return { row: newRow, companyName: row.CompanyName };
  });
  return cyfiStatsList;
};
