import { store } from '../index';

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getCompanyList } from '../../api-rosie/companyList';
import { Company } from '@/types';

type viewedCompanyInfo = {
  companyId: string;
  companyName: string;
  logoUrl: string | null;
  domen: string | null;
};

const storage = window.localStorage;

@Module
class Companies extends VuexModule {
  private companyList: Company[] = [];
  private viewedList: viewedCompanyInfo[] = [];

  @Action
  public cleanUpCompaniesInitialData() {
    store.commit('setCompanyList', []);
    store.commit('setViewedList', []);
  }

  @Action
  public async loadCompanies() {
    return getCompanyList().then((list) => {
      store.commit('setCompanyList', list);
    });
  }

  @Mutation
  public setCompanyList(companies: Company[]) {
    this.companyList.splice(0, this.companyList.length, ...companies);
    console.info('💽 stored:', this.companyList.length, 'companies');
  }

  get allCompanies(): Company[] {
    return this.companyList;
  }

  get viewedListKey(): string {
    const username = store.getters.username;
    return `viewedList_${username}`;
  }

  @Action loadViewedList() {
    const storagedList = storage.getItem(this.viewedListKey);
    if (storagedList) {
      const list = JSON.parse(storagedList);
      const filteredList = JSON.parse(storagedList).filter((cmp: viewedCompanyInfo) => {
        if (cmp.companyId === '') return false;
        const find: Company | undefined = store.getters.allCompanies.find(
          (el: Company) => el.companyID === cmp.companyId
        );
        return find !== undefined;
      });
      store.commit('setViewedList', filteredList);
    }
  }

  @Mutation setViewedList(list: viewedCompanyInfo[]) {
    this.viewedList.splice(0, this.viewedList.length, ...list);
  }

  @Action appendViewedList(item: viewedCompanyInfo) {
    const list = JSON.parse(JSON.stringify(this.viewedList));
    const duplicateInd = list.findIndex((v: viewedCompanyInfo) => v.companyId === item.companyId);
    if (duplicateInd !== -1) {
      list.splice(duplicateInd, 1);
    }
    list.splice(0, 0, item);
    storage.setItem(this.viewedListKey, JSON.stringify(list));
    store.commit('setViewedList', list);
  }

  get previouslyViewedList(): viewedCompanyInfo[] {
    return this.viewedList;
  }
}
export default Companies;
