import { Point } from '../Point';
import { Rectangle } from '../Rectangle';
import { Tooltip } from '../tooltip/Tooltip';
import { Label } from '../Label';
import { MetaItem } from '../series/SeriesXY';

import { drawBarChart } from './drawings/bar-chart';
import { drawPolicyCircles } from './drawings/policy_circles';
import { drawPolicyBackground } from './drawings/policy_background';
import { drawPolicyBackgroundGradient } from './drawings/policy_background_gradient';
import { drawPolicyStroke } from './drawings/policy_stroke';
import { drawPolicyBackgroundPattern } from './drawings/policy_background_pattern';
import { line } from './drawings/line';
import { lineAreaVertical } from './drawings/line-area-vertical';
import { drawTriangleCursorX } from './drawings/triangle-cursor-x';
import { drawLabelX } from './drawings/label-x';
import { drawLabelXTop } from './drawings/label-x-top';
import { drawPolicyBars } from './drawings/policy_bars';
import { drawPolicyBarsChart } from './drawings/policy_bars_chart';
import { drawPolicyGradientChart } from './drawings/policy_gradient_chart';
import { drawCircle } from './drawings/circle';
import { drawArrowsWithAngle } from './drawings/arrows_with_angle';
import { drawCurveLine } from './drawings/curve-line';
import { drawBarsRounded } from './drawings/bars_rounded';
import { lineTrianglesVertical } from './drawings/line-triangles-vertical';
import { drawDragSeverityPin } from './drawings/drag-severity-pin';
import { drawSeverityMidLabel } from './drawings/severity-mid-label';

interface plotOptions {
  lineWidth: number;
  lineColor: string;
  lineDash: number[];
  brushColor: string;
  mainSize: number;
  fontSize: number;
  char: string;
  lineJoin: CanvasLineJoin;
  opacity: number;
  step: number;
  size: {
    x: number;
    y: number;
  };
  offset: {
    x: number;
    y: number;
  };
  vpOffset: Pick<Rectangle, 'x1' | 'x2' | 'y1' | 'y2'>;
}

export interface textLineOptions {
  length: number;
  before: number;
  after: number;
  angle: number;
}

export class Plot {
  _id: string;
  type:
    | 'dotted'
    | 'line'
    | 'circle'
    | 'area'
    | 'bar_chart'
    | 'bars_rounded'
    | 'area_bottom'
    | 'area_left'
    | 'arrows_with_angle'
    | 'unicode'
    | 'text'
    | 'text_colored'
    | 'side_text'
    | 'side_text_start'
    | 'side_text_end'
    | 'side_text_bottom'
    | 'text_line'
    | 'line_horizontal'
    | 'line_vertical'
    | 'line_area_vertical'
    | 'line_triangles_vertical'
    | 'tick_y_end'
    | 'policy_circles'
    | 'policy_bars'
    | 'policy_bars_chart'
    | 'policy_stroke'
    | 'policy_background'
    | 'policy_background_pattern'
    | 'policy_background_gradient'
    | 'policy_gradient_chart'
    | 'triangle_cursor_x'
    | 'label_x'
    | 'label_x_top'
    | 'curve_line'
    | 'drag_severity_pin'
    | 'severity_mid_label';
  _options: plotOptions;
  tooltips: Tooltip[];
  label: Label;
  labels: Label[] = [];

  textLine: textLineOptions;

  constructor(id: string, type: Plot['type'], ...options: any) {
    this._id = id;
    this.type = type;

    this._options = {
      lineWidth: 0.5,
      lineColor: '#000000',
      brushColor: '#000000',
      mainSize: 1,
      fontSize: 10,
      char: '1',
      lineDash: [],
      lineJoin: 'miter',
      opacity: 1,
      step: 0,
      offset: {
        x: 0,
        y: 0,
      },
      size: {
        x: 0,
        y: 0,
      },
      vpOffset: { x1: 0, x2: 0, y1: 0, y2: 0 },
    };

    this.textLine = {
      length: 0,
      before: 0,
      after: 0,
      angle: 0,
    };

    this.setOptions(options);
    this.tooltips = [];

    this.label = new Label(this.type);
    return this;
  }

  addTooltip(id: string, type: Tooltip['type'], ...options: any): Tooltip {
    const tooltip = new Tooltip(id, type, ...options);
    this.tooltips.push(tooltip);
    return tooltip;
  }

  findTooltipById(id: string): Tooltip | null {
    const tooltips: Tooltip[] = this.tooltips.filter((tooltip) => {
      return tooltip.id === id;
    });
    if (tooltips.length !== 0) return tooltips[0];
    return null;
  }

  setTextLineOptions(before: number, length: number, after: number, angle: number) {
    this.textLine.before = before;
    this.textLine.length = length;
    this.textLine.after = after;
    this.textLine.angle = angle;
    return this;
  }

  setLineColor(color: string) {
    this._options.lineColor = color;
    return this;
  }

  setLineWidth(width: number) {
    this._options.lineWidth = width;
    return this;
  }

  setBrushColor(color: string) {
    this._options.brushColor = color;
    return this;
  }

  setOptionsPartially(options: Partial<plotOptions>) {
    this._options = { ...this._options, ...options };
    return this;
  }

  setLineWidthMediaQueries(valueList: number[], queryList: string[]) {
    queryList.forEach((q, ind) => {
      const mediaQuery = window.matchMedia(q);
      mediaQuery.addEventListener('change', () => {
        if (mediaQuery.matches) {
          this._options.lineWidth = valueList[ind];
        }
      });

      if (mediaQuery.matches) this._options.lineWidth = valueList[ind]; // call once for each query
    });

    return this;
  }

  get id(): string {
    return this._id;
  }

  setOpacity(opacity: number) {
    this._options.opacity = opacity;
    return this;
  }

  drawPlot(
    isAnimationOn: boolean,
    ctx: CanvasRenderingContext2D,
    plotData: Point[],
    vp: Rectangle,
    labels: string[],
    metaArr: MetaItem[],
    highlighted?: boolean
  ) {
    if (plotData.length === 0) return;

    ctx.strokeStyle = this._options.lineColor;
    ctx.lineWidth = this._options.lineWidth;
    ctx.globalAlpha = 1;
    ctx.fillStyle = this._options.brushColor;
    ctx.lineJoin = this._options.lineJoin;

    let stepWidth = vp.width;
    if (plotData.length >= 2) {
      stepWidth = plotData[1].x - plotData[0].x;
    }
    this._options.step = stepWidth;

    switch (this.type) {
      case 'dotted':
        this.drawDotted(ctx, plotData);
        break;

      case 'line':
        this.drawLine(ctx, plotData);
        break;

      case 'area':
        this.drawArea(ctx, plotData, vp);
        break;

      case 'circle':
        drawCircle.call(this, ctx, plotData);
        break;

      case 'bar_chart':
        drawBarChart.call(this, ctx, plotData, vp);
        break;

      case 'policy_circles':
        drawPolicyCircles.call(this, ctx, plotData, vp, metaArr);
        break;

      case 'policy_bars':
        drawPolicyBars.call(this, ctx, plotData, metaArr);
        break;

      case 'bars_rounded':
        drawBarsRounded.call(this, ctx, plotData, vp, metaArr);
        break;

      case 'policy_bars_chart':
        drawPolicyBarsChart.call(this, ctx, plotData, metaArr);
        break;

      case 'policy_stroke':
        drawPolicyStroke.call(this, ctx, plotData);
        break;

      case 'policy_background':
        drawPolicyBackground.call(this, ctx, plotData);
        break;

      case 'policy_background_pattern':
        drawPolicyBackgroundPattern.call(this, ctx, plotData);
        break;

      case 'policy_background_gradient':
        drawPolicyBackgroundGradient.call(this, ctx, plotData, vp, metaArr);
        break;

      case 'policy_gradient_chart':
        drawPolicyGradientChart.call(this, ctx, plotData, metaArr);
        break;

      case 'area_bottom':
      case 'area_left':
        this.drawArea(ctx, plotData, vp);
        break;

      case 'unicode':
        this.drawUnicode(ctx, plotData, highlighted);
        break;

      case 'text':
        this.drawText(isAnimationOn, ctx, plotData, labels);
        break;

      case 'text_colored':
        this.drawText(isAnimationOn, ctx, plotData, labels, metaArr);
        break;

      case 'side_text':
      case 'side_text_end':
        this.sideText(isAnimationOn, ctx, plotData, vp, labels, 'end');
        break;

      case 'side_text_start':
        this.sideText(isAnimationOn, ctx, plotData, vp, labels, 'start');
        break;

      case 'side_text_bottom':
        this.sideText(isAnimationOn, ctx, plotData, vp, labels, 'end', 'horizontal');
        break;

      case 'text_line':
        this.drawTextLine(isAnimationOn, ctx, plotData, labels);
        break;

      case 'line_horizontal':
        line.call(this, 'horizontal', ctx, plotData, vp);
        break;

      case 'line_vertical':
        line.call(this, 'vertical', ctx, plotData, vp);
        break;

      case 'curve_line':
        drawCurveLine.call(this, ctx, plotData);
        break;

      case 'line_area_vertical':
        lineAreaVertical.call(this, ctx, plotData, vp);
        break;

      case 'line_triangles_vertical':
        lineTrianglesVertical.call(this, ctx, plotData, vp);
        break;

      case 'tick_y_end':
        this.yTick('end', ctx, plotData, vp);
        break;

      case 'triangle_cursor_x':
        drawTriangleCursorX.call(this, ctx, plotData, vp);
        break;

      case 'label_x':
        drawLabelX.call(this, ctx, plotData, labels, vp);
        break;

      case 'label_x_top':
        drawLabelXTop.call(this, ctx, plotData, labels, vp);
        break;

      case 'arrows_with_angle':
        drawArrowsWithAngle.call(this, ctx, plotData, metaArr);
        break;

      case 'drag_severity_pin':
        drawDragSeverityPin.call(this, ctx, plotData, vp);
        break;

      case 'severity_mid_label':
        drawSeverityMidLabel.call(this, ctx, plotData, labels, vp);
        break;
    }
  }

  yTick(position: string, ctx: CanvasRenderingContext2D, plotData: Point[], vp: Rectangle) {
    ctx.setLineDash(this._options.lineDash);
    for (let i = 0; i < plotData.length; i++) {
      const start = new Point(0, plotData[i].y);
      const end = new Point(0, plotData[i].y);

      switch (position) {
        case 'end':
          start.x = vp.x2;
          break;
        case 'start':
          start.x = vp.x1;
          break;
      }
      end.x = start.x + this._options.mainSize;

      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      ctx.stroke();
    }
  }

  drawDotted(ctx: CanvasRenderingContext2D, plotData: Point[]) {
    ctx.setLineDash(this._options.lineDash);
    for (let i = 0; i < plotData.length; i++) {
      ctx.beginPath();
      ctx.arc(plotData[i].x, plotData[i].y, this._options.mainSize, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    }
  }

  drawUnicode(ctx: CanvasRenderingContext2D, plotData: Point[], highlighted?: boolean) {
    ctx.font = `${this._options.fontSize}px serif`;
    ctx.textBaseline = 'middle';

    const text = ctx.measureText(this._options.char);
    for (let i = 0; i < plotData.length; i++) {
      ctx.globalAlpha = 1;
      ctx.fillText(this._options.char, plotData[i].x - text.width * 0.5, plotData[i].y);
      if (highlighted) {
        ctx.lineWidth = 7;
        ctx.globalAlpha = 0.3;
        ctx.strokeText(this._options.char, plotData[i].x - text.width * 0.5, plotData[i].y);
        ctx.globalAlpha = 1;
        ctx.fillText(this._options.char, plotData[i].x - text.width * 0.5, plotData[i].y);
      }
    }
  }

  drawTextLine(
    isAnimationOn: boolean,
    ctx: CanvasRenderingContext2D,
    plotData: Point[],
    labels: string[]
  ) {
    const textPointArr = plotData.map((el) => {
      const textPoint = new Point(el.x, el.y);
      return textPoint;
    });

    for (let i = 0; i < plotData.length; i++) {
      let printTextArr = [''];
      if (labels[i] !== undefined) printTextArr = labels[i].split('\\n');

      printTextArr.forEach((row, ind, mas) => {
        const t = new Point(textPointArr[i].x, textPointArr[i].y);
        t.y = t.y - (mas.length - ind - 1) * this.label.getRowHeight(ctx);
        this.label.draw(ctx, t, row);
      });
    }
  }

  drawText(
    isAnimationOn: boolean,
    ctx: CanvasRenderingContext2D,
    plotData: Point[],
    labels: string[],
    metaArr?: MetaItem[]
  ) {
    const getLabelArrRect = (textArr: string[], t: Point) => {
      let x1 = t.x,
        x2 = t.x,
        y1 = t.y,
        y2 = t.y;
      textArr.forEach((row, i, mas) => {
        const t_p = new Point(t.x, t.y);
        t_p.y = t_p.y - (mas.length - i - 1) * this.label.getRowHeight(ctx);
        const textRect = this.label.getlabelRect(ctx, t_p, row);
        if (textRect.x1 < x1) x1 = textRect.x1;
        if (textRect.x2 > x2) x2 = textRect.x2;
        if (textRect.y1 < y1) y1 = textRect.y1;
        if (textRect.y2 > y2) y2 = textRect.y2;
      });
      return new Rectangle(x1, y1, x2, y2);
    };

    const pushBorder = (textArr: string[], t: Point, cW: number, cH: number) => {
      let dx = 0;
      let dy = 0;

      textArr.forEach((row, ind, mas) => {
        const t_p = new Point(t.x, t.y);
        t_p.y = t_p.y - (mas.length - ind - 1) * this.label.getRowHeight(ctx);
        const textRect = this.label.getlabelRect(ctx, t_p, row);

        if (textRect.x1 < 0 && textRect.x1 < dx) dx = textRect.x1;
        if (textRect.x2 > cW && cW - textRect.x2 > dx) dx = cW - textRect.x2;

        if (textRect.y1 < 0 && textRect.y1 < dy) dy = textRect.y1;
        if (textRect.y2 > cH && cH - textRect.y2 > dy) dy = cH - textRect.y2;
      });

      if (dx !== 0) dx = ((Math.abs(dx) + 5) * dx) / Math.abs(dx);
      if (dy !== 0) dy = ((Math.abs(dy) + 5) * dy) / Math.abs(dy);

      t.x = t.x - dx;
      t.y = t.y - dy;
    };

    const cursorLineLength = this.textLine.length;
    const before_cursorLine = this.textLine.before;
    const after_cursorLine = this.textLine.after;
    const rotation_angle = this.textLine.angle;

    let cursorLineStart;
    let cursorLineEnd;
    let rotationCenter: Point;

    const canvasW = ctx.canvas.clientWidth;
    const canvasH = ctx.canvas.clientHeight;

    //plotData.sort((a, b) => a.x - b.x);

    const textPointArr = plotData.map((el) => {
      rotationCenter = new Point(el.x, el.y);
      const textPoint = new Point(
        el.x,
        el.y - before_cursorLine - cursorLineLength - after_cursorLine
      ).rotate(rotation_angle, rotationCenter);
      return textPoint;
    });

    if (!isAnimationOn) {
      // x overlap
      let hasOverlap = true;
      const counter = 0;

      while (hasOverlap && counter < textPointArr.length * 2) {
        hasOverlap = false;

        for (let i = 0; i < textPointArr.length - 1; i++) {
          let printTextArr = [''];
          if (labels[i] !== undefined) printTextArr = labels[i].split('\\n');
          let printTextArr1 = [''];
          if (labels[i + 1] !== undefined) printTextArr1 = labels[i + 1].split('\\n');

          const rect1 = getLabelArrRect(printTextArr, textPointArr[i]);
          const rect2 = getLabelArrRect(printTextArr1, textPointArr[i + 1]);

          if (rect1.isIntersect(rect2)) {
            hasOverlap = true;
            const abs = rect1.splitX(rect2);
            textPointArr[i].x = textPointArr[i].x - abs * 0.5 - 2;
            textPointArr[i + 1].x = textPointArr[i + 1].x + abs * 0.5 + 2;
          }
        }
      }
    }

    for (let i = 0; i < plotData.length; i++) {
      rotationCenter = new Point(plotData[i].x, plotData[i].y);

      // draw line
      ctx.beginPath();
      ctx.setLineDash([]);

      cursorLineStart = new Point(plotData[i].x, plotData[i].y - before_cursorLine).rotate(
        rotation_angle,
        rotationCenter
      );
      cursorLineEnd = new Point(
        plotData[i].x,
        plotData[i].y - before_cursorLine - cursorLineLength
      ).rotate(rotation_angle, rotationCenter);

      ctx.strokeStyle = this._options.lineColor;
      ctx.lineWidth = this._options.lineWidth;
      ctx.globalAlpha = 1;
      ctx.fillStyle = this._options.brushColor;
      ctx.lineJoin = this._options.lineJoin;
      ctx.globalAlpha = 1;
      ctx.moveTo(cursorLineStart.x, cursorLineStart.y);
      ctx.lineTo(cursorLineEnd.x, cursorLineEnd.y);
      ctx.stroke();
      ctx.closePath;

      let printTextArr = [''];
      if (labels[i] !== undefined) printTextArr = labels[i].split('\\n');

      pushBorder(printTextArr, textPointArr[i], canvasW, canvasH);

      if (metaArr) {
        this.label.color = metaArr[i]?.color || 'black';
      }
      printTextArr.forEach((row, ind, mas) => {
        const t = new Point(textPointArr[i].x, textPointArr[i].y);
        t.y = t.y - (mas.length - ind - 1) * this.label.getRowHeight(ctx);
        this.label.draw(ctx, t, row);
      });
    }
  }

  sideText(
    isAnimationOn: boolean,
    ctx: CanvasRenderingContext2D,
    plotData: Point[],
    vp: Rectangle,
    labels: string[],
    position: 'start' | 'end',
    orientation: 'horizontal' | 'vertical' = 'vertical'
  ) {
    const getLabelArrRect = (textArr: string[], t: Point) => {
      let x1 = t.x,
        x2 = t.x,
        y1 = t.y,
        y2 = t.y;
      textArr.forEach((row, i, mas) => {
        const t_p = new Point(t.x, t.y);
        t_p.y = t_p.y - (mas.length - i - 1) * this.label.getRowHeight(ctx);
        const textRect = this.label.getlabelRect(ctx, t_p, row);
        if (textRect.x1 < x1) x1 = textRect.x1;
        if (textRect.x2 > x2) x2 = textRect.x2;
        if (textRect.y1 < y1) y1 = textRect.y1;
        if (textRect.y2 > y2) y2 = textRect.y2;
      });
      return new Rectangle(x1, y1, x2, y2);
    };

    const pushBorder = (textArr: string[], t: Point, cW: number, cH: number) => {
      let dx = 0;
      let dy = 0;

      textArr.forEach((row, ind, mas) => {
        const t_p = new Point(t.x, t.y);
        t_p.y = t_p.y - (mas.length - ind - 1) * this.label.getRowHeight(ctx);
        const textRect = this.label.getlabelRect(ctx, t_p, row);

        if (textRect.x1 < 0 && textRect.x1 < dx) dx = textRect.x1;
        if (textRect.x2 > cW && cW - textRect.x2 > dx) dx = cW - textRect.x2;

        if (textRect.y1 < 0 && textRect.y1 < dy) dy = textRect.y1;
        if (textRect.y2 > cH && cH - textRect.y2 > dy) dy = cH - textRect.y2;
      });

      if (dx !== 0) dx = ((Math.abs(dx) + 5) * dx) / Math.abs(dx);
      if (dy !== 0) dy = ((Math.abs(dy) + 5) * dy) / Math.abs(dy);

      t.x = t.x - dx;
      t.y = t.y - dy;
    };

    const canvasW = ctx.canvas.clientWidth;
    const canvasH = ctx.canvas.clientHeight;

    const textPointArr = plotData.map((el) => {
      let x, y: number;
      switch (orientation) {
        case 'horizontal':
          x = el.x;
          y = position === 'end' ? vp.y2 : vp.y1;
          break;
        case 'vertical':
          x = position === 'end' ? vp.x2 : vp.x1;
          y = el.y;
          break;
      }
      return new Point(x, y);
    });

    if (!isAnimationOn) {
      // x overlap
      let hasOverlap = true;
      const counter = 0;

      while (hasOverlap && counter < textPointArr.length * 2) {
        hasOverlap = false;

        for (let i = 0; i < textPointArr.length - 1; i++) {
          for (let j = i + 1; j < textPointArr.length; j++) {
            let printTextArr = [''];
            if (labels[i] !== undefined) printTextArr = labels[i].split('\n');
            let printTextArr1 = [''];
            if (labels[j] !== undefined) printTextArr1 = labels[j].split('\n');

            const rect1 = getLabelArrRect([printTextArr[0]], textPointArr[i]);
            const rect2 = getLabelArrRect([printTextArr1[0]], textPointArr[j]);

            if (rect1.isIntersect(rect2)) {
              hasOverlap = true;
              const abs = rect1.splitY(rect2);
              textPointArr[i].y = textPointArr[i].y - abs * 0.5 - 2;
              textPointArr[j].y = textPointArr[j].y + abs * 0.5 + 2;
            }
          }
        }
      }
    }

    for (let i = 0; i < plotData.length; i++) {
      // draw line
      ctx.beginPath();
      ctx.setLineDash([]);

      ctx.strokeStyle = this._options.lineColor;
      ctx.lineWidth = this._options.lineWidth;
      ctx.globalAlpha = 1;
      ctx.fillStyle = this._options.brushColor;
      ctx.lineJoin = this._options.lineJoin;
      ctx.globalAlpha = 1;

      ctx.stroke();
      ctx.closePath;

      let printTextArr = [''];
      if (labels[i] !== undefined) printTextArr = labels[i].split('\n');

      pushBorder(printTextArr, textPointArr[i], canvasW, canvasH);

      // print only 1-st line
      printTextArr.forEach((row, ind, mas) => {
        const t = new Point(textPointArr[i].x, textPointArr[i].y);
        t.y = t.y + ind * this.label.getRowHeight(ctx);
        if (ind === 0) {
          this.label.draw(ctx, t, row, undefined, i);
        }
      });

      // print only 2-nd line
      printTextArr.forEach((row, ind, mas) => {
        if (ind === 1) {
          const t = new Point(textPointArr[i].x, textPointArr[i].y);
          t.y = t.y + ind * this.label.getRowHeight(ctx);
          let hasOverlap = false;

          for (let j = 0; j < textPointArr.length; j++) {
            const secondRow = [row];
            const firstRow = labels[j].split('\n');

            const rect1 = getLabelArrRect(secondRow, t);
            const rect2 = getLabelArrRect([firstRow[0]], textPointArr[j]);

            if (rect1.isIntersect(rect2) && i !== j) {
              hasOverlap = true;
            }
          }

          const prevLineHeight = this.labels[ind - 1]
            ? this.labels[ind - 1].getRowHeight(ctx)
            : this.label.getRowHeight(ctx);
          const drawLabel = this.labels[ind - 1] ? this.labels[ind - 1] : this.label;

          if (hasOverlap) {
            t.x = t.x + ctx.measureText(mas[0]).width + 5;
            t.y = textPointArr[i].y;
            t.y = t.y + this.label.getRowHeight(ctx) - prevLineHeight;
            drawLabel.draw(ctx, t, row, undefined, i);
          } else {
            t.y = t.y + this.label.getRowHeight(ctx) - prevLineHeight;
            drawLabel.draw(ctx, t, row, undefined, i);
          }
        }
      });
    }
  }

  drawLine(ctx: CanvasRenderingContext2D, plotData: Point[]) {
    ctx.lineCap = 'round';

    ctx.setLineDash(this._options.lineDash);
    ctx.beginPath();
    ctx.moveTo(plotData[0].x, plotData[0].y);

    for (let i = 1; i < plotData.length; i++) {
      ctx.lineTo(plotData[i].x, plotData[i].y);
    }

    ctx.stroke();
  }

  drawArea(ctx: CanvasRenderingContext2D, plotData: Point[], vp: Rectangle) {
    ctx.beginPath();

    switch (this.type) {
      case 'area_bottom':
        ctx.lineTo(vp.x1, vp.zeroY);
        break;
      case 'area_left':
        ctx.lineTo(vp.zeroX, plotData[0].y);
        break;
    }

    ctx.lineTo(plotData[0].x, plotData[0].y);

    for (let i = 1; i < plotData.length; i++) {
      ctx.lineTo(plotData[i].x, plotData[i].y);
    }

    switch (this.type) {
      case 'area_bottom':
        ctx.lineTo(vp.x2, vp.zeroY);
        break;
      case 'area_left':
        ctx.lineTo(vp.zeroX, plotData[plotData.length - 1].y);
        break;
    }

    ctx.closePath();
    ctx.globalAlpha = this._options.opacity;
    ctx.fill();
    ctx.stroke();
  }

  // deprecated
  // no longer supported: use 'setOptionsPartially' instead
  setOptions(options: any[]) {
    switch (this.type) {
      case 'policy_stroke':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.mainSize = options[2];
        if (options[3]) this._options.offset = options[3];
        break;

      case 'policy_background_pattern':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        this._options.mainSize = options[3];
        if (options[4]) this._options.offset = options[4];
        break;

      case 'policy_circles':
      case 'policy_bars':
      case 'policy_bars_chart':
      case 'policy_background':
      case 'policy_background_gradient':
      case 'policy_gradient_chart':
        this._options.brushColor = options[0];
        this._options.mainSize = options[1];
        if (options[2]) this._options.offset = options[2];
        break;

      case 'bars_rounded':
        this._options.brushColor = options[0];
        this._options.mainSize = options[1];
        this._options.lineWidth = options[2];
        if (options[2]) this._options.offset = options[3];
        break;

      case 'dotted':
      case 'bar_chart':
      case 'circle':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        this._options.mainSize = options[3];
        break;

      case 'line':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        if (options[2]) this._options.lineDash = options[2];
        if (options[3]) this._options.lineJoin = options[3];
        break;

      case 'area':
      case 'area_bottom':
      case 'area_left':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        break;

      case 'unicode':
        this._options.fontSize = options[0];
        this._options.brushColor = options[1];
        this._options.char = options[2];
        break;

      case 'text':
      case 'side_text':
      case 'side_text_start':
      case 'side_text_bottom':
      case 'side_text_end':
      case 'text_line':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        break;

      case 'line_horizontal':
      case 'line_vertical':
      case 'curve_line':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        if (options[2]) this._options.lineDash = options[2];
        if (options[3]) this._options.lineJoin = options[3];
        break;

      case 'tick_y_end':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.mainSize = options[2];
        if (options[3]) this._options.lineDash = options[3];
        if (options[4]) this._options.lineJoin = options[4];
        break;
    }
  }
}
