const RANGECOND_scaledRaeData = 'ScaledRAEData#';

import * as ddb from '../ddbUtils-config';
import { _queryJSON } from '../utils';
import { ScaledRaeData } from '@/types';

export const queryCompanyScaledRaeData = async (companyID: string): Promise<ScaledRaeData[]> => {
  const q = _queryJSON(companyID, RANGECOND_scaledRaeData);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any): ScaledRaeData => {
    const newRow: ScaledRaeData = {
      companyID: el.CompanyID,
      timeStamp: new Date(el.ChTimestamp),
      Attack: +el.Attack,
      Attack_rs: +el.Attack_rs,
      Exfil: +el.Exfil,
      Exfil_rs: +el.Exfil_rs,
      Recon: +el.Recon,
      Recon_rs: +el.Recon_rs,
      slRating: +el.slRating,
    };
    return newRow;
  });
  return mappedResult;
};
