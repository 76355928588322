import { Ticks } from '../Ticks';
import { Rectangle } from '../../Rectangle';
import { Point } from '../../Point';
import { Transformer } from '../../Transformer';
import { roundDayDateWithOffset } from '@/pages/customer-pages/Company/Timeline/utils';

export const generateTimestampMonthBetween = function (
  this: Ticks,
  min: number,
  max: number,
  vp: Rectangle,
  count: number
): Point[] {
  const coords: Point[] = [];
  this.values = [];
  this.labels = [];

  const transformer = new Transformer();
  const fromRect = new Rectangle(min, 0, max, 1);

  const startTimestamp = min;
  const endTimestamp = max;

  const startDate = new Date(min);

  let nextMonthTickTimestamp = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, 1);
  let nextMonthTickDate = new Date(nextMonthTickTimestamp);

  let curTimestamp = startTimestamp;
  let safetyCounter = 1000;
  while (curTimestamp < endTimestamp && safetyCounter > 0) {
    safetyCounter = safetyCounter - 1;
    const curMonthTimestampRange =
      nextMonthTickTimestamp -
      Date.UTC(nextMonthTickDate.getUTCFullYear(), nextMonthTickDate.getUTCMonth() - 1, 1);
    const midTimestampArr = [];
    let i = 1;
    let curMidTickTimestamp = nextMonthTickTimestamp - (curMonthTimestampRange / count) * i;
    while (curMidTickTimestamp > curTimestamp && safetyCounter > 0) {
      safetyCounter = safetyCounter - 1;
      midTimestampArr.unshift(curMidTickTimestamp);
      i = i + 1;
      curMidTickTimestamp = nextMonthTickTimestamp - (curMonthTimestampRange / count) * i;
    }

    midTimestampArr.forEach((t) => {
      if (t <= endTimestamp) {
        curTimestamp = t;
        const value = curTimestamp;
        const coordPoint = transformer.getVeiwportCoord(fromRect, vp, new Point(value, 0));
        coords.push(coordPoint);
        this.values.push(value);
        const curDate = new Date(curTimestamp);
        this.labels.push(
          curDate.toLocaleString('en-us', { month: 'short', timeZone: 'UTC' }).toLocaleUpperCase()
        );
        curTimestamp = Date.UTC(curDate.getUTCFullYear(), curDate.getUTCMonth() + 1, 1);
      }
    });

    nextMonthTickTimestamp = Date.UTC(
      nextMonthTickDate.getUTCFullYear(),
      nextMonthTickDate.getUTCMonth() + 1,
      1
    );
    nextMonthTickDate = new Date(nextMonthTickTimestamp);
  }

  return coords;
};
