
import { Component, Vue } from 'vue-property-decorator';
import InsideBtnSpinner from '@/components/InsideBtnSpinner.vue';

@Component({ components: { InsideBtnSpinner } })
export default class UserLoginPage extends Vue {
  email: string = '';
  password: string = '';
  waitingForResponse: boolean = false;

  login() {
    this.$store.commit('setLoginError', '');
    this.waitingForResponse = true;
    window.CbhRosie.logIn(
      this.email,
      this.password,
      () => {
        const redirectPath: string =
          this.$route.query.redirect && !Array.isArray(this.$route.query.redirect)
            ? this.$route.query.redirect
            : '/';
        this.$router.push(redirectPath);
        this.waitingForResponse = false;
      },
      (err) => {
        if (err.message) {
          this.$store.commit('setLoginError', err.message);
          this.waitingForResponse = false;
        }
      }
    );
  }

  get loginError(): string {
    return this.$store.getters.sessionLoginError;
  }

  get isValid() {
    return this.email !== '' && this.password !== '';
  }

  updateRemember(e: any) {
    this.$store.commit('setRemember', e.target.checked);
  }

  get remember(): boolean {
    return this.$store.getters.rememberOnLogn;
  }
}
