const RANGECOND_raeAlert = 'RaeAlert#';

import * as ddb from '../ddbUtils-config';
import { _queryJSON } from '../utils';

export const queryCompanyRaeAlert = async (companyID: string) => {
  const q = _queryJSON(`Company#${companyID}`, RANGECOND_raeAlert);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any) => {
    const newRow = {
      companyID: el.CompanyID,
      timeStamp: new Date(el.ChTimestamp),
      RaeRisk: el.RaeRisk,
    };
    return newRow;
  });
  return mappedResult;
};
