import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const drawDragSeverityPin = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle
) {
  const { size, lineColor, lineWidth, brushColor, vpOffset, mainSize } = this._options;
  ctx.lineWidth = lineWidth;
  ctx.fillStyle = brushColor;
  const { x1, x2, y1, y2 } = vpOffset;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  const midY = rect.y1 + Math.round((rect.y2 - rect.y1) * 0.5);
  const dy = Math.abs(size.y - size.x);
  const dx = Math.round((size.x * 2) / 3);
  for (let i = 0; i < plotData.length; i++) {
    const p = new Point(plotData[i].x, midY);
    ctx.strokeStyle = lineColor;
    ctx.beginPath();
    ctx.moveTo(p.x - size.x, p.y + dy);
    ctx.lineTo(p.x - size.x, p.y - dy);
    ctx.arc(p.x, p.y - dy, size.x, -1 * Math.PI, 0 * Math.PI);
    ctx.lineTo(p.x + size.x, p.y + dy);
    ctx.arc(p.x, p.y + dy, size.x, 0, 1 * Math.PI);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // draw lines
    ctx.strokeStyle = '#fff';
    ctx.beginPath();
    ctx.moveTo(p.x - size.x + dx, p.y - mainSize);
    ctx.lineTo(p.x - size.x + dx, p.y + mainSize);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(p.x + size.x - dx, p.y - mainSize);
    ctx.lineTo(p.x + size.x - dx, p.y + mainSize);
    ctx.closePath();
    ctx.stroke();
  }
};
