
import PolicyTimelineModule from '@/store/modules/company-tabs/policy-timiline';
import { CompanyProfile } from '@/types';
import { formatDateUs } from '@/utilities';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CompanyInfo extends Vue {
  @Prop() title!: string;

  mounted() {
    console.info('company info:', this.profile, this.policy);
  }

  get isAdvisory() {
    return this.$store.getters.isAdvisoryUser;
  }

  get tableData() {
    const table: Array<{ title: string; value: string }> = [
      { title: 'MIC:', value: this.profile.MIC },
      { title: 'Ticker:', value: this.profile.CodeEod },
      { title: 'ISIN:', value: this.profile.ISIN },
      { title: 'Primary Exchange:', value: this.profile.ExchangeEod },
      { title: 'Benchmark', value: this.profile.Benchmark },
      {
        title: '# of Subsidiaries',
        value: this.profile.EstimatedSubsidiariesNumber || this.profile.NumberOfSubs || '',
      },
      ...(this.isAdvisory
        ? []
        : [
            { title: 'Policy number:', value: '' },
            {
              title: 'Policy start:',
              value: this.policy?.PolicyStartDate ? formatDateUs(this.policy?.PolicyStartDate) : '',
            },
            {
              title: 'Policy end:',
              value: this.policy?.PolicyEndDate ? formatDateUs(this.policy?.PolicyEndDate) : '',
            },
          ]),
    ];
    return table;
  }

  get profile() {
    return this.$store.getters.companyProfile as CompanyProfile;
  }

  get policy() {
    return this.$store.getters.policyTimelineData as PolicyTimelineModule['data'];
  }
}
