import { Point } from './Point';
import { Rectangle } from './Rectangle';

export function drawRoundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

export function pushOverlapCoordsY(coords: Point[], w: number, h: number) {
  let hasOverlap = true;
  let counter = 0;

  while (hasOverlap && counter < coords.length * 2) {
    hasOverlap = false;
    counter += 1;
    for (let i = 0; i < coords.length - 1; i++) {
      const rect1 = new Rectangle(coords[i].x, coords[i].y, coords[i].x + w, coords[i].y + h);
      const rect2 = new Rectangle(
        coords[i + 1].x,
        coords[i + 1].y,
        coords[i + 1].x + w,
        coords[i + 1].y + h
      );

      if (rect1.isIntersect(rect2)) {
        hasOverlap = true;
        const abs_y = rect1.splitY(rect2);
        let down: Point;
        let up: Point;
        if (coords[i].y > coords[i + 1].y) {
          down = coords[i];
          up = coords[i + 1];
        } else {
          down = coords[i + 1];
          up = coords[i];
        }
        down.y = down.y + h * 0.51;
        up.y = up.y - h * 0.51;
      }
    }
  }
  return coords;
}
