import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCyFiStatsList, queryCyFiStatsCurrent } from '../../../api-rosie/company';
import { CyFiStatsRowMeta } from '@/types';

@Module
class CyFiScoresModule extends VuexModule {
  private scores: CyFiStatsRowMeta[] = [];
  private cyfiStatsCurrent: CyFiStatsRowMeta | null = null;

  get companyScores(): CyFiStatsRowMeta[] {
    return this.scores;
  }

  get overview_CyFiStats() {
    return this.cyfiStatsCurrent;
  }

  @Mutation
  public setCompanyScores(scores: CyFiStatsRowMeta[]) {
    const filteredList = scores.reduce((prev: CyFiStatsRowMeta[], cur) => {
      if (prev.length === 0) {
        prev.push(cur);
        return prev;
      }
      const d1 = prev[prev.length - 1].ChTimestamp.getTime();
      const d2 = cur.ChTimestamp.getTime();
      if (d1 !== d2) prev.push(cur);
      return prev;
    }, []);
    this.scores.splice(0, this.scores.length, ...filteredList);
  }

  @Action
  public async loadCompanyScores(id: string) {
    return queryCyFiStatsList(id)
      .then((data) => {
        store.commit('setCompanyScores', data);
      })
      .catch((err) => {
        console.error(`load CyFiScoresData failed`);
        store.commit('setCompanyScores', []);
      });
  }

  @Mutation
  public setCyFiStatsCurrent(data: CyFiStatsRowMeta[]) {
    if (data.length === 0) {
      this.cyfiStatsCurrent = null;
    } else {
      if (this.cyfiStatsCurrent) {
        this.cyfiStatsCurrent = Object.assign(this.cyfiStatsCurrent, data[0]);
      } else {
        this.cyfiStatsCurrent = Object.assign({}, data[0]);
      }
    }
  }

  @Action
  public async loadCyFiStatsCurrent(id: string) {
    return queryCyFiStatsCurrent(id)
      .then((data) => {
        store.commit('setCyFiStatsCurrent', data);
      })
      .catch((err) => {
        console.error(`load CyFiStatsCurrent failed`);
        store.commit('setCyFiStatsCurrent', []);
      });
  }
}
export default CyFiScoresModule;
