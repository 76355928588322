
import { Component, Vue, Prop } from 'vue-property-decorator';
import TableCyFi from './tables/SectorPeersTable.vue';
import TableCharts from './tables/SectorPeersTableCharts.vue';

@Component({
  components: {
    TableCyFi,
    TableCharts,
  },
})
export default class SectorPeersPage extends Vue {
  @Prop() private tableType!: string;

  get tableComponent(): string {
    if (this.tableType === 'cyfi') return 'TableCyFi';
    return 'TableCharts';
  }

  get tableData(): any[] {
    const result = this.$store.getters.companySectorPeers;
    return result;
  }
}
