import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';

const RANGECOND_BREACH_PROBABILITY = 'BreachProbability#';
const RANGECOND_BREACH_PROBABILITY_CURRENT = 'BreachProbability#current';

export enum BreachProbabilityRisk {
  LowRiskProb = 'LowRiskProb',
  LowMediumRiskProb = 'LowMediumRiskProb',
  MediumRiskProb = 'MediumRiskProb',
  MediumMediumHighRiskProb = 'MediumMediumHighRiskProb',
  MediumHighRiskProb = 'MediumHighRiskProb',
  MediumHighHighRiskProb = 'MediumHighHighRiskProb',
  HighRiskProb = 'HighRiskProb',
  HighHighestRiskProb = 'HighHighestRiskProb',
  HighestRiskProb = 'HighestRiskProb',
}

type BreachProbabilityValueScheme = Record<BreachProbabilityRisk, number>;

export interface BreachProbabilityItem {
  BreachProbability: number;
  ChTimestamp: Date | null;
  DataType: string;
  Rank: number | null;
  scheme: BreachProbabilityValueScheme;
}

export interface BreachProbabilityItemPrepared extends BreachProbabilityItem {
  ChTimestamp: Date;
  risk: BreachProbabilityRisk;
}

export const getBreachRiskByValue = (
  value: number,
  scheme: BreachProbabilityValueScheme
): BreachProbabilityRisk => {
  let risk: BreachProbabilityRisk = BreachProbabilityRisk.LowRiskProb;
  let diff = Math.abs(scheme[risk] - value);
  for (const r in scheme) {
    const d = Math.abs(scheme[r as BreachProbabilityRisk] - value);
    if (d < diff) {
      risk = r as BreachProbabilityRisk;
      diff = d;
    }
  }
  return risk;
};

const parseBreachProbabilityItem = (item: any): BreachProbabilityItem => ({
  BreachProbability: Number(item['BreachProbability']),
  ChTimestamp: item['ChTimestamp'] === '-' ? null : new Date(item['ChTimestamp']),
  DataType: item['DataType'],
  Rank: item['Rank'] ? Number(item['Rank']) : null,
  scheme: {
    LowRiskProb: Number(item['LowRiskProb']),
    LowMediumRiskProb: Number(item['LowMediumRiskProb']),
    MediumRiskProb: Number(item['MediumRiskProb']),
    MediumMediumHighRiskProb: Number(item['MediumMediumHighRiskProb']),
    MediumHighRiskProb: Number(item['MediumHighRiskProb']),
    MediumHighHighRiskProb: Number(item['MediumHighHighRiskProb']),
    HighRiskProb: Number(item['HighRiskProb']),
    HighHighestRiskProb: Number(item['HighHighestRiskProb']),
    HighestRiskProb: Number(item['HighestRiskProb']),
  },
});

@Module({ dynamic: true, store, name: 'breachProbability' })
class BreachProbability extends VuexModule {
  private list: BreachProbabilityItemPrepared[] = [];
  private data?: BreachProbabilityItem = undefined;

  @Action({ commit: 'setBreachProbability' })
  public async fetchCompanyBreachProbability() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_BREACH_PROBABILITY)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_BREACH_PROBABILITY + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setBreachProbability(data: any[]) {
    if (data) {
      const dataMap = data.reduce<Record<string, BreachProbabilityItemPrepared>>((pre, cur) => {
        const parsedItem = parseBreachProbabilityItem(cur);
        const { BreachProbability, ChTimestamp, scheme } = parsedItem;
        if (ChTimestamp) {
          pre[cur['ChTimestamp']] = {
            ...parsedItem,
            ChTimestamp,
            risk: getBreachRiskByValue(BreachProbability, scheme),
          };
        }
        return pre;
      }, {});
      this.list.splice(
        0,
        this.list.length,
        ...Object.values(dataMap).sort((a, b) => {
          return a.ChTimestamp.getTime() - b.ChTimestamp.getTime();
        })
      );
    } else {
      this.list.splice(0, this.list.length);
    }
  }

  get breachProbabilityList() {
    return this.list;
  }

  @Action({ commit: 'setBreachProbabilityCurrent' })
  public async fetchCompanyBreachProbabilityCurrent() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_BREACH_PROBABILITY_CURRENT)
      .then((data) => {
        if (data[0]) {
          return data[0];
        } else {
          throw new Error(RANGECOND_BREACH_PROBABILITY_CURRENT + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      });
  }

  @Mutation
  public setBreachProbabilityCurrent(data: any) {
    if (data) {
      this.data = parseBreachProbabilityItem(data);
    } else {
      this.data = data;
    }
  }

  get breachCurrentData() {
    return this.data;
  }

  get currentRisk() {
    if (!this.data) return null;
    const { BreachProbability, scheme } = this.data;
    return getBreachRiskByValue(BreachProbability, scheme);
  }
}

export const BreachProbabilityModule = getModule(BreachProbability);
