
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Meta } from '@/types/meta';
import MetaLabel from '@/components/MetaLabel.vue';

@Component({ components: { MetaLabel } })
export default class SqrValueMeta extends Vue {
  @Prop() valueMeta!: Meta | null;
  @Prop() dec!: number;
  @Prop() private colorOptions!: [{ color: string; condition: (val: number) => boolean }];

  get value() {
    if (!this.valueMeta) return null;
    return this.valueMeta.value;
  }

  get bgColor(): string {
    if (this.value === null) return 'none';
    const value = +this.value;
    for (let i = 0; i < this.colorOptions.length; i++) {
      if (this.colorOptions[i].condition(value)) return this.colorOptions[i].color;
    }
    return '#000';
  }

  formatValue(value: number, dec: number = 2): string {
    let result: string = '-';
    if (isNaN(value) || value === null) return result;
    result = value.toFixed(dec);
    return result;
  }
}
