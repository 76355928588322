
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createChart_2, createChart_3, createChart_4 } from './rosie-charts-cell';

@Component
export default class ChartCell extends Vue {
  @Prop() private data!: string;
  @Prop() private type!: 'rec' | 'att' | 'net';
  chart?: any;

  mounted() {
    this.chartsInit();
  }

  get cyberValuesData() {
    const res = [...this.data];
    return res;
  }

  get cyberValues_DateObj(): Date[] {
    return this.cyberValuesData.map((el: any) => new Date(el.ChTimestamp));
  }

  get cyberValues_DateString(): string[] {
    return this.cyberValues_DateObj.map((el: Date) => el.toLocaleDateString('en'));
  }

  get forums_row() {
    return this.cyberValuesData.map((el: any) => el.Forums);
  }
  get pastes_row() {
    return this.cyberValuesData.map((el: any) => el.Pastes);
  }
  get osint_row() {
    return this.cyberValuesData.map((el: any) => el.Osint);
  }
  get markets_row() {
    return this.cyberValuesData.map((el: any) => el.Markets);
  }
  get exits_row() {
    return this.cyberValuesData.map((el: any) => el.Exits);
  }
  get clients_row() {
    return this.cyberValuesData.map((el: any) => el.Clients);
  }

  chartsInit() {
    // const chart_container_axis = document.getElementById('rosie-charts_axis');
    const chart_container = this.$refs.chart;
    if (this.type === 'rec')
      // @ts-ignore
      this.chart = createChart_2(chart_container, [
        this.cyberValues_DateString,
        this.forums_row,
        this.pastes_row,
        this.osint_row,
      ]);
    if (this.type === 'att')
      // @ts-ignore
      this.chart = createChart_3(chart_container, [
        this.cyberValues_DateString,
        this.markets_row,
        this.exits_row,
      ]);
    if (this.type === 'net')
      // @ts-ignore
      this.chart = createChart_4(chart_container, [this.cyberValues_DateString, this.clients_row]);

    this.$emit('chart-ready', this.chart);
  }
}
