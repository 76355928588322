import { Tooltip, tooltipObject } from '../Tooltip';

export const drawLineVerticalFull = function (this: Tooltip, t: tooltipObject) {
  t.ctx.beginPath();
  t.ctx.strokeStyle = this._options.lineColor;
  t.ctx.lineWidth = this._options.lineWidth;
  t.ctx.setLineDash(this._options.lineDash);
  t.ctx.moveTo(t.coord.x, t.vp.y1);
  t.ctx.lineTo(t.coord.x, t.vp.zeroY);
  t.ctx.stroke();
  t.ctx.setLineDash([]);
  t.ctx.closePath();
};
