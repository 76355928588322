import { Plot } from '../Plot';
import { Point } from '../../Point';

export const drawPolicyStroke = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[]
) {
  if (plotData.length < 2) return;
  const { mainSize, offset } = this._options;
  const start = plotData[0];
  const end = plotData[plotData.length - 1];

  ctx.beginPath();
  ctx.arc(start.x + offset.x, start.y + offset.y, mainSize, -0.5 * Math.PI, 0.5 * Math.PI, true);
  ctx.moveTo(start.x + offset.x, start.y + offset.y + mainSize);

  for (let i = 1; i < plotData.length - 1; i++) {
    const p = plotData[i];
    ctx.lineTo(p.x + offset.x, p.y + offset.y + mainSize);
  }

  ctx.lineTo(end.x + offset.x, end.y + offset.y + mainSize);
  ctx.arc(end.x + offset.x, end.y + offset.y, mainSize, 0.5 * Math.PI, -0.5 * Math.PI, true);
  ctx.lineTo(start.x + offset.x, start.y + offset.y - mainSize);

  ctx.stroke();
};
