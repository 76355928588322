class Meta {
  initialValue: any;
  fieldName: string;
  query: any;
  rowDataType: string;
  transformMethod?: (val: any) => any;

  constructor(row: any, fieldName: string, query: any, transformMethod?: (val: any) => any) {
    this.initialValue = row[fieldName];
    this.fieldName = fieldName;
    this.query = query;
    this.rowDataType = row.DataType;
    if (transformMethod) this.transformMethod = transformMethod;
  }

  get value() {
    return this.transformMethod ? this.transformMethod(this.initialValue) : this.initialValue;
  }
}

export { Meta };
