
import { Component, Vue } from 'vue-property-decorator';
// import CyFiTable from './tables/CyFiCustomGroupTable.vue';
import TableCyFi from '../SectorPeers/tables/SectorPeersTable.vue';

// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

import { Company } from '@/types';
import InsideBtnSpinner from '@/components/InsideBtnSpinner.vue';

@Component({
  components: {
    Autocomplete,
    // CyFiTable,
    TableCyFi,
    InsideBtnSpinner,
  },
})
export default class CustomGroupPage extends Vue {
  autoselect: boolean = true;
  companyList: Company[] = [];
  saveDisabled: boolean = false;

  get tableData(): any[] {
    const result = this.$store.getters.cyfiTableData;
    return result;
  }

  search(input: string) {
    if (input.length < 1) {
      return [];
    }
    const companyNames: Array<string> = this.$store.getters.allCompanies.map(
      (c: Company) => c.companyName
    );
    const result = companyNames.filter((cmp) => {
      return cmp.toLowerCase().includes(input.toLowerCase());
    });
    if (result.length !== 0) return result;
    return [];
  }

  async handleSubmit(result: string) {
    this.saveDisabled = true;
    // @ts-ignore
    const res = result || this.$refs.searchInput.value;
    const cmp: Company | undefined = this.$store.getters.allCompanies.find((el: Company) => {
      return el.companyName == res;
    });
    if (cmp) {
      // @ts-ignore
      this.$refs.searchInput.value = '';
      this.$store
        .dispatch('load_CyFiStatsCurrent_FiData_byCompanyId', cmp.companyID)
        .then((newRow) => {
          if (!newRow) return false;
          const tempList = this.$store.getters.peersGroupTempList;
          tempList.push(cmp.companyID);
          this.$store.commit('setTempGroupIDList', tempList);
          this.$store.commit('appendCyFiList', newRow);
        })
        .finally(() => {
          this.saveDisabled = false;
        });
    } else {
      this.saveDisabled = false;
    }
  }

  saveNewList() {
    const tempList = this.$store.getters.peersGroupTempList;
    this.$store.commit('setGroupIDList', tempList);
  }

  deleteCompany(id: string) {
    const tempList = this.$store.getters.peersGroupTempList;
    const resultList = tempList.filter((companyId: string) => companyId !== id);
    this.$store.commit('setTempGroupIDList', resultList);
    this.$store.commit('setGroupIDList', resultList);
    this.$store.commit('removeCyFiListById', id);
  }
}
