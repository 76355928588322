import { Plot } from '../Plot';
import { Point } from '../../Point';
import { MetaItem } from '../../series/SeriesXY';
import { Rectangle } from '../../Rectangle';

export const drawBarsRounded = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle,
  plotMetaArr: MetaItem[]
) {
  for (let i = 0; i < plotData.length; i++) {
    const { mainSize: width, lineWidth: radius } = this._options;
    const p = new Point(plotData[i].x, plotData[i].y - radius);
    const p0 = new Point(plotData[i].x, vp.zeroY - radius);
    if (plotMetaArr[i]) {
      const { color } = plotMetaArr[i];
      if (color) ctx.fillStyle = color;
    }
    const half = Math.round(width * 0.5) + radius;

    ctx.beginPath();
    ctx.moveTo(p.x - half, p0.y);
    ctx.lineTo(p.x - half, p.y);
    ctx.ellipse(p.x - half + radius, p.y, radius, radius, 0, -1 * Math.PI, -0.5 * Math.PI);
    ctx.lineTo(p.x + half - radius, p.y - radius);
    ctx.ellipse(p.x + half - radius, p.y, radius, radius, 0, -0.5 * Math.PI, 0 * Math.PI);
    ctx.lineTo(p.x + half, p0.y);
    ctx.ellipse(p.x + half - radius, p0.y, radius, radius, 0, 0 * Math.PI, 0.5 * Math.PI);
    ctx.lineTo(p.x - half + radius, p0.y + radius);
    ctx.ellipse(p.x - half + radius, p0.y, radius, radius, 0, 0.5 * Math.PI, 1 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }
};
