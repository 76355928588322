import { Company } from '@/types';
import * as ddb from './ddbUtils-config';

// @ts-ignore
import awsConfig from '../environments/awsconfig.js';
const { tableName } = awsConfig;

const HASHKEY = 'CompanyProfile#current';

const queryJSON: any = {
  TableName: tableName,
  IndexName: 'RevGSI',
  KeyConditionExpression: '#HASH = :hashKey',
  ExpressionAttributeNames: {
    // hard-coded, kept to make code more portable
    '#HASH': 'DataType',
  },
  ExpressionAttributeValues: {
    ':hashKey': HASHKEY,
  },
};

export const getCompanyList = async (): Promise<Company[]> => {
  let items: any = [];
  let data = await ddb.executeQuery(queryJSON);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    queryJSON.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(queryJSON);
    items = [...items, ...data.Items];
  }
  const mappedList: Array<Company> = items.map((el: any) => {
    const cmp: Company = {
      companyName: el.FullName,
      ISIN: el.ISIN,
      fullTickerCountry: el.FullTickerCountry,
      companyID: el.CompanyID,
      gicsSector: el.GICS_sector,
      industry: el.GICS_industry,
      runDate: '-',
      region: el.Region,
    };
    if (queryJSON.ExclusiveStartKey) delete queryJSON.ExclusiveStartKey;
    return cmp;
  });

  return mappedList;
};
