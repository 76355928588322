import { store } from '@/store';

export const getAdminCompanyPageTitle = () => {
  const companyName = store.getters.companyFullName;
  let title = 'Admin';
  if (companyName !== '') {
    title = title + ' - ' + companyName;
  }
  return title;
};
