
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SidebarPopup extends Vue {
  isOpened: boolean = false;

  toggle(withValue?: boolean) {
    if (withValue !== undefined) {
      this.isOpened = withValue;
    } else {
      this.isOpened = !this.isOpened;
    }
  }
}
