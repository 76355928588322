import { store } from '@/store';

export const getCustomerCompanyPageTitle = (pageTitle: string) => {
  const companyName = store.getters.companyFullName;
  let title = pageTitle;
  if (companyName !== '') {
    title = title + ' - ' + companyName;
  }
  return title;
};
