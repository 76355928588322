import { store } from '../index';

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import { Company, CompanyProfile } from '@/types';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { queryCompanyProfile } from '@/api-rosie/company/profile';
import { PolicyRoutesNames } from '@/router/customer-routes/policy/policy-routes';

const getAdminCompanyDataRequestArr = (id: string, name: string) => {
  return [
    store.dispatch('fetchSubsidiaries', name), // ✅
    store.dispatch('loadCompanyScores', id), // ✅
    store.dispatch('fetchCyData', id), // ✅
    store.dispatch('fetchRaeAlert', id),
    store.dispatch('fetchFiData', id), // ✅
    store.dispatch('fetchStockPrice', id), // ✅
    store.dispatch('loadCyberValuesData', id), // ✅
    store.dispatch('initGroupIDList'), // 🤞🏻
    store.dispatch('loadExecutiveSummaryList', id), // test
    store
      .dispatch('loadCyFiStatsCurrent', id)
      .then(() =>
        store
          .dispatch('loadSectorPeers', id)
          .then(() => store.dispatch('fetchSectorPeersChartsData'))
      ),
    store.dispatch('queryReports', id),
    store.dispatch('fetchCompanyMicroTrend'),
  ];
};

const getCustomerCompanyDataRequestArr = (id: string, name: string) => {
  return [
    store.dispatch('queryCorporateEvents', id),
    store.dispatch('queryCyberAlerts', id),
    store.dispatch('fetchMarketChangeList'),
    store.dispatch('fetchCompanyPolicyTimelineCurrent'),
    store.dispatch('fetchCompanyClaimCurrent'),
    store.dispatch('fetchCompanyPeerComps', 'current'),
    store.dispatch('fetchCompanyMicroTrend'),
  ];
};

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
});

export const api = axios.create({
  adapter: cache.adapter,
});

const _emptyCompany: Company = {
  companyID: '',
  companyName: '',
  gicsSector: '',
  ISIN: '',
  fullTickerCountry: '',
  region: '',
  industry: '',
  runDate: '',
};

@Module
class CompanyModule extends VuexModule {
  private info: Company = _emptyCompany;
  private profile: CompanyProfile = {
    CodeEod: '',
    CompanyID: '',
    ExchangeEod: '',
    FullName: '',
    GICS_industry: '',
    GICS_sector: '',
    Grouping: '',
    ISIN: '',
    Region: '',
    NumberOfSubs: '',
    EstimatedSubsidiariesNumber: undefined,
    MIC: '',
    Benchmark: '',
  };

  private disabledCompanyRoutes: string[] = [];

  newsdataLoading: boolean = false;

  get companyPeerSvg() {
    const name = this.companyFullName.toLowerCase();
    if (name.includes('acme')) return 'ACME';
    if (name.includes('astra') && name.includes('zeneca')) return 'AZ';
    return null;
  }

  get enabledRouteNames(): PolicyRoutesNames[] {
    const routes: PolicyRoutesNames[] = [
      'CustomerOverviewPage_policy',
      'EarlyWarning',
      'PolicyPeerBenchmarking',
    ];
    // if (this.companyPeerSvg) routes.push('PolicyPeerBenchmarking');
    return routes;
  }

  get companyInfo(): Company {
    return this.info;
  }

  get companyIsEmpty(): boolean {
    // @ts-ignore
    const values = Object.keys(this.info).map((key) => this.info[key]);
    const anyField = values.find((el) => {
      return el !== '';
    });
    if (anyField !== undefined) return false;
    return true;
  }

  get getDisabledCompanyRoutes(): string[] {
    return this.disabledCompanyRoutes;
  }

  @Action
  public async loadCompanyTabsData(id: string) {
    await store.dispatch('selectCompany', id);
    // store.commit('setDisabledCompanyRoutes', ['CompanyPeerBenchmarking']);
    await store.dispatch('loadCompanyProfile', id);
    store.commit('renewСonvertTable');
    const pArr = !store.getters.isRestrictedUser
      ? getAdminCompanyDataRequestArr(id, this.info.companyName)
      : getCustomerCompanyDataRequestArr(id, this.profile.FullName);
    return Promise.allSettled(pArr);
  }

  @Mutation
  public setDisabledCompanyRoutes(routeNames: string[]) {
    this.disabledCompanyRoutes = routeNames;
  }

  @Mutation
  public addDisabledCompanyRoute(routeName: string) {
    this.disabledCompanyRoutes.push(routeName);
  }

  @Action
  public async selectCompany(id: string) {
    const cmp: Company | undefined = store.getters.allCompanies.find((el: Company) => {
      return el.companyID == id;
    });
    if (cmp !== undefined) {
      store.commit('setCompanyInfo', cmp);
    } else {
      store.commit('setCompanyInfo', { ..._emptyCompany, companyID: id });
    }
  }

  @Mutation
  public setCompanyInfo(info: Company) {
    this.info = info;
  }

  @Action
  public async setCompany(searchName: string) {
    const cmp: Company = {
      ..._emptyCompany,
      companyName: searchName,
    };
    store.commit('setCompanyInfo', cmp);
  }

  @Action
  public async loadCompanyProfile(id: string) {
    return queryCompanyProfile(id)
      .then((list) => {
        if (list.length === 0) {
          throw new Error('company profile loaded is empty');
        }
        store.commit('setCompanyProfile', list[0]);
        return list[0];
      })
      .catch((err) => {
        console.error(err);
        const emptyProfile: CompanyProfile = {
          CodeEod: '',
          CompanyID: '',
          ExchangeEod: '',
          FullName: '',
          GICS_industry: '',
          GICS_sector: '',
          Grouping: '',
          ISIN: '',
          Region: '',
          NumberOfSubs: '',
          EstimatedSubsidiariesNumber: undefined,
          MIC: '',
          Benchmark: '',
        };
        store.commit('setCompanyProfile', emptyProfile);
      });
  }

  @Mutation
  public setCompanyProfile(newProfileData: CompanyProfile) {
    this.profile = { ...newProfileData };
  }

  get companyEod() {
    return {
      code: this.profile.CodeEod,
      exchange: this.profile.ExchangeEod,
    };
  }

  get companyProfile() {
    return this.profile;
  }

  get companyId() {
    return this.profile.CompanyID;
  }

  get companyFullName() {
    return this.profile.FullName;
  }

  @Action
  public async loadEodByCompanyId(id: string) {
    return queryCompanyProfile(id)
      .then((list) => {
        if (list.length === 0) {
          throw new Error('company profile loaded is empty');
        }
        return {
          code: list[0].CodeEod,
          exchange: list[0].ExchangeEod,
        };
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
export default CompanyModule;
