
import { Component, Vue, Ref } from 'vue-property-decorator';
import Header from './Header.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import SidebarPopup from '../../components/SidebarPopup.vue';
import FAQ from './sidebar-content/faq/FAQ.vue';

@Component({
  components: {
    Header,
    ProgressBar,
    SidebarPopup,
    FAQ,
  },
})
export default class RootPage extends Vue {
  @Ref() readonly sidebar!: SidebarPopup;

  created() {
    this.$store.dispatch('loadInitialData', 'customer');
  }

  get loadedOnce() {
    return this.$store.getters.initialDataIsLoaded;
  }

  get progress() {
    return this.$store.getters.initialDataProgress;
  }

  toggleSidebar() {
    this.sidebar.toggle();
  }
}
