import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const lineAreaVertical = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle
) {
  ctx.globalAlpha = this._options.opacity;
  const { x1, x2, y1, y2 } = this._options.vpOffset;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  const start = plotData[0];
  const end = plotData[plotData.length - 1];
  ctx.beginPath();
  ctx.moveTo(start.x, rect.y1);
  ctx.lineTo(end.x, rect.y1);
  ctx.lineTo(end.x, rect.y2);
  ctx.lineTo(start.x, rect.y2);
  ctx.closePath();
  ctx.fill();
};
