import { Tooltip, tooltipObject } from '../Tooltip';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { drawRoundRect } from '../../utils';

export const drawLabelXStart = function (this: Tooltip, t: tooltipObject) {
  t.ctx.strokeStyle = this._options.lineColor;
  t.ctx.lineWidth = this._options.lineWidth;
  t.ctx.fillStyle = this._options.brushColor;
  t.ctx.setLineDash(this._options.lineDash);

  // параметры
  const rectPadding = 6;
  const rectWidth = 60;

  const labelText = (this.labels && this.labels[t.ind]) || '';
  const cornersRadius = this._options.mainSize;

  const labelCoord = new Point(t.coord.x, t.vp.zeroY);
  const labelRect = this.label.getlabelRect(t.ctx, labelCoord, labelText);

  let labelCenter = new Point(labelCoord.x, labelRect.y1 + labelRect.height * 0.5);

  let roundRect: Rectangle = new Rectangle(
    labelCenter.x - rectWidth * 0.5,
    labelCenter.y - rectPadding - labelRect.height * 0.5,
    labelCenter.x + rectWidth * 0.5,
    labelCenter.y + rectPadding + labelRect.height * 0.5
  );

  labelCenter = new Point(labelCoord.x, labelRect.y1 + labelRect.height * 0.5);

  roundRect = new Rectangle(
    labelCenter.x - rectWidth * 0.5,
    labelCenter.y - rectPadding - labelRect.height * 0.5,
    labelCenter.x + rectWidth * 0.5,
    labelCenter.y + rectPadding + labelRect.height * 0.5
  );

  drawRoundRect(
    t.ctx,
    roundRect.x1,
    roundRect.y1,
    roundRect.width,
    roundRect.height,
    cornersRadius
  );

  t.ctx.fill();
  t.ctx.stroke();
  this.label.draw(t.ctx, labelCoord, labelText);

  return roundRect;
};
