
import { Component, Vue } from 'vue-property-decorator';

import LogoutBtn from '@/components/header/LogoutBtn.vue';

@Component({
  components: {
    LogoutBtn,
  },
})
export default class Header extends Vue {
  get username(): string {
    return this.$store.getters.username;
  }

  logout() {
    this.$store.commit('setLoadedOnce', false);
    window.CbhRosie.signOut().then(() => {
      this.$store.commit('setUserName', null);
      this.$store.commit('setDecodedToken', null);
      this.$router.push('/Login/userlogin');
      this.$store.dispatch('cleanUpCompaniesInitialData');
    });
  }
}
