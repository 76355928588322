import { store } from '../../index';
import axios from 'axios';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryExecutiveSummary } from '../../../api-rosie/company';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { tableName, apiGateWayUrl } = awsConfig;
import { ExecutiveSummaryRow } from '@/store/types/interfaces';

@Module
class ExecutiveSummaryModule extends VuexModule {
  private editorData: any = {};
  private executiveSummaryList: ExecutiveSummaryRow[] = [];

  get executiveSummaryData(): ExecutiveSummaryRow | null {
    const list = store.getters.executiveSummarySortedList;
    if (list.length >= 1) {
      return list[0];
    }
    return null;
  }

  get executiveSummarySortedList(): ExecutiveSummaryRow[] {
    return this.executiveSummaryList.sort((a, b) => {
      const dateA = new Date(a.ChTimestamp).getTime();
      const dateB = new Date(b.ChTimestamp).getTime();
      return dateB - dateA;
    });
  }

  @Mutation
  public setExecutiveSummaryList(data: any[]) {
    const filteredList = data.filter((row) => {
      const date = new Date(row.ChTimestamp);
      // @ts-ignore
      return row.EditorText && row.ChTimestamp && date instanceof Date && !isNaN(date);
    });
    this.executiveSummaryList.splice(0, this.executiveSummaryList.length, ...filteredList);
  }

  @Action
  public async loadExecutiveSummaryList(companyId: string) {
    return queryExecutiveSummary(companyId).then((res) => {
      store.commit('setExecutiveSummaryList', res);
    });
  }

  @Mutation
  public setEditorData(data: any) {
    this.editorData = data;
  }

  @Action
  public async updateEditorData(payload: { companyId: string; data: any }) {
    const data = JSON.stringify(payload.data);

    const cognitoToken = await window.CbhRosie.getAuthToken();

    const config = {
      method: 'put',
      url: `${apiGateWayUrl}loadExecutiveSummary?TableName=${tableName}&CompanyId=${payload.companyId}`,
      headers: {
        Authorization: cognitoToken,
      },
      data: {
        EditorText: data,
        UserLogin: store.getters.username,
      },
    };
    // @ts-ignore
    return axios(config)
      .then(function (response) {
        return store.dispatch('loadExecutiveSummaryList', payload.companyId);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  /*
  @Action
  public async updateEditorData(payload: { companyId: string; data: any }) {
    const data = JSON.stringify(payload.data);

    const config = {
      method: 'put',
      url: `${apiGateWayDdbUrl}loadExecutiveSummary?TableName=${tableName}&CompanyId=${payload.companyId}`,
      headers: {
        'x-api-key': apiKey,
        'Content-Type': 'text/plain',
      },
      data: {
        EditorText: data,
        UserLogin: store.getters.username,
      },
    };

    // @ts-ignore
    return axios(config)
      .then(function (response) {
        return store.dispatch('loadExecutiveSummaryList', payload.companyId);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  */

  /*
  @Action
  public async loadEditorData(companyId: string) {
    const config = {
      method: 'get',
      url: `${apiGateWayDdbUrl}editExecutiveSummary?TableName=${tableName}&CompanyId=${companyId}`,
      headers: {
        'x-api-key': apiKey,
      },
    };
    // @ts-ignore
    return axios(config)
      .then(function (response) {
        console.log(response);
        const data = response.data.Item.EditorData ? response.data.Item.EditorData : {};
        store.commit('setEditorData', data);
      })
      .catch(function (error) {
        console.error(`load executiveSummaryData failed`, error);
        store.commit('setEditorData', {});
      });
  }
  */
}
export default ExecutiveSummaryModule;
