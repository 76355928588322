import { Ticks } from '../Ticks';
import { Rectangle } from '../../Rectangle';
import { Point } from '../../Point';
import { Transformer } from '../../Transformer';

export const generateTimestampDayStart = function (
  this: Ticks,
  min: number,
  max: number,
  vp: Rectangle
): Point[] {
  const coords = [];
  this.values = [];
  this.labels = [];

  const transformer = new Transformer();
  const fromRect = new Rectangle(min, 0, max, 1);

  const startDate = new Date(min);
  const endDate = new Date(max);

  const firstTickDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  let curDate = firstTickDate;

  while (curDate <= endDate) {
    const value = curDate.getTime();
    const coordPoint = transformer.getVeiwportCoord(fromRect, vp, new Point(value, 0));
    coords.push(coordPoint);
    this.values.push(value);
    this.labels.push(
      curDate.toLocaleString('en-GB', { day: 'numeric', month: 'long' }).toUpperCase()
    );
    curDate = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate() + 1);
  }

  this.labels = this.labels.map((value, index, arr) => {
    switch (index) {
      case 0:
      case arr.length - 1:
        return value;
      default:
        return '';
    }
  });

  return coords;
};
