
import { Component, Vue } from 'vue-property-decorator';
import { ToggleButton } from 'vue-js-toggle-button';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

Vue.component('ToggleButton', ToggleButton);
Vue.component('VSelect', vSelect);

import './styles/styles.scss';

@Component
export default class App extends Vue {}
