/* tslint:disable:no-console */
import Vue from 'vue';
import VueRouter from 'vue-router';

// store
import { store } from '../store/index';

// Pages
import LoginPage from '../pages/LoginPage/index.vue';
import UserLoginPage from '../pages/LoginPage/UserLoginPage.vue';
import ForgotPasswordPage from '../pages/LoginPage/ForgotPasswordPage.vue';

// routes
import adminRoutes from './admin-routes/index';
import customerRoutes from './customer-routes/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/Login',
    name: 'LoginPage',
    // redirect: '/Login/userlogin',
    component: LoginPage,
    children: [
      {
        component: UserLoginPage,
        path: '/Login/userlogin',
        name: 'UserLoginPage',
        meta: {
          getTitle: () => 'Login - Intangic CyFi™ Dashboard',
        },
      },
      {
        component: ForgotPasswordPage,
        path: '/Login/forgot',
        name: 'ForgotPasswordPage',
        meta: {
          getTitle: () => 'Login - Intangic CyFi™ Dashboard',
        },
      },
    ],
  },
  ...adminRoutes,
  ...customerRoutes,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  window.CbhRosie.checkAuth()
    .then(async (authToken: string | null) => {
      store.dispatch('updateSessionInfo', authToken).then(() => {
        if (to.path === '/Login/userlogin') {
          next({ path: '/' });
        } else {
          next();
        }
        if (to.path === '/') {
          next({ path: store.getters.userRedirectPath });
        }
      });
    })
    .catch((err: Error) => {
      if (to.path === '/Login/userlogin' || to.path === '/Login/forgot') {
        next();
      } else {
        next({ path: '/Login/userlogin', query: { redirect: to.path } });
      }
    });
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = 'Intangic CyFi™ Dashboard';
    if (to.meta?.getTitle) {
      title = to.meta.getTitle(to.meta.title);
    }
    document.title = title;
  });
});

export default router;
