import { Plot } from '../Plot';
import { Point } from '../../Point';
import { MetaItem } from '../../series/SeriesXY';

export const drawPolicyBars = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  plotMetaArr: MetaItem[]
) {
  for (let i = 0; i < plotData.length; i++) {
    const { mainSize, offset } = this._options;
    const p = new Point(plotData[i].x + offset.x, plotData[i].y + offset.y);
    let barHeight = 100;
    if (plotMetaArr[i]) {
      const { color, size } = plotMetaArr[i];
      if (color) ctx.fillStyle = color;
      if (size) barHeight = size;
    }

    ctx.beginPath();
    ctx.moveTo(p.x - mainSize, p.y - mainSize);
    ctx.lineTo(p.x - mainSize, p.y - mainSize - barHeight);
    ctx.arc(p.x, p.y - mainSize - barHeight, mainSize, -1 * Math.PI, 0 * Math.PI);
    ctx.lineTo(p.x + mainSize, p.y - mainSize);
    ctx.arc(p.x, p.y - mainSize, mainSize, 0, 1 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }
};
