// Peer Benchmarking Pages
const CustomGroupPage = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/PeerBenchmarkingPage/peers-subpages/CustomGroup/index.vue'
  );
const SectorPeersPage = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/PeerBenchmarkingPage/peers-subpages/SectorPeers/index.vue'
  );
const PeerBenchmarkingAreaPage = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/customer-pages/Company/PeerBenchmarkingArea/PeerBenchmarkingAreaPage.vue'
  );

import { getAdminCompanyPageTitle } from './utils';

export const peerbenchmarkingRoutes = [
  {
    component: SectorPeersPage,
    path: 'sector_peers',
    name: 'SectorPeers',
    meta: {
      adminRoute: true,
      companyRoute: true,
      peersbenchmarkingRoute: true,
      title: 'Sector Peers',
      menuTitle: 'Peer Benchmarking',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: CustomGroupPage,
    path: 'custom_group',
    name: 'CustomGroup',
    meta: {
      adminRoute: true,
      companyRoute: true,
      peersbenchmarkingRoute: true,
      title: 'Custom Group',
      menuTitle: 'Peer Benchmarking',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: PeerBenchmarkingAreaPage,
    path: 'peer_benchmarking',
    name: 'PeerBenchmarking_admin',
    meta: {
      companyRoute: true,
      title: 'Peer Benchmarking',
      menuTitle: 'Peer Benchmarking',
      getTitle: getAdminCompanyPageTitle,
    },
  },
];
