import { Meta } from './meta';

export type Nullable<T> = T | null;

export interface ScaledRaeData {
  companyID: string;
  timeStamp: Date;
  Attack: number;
  Attack_rs: number;
  Exfil: number;
  Exfil_rs: number;
  Recon: number;
  Recon_rs: number;
  slRating: number;
}

export type CyRow = {
  companyID: string;
  timeStamp: Date;
  clients: number;
  pages: { low: number; mid: number; high: number };
  markets: { low: number; mid: number; high: number };
  forums: { low: number; mid: number; high: number };
  pastes: { low: number; mid: number; high: number };
  exits: { low: number; mid: number; high: number };
  osint: { low: number; mid: number; high: number };
};

export interface Company {
  companyID: string;
  companyName: string;
  gicsSector: string;
  ISIN: string;
  fullTickerCountry: string;
  region: string;
  industry: string;
  runDate: string;
}

export interface CompanyProfile {
  CodeEod: string;
  CompanyID: string;
  CreationTime?: number;
  DataType?: string;
  ExchangeEod: string;
  ExpirationTime?: number;
  FullName: string;
  GICS_industry: string;
  GICS_sector: string;
  Grouping: string;
  ISIN: string;
  Region: string;
  NumberOfSubs?: string;
  EstimatedSubsidiariesNumber?: string;
  MIC: string;
  Benchmark: string;
}

export type CyFiScoreRow = {
  awareness: number | '-';
  capitalEfficiency: number | '-';
  capitalManagement: number | '-';
  cbhScore: number | '-';
  cbhScore_12ma: number | '-';
  cbhSectorScore: number | '-';
  cbhSectorScore_12ma: number | '-';
  chTimestamp: Date;
  complexity: number | '-';
  ebitCM: number | '-';
  financialExposure: number | '-';
  marketCap: number | '-';
  nopatCM: number | '-';
  productivity: number | '-';
  pullDate: Date;
  sentiment: number | '-';
  vulnerability: number | '-';
};

export type EODrow = {
  companyID: string;
  code: string | null;
  exchange: string | null;
};

export type CyFiStatsRow = {
  Ticker: string;
  ChTimestamp: Date;
  CompanyID: string;
  Grouping: string;
  DataType: string;
  UnderwritingStatus: string;
  MarketCapitalization: number | '-';

  SectorScore: number | '-';
  SectorScore_3ma: number | '-';
  SectorScore_12ma: number | '-';
  SectorScore_24ma: number | '-';

  Productivity: number | '-';
  Productivity_3ma: number | '-';
  Productivity_12ma: number | '-';
  Productivity_24ma: number | '-';

  Attack: number | '-';
  Attack_3ma: number | '-';
  Attack_12ma: number | '-';
  Attack_24ma: number | '-';

  Recon: number | '-';
  Recon_3ma: number | '-';
  Recon_12ma: number | '-';
  Recon_24ma: number | '-';

  Exfil: number | '-';
  Exfil_3ma: number | '-';
  Exfil_12ma: number | '-';
  Exfil_24ma: number | '-';

  FinancialExposure: number | '-';
  FinancialExposure_3ma: number | '-';
  FinancialExposure_12ma: number | '-';
  FinancialExposure_24ma: number | '-';

  CyberRank: number | '-';
  CyberRank_3ma: number | '-';
  CyberRank_12ma: number | '-';
  CyberRank_24ma: number | '-';

  CapitalEfficiency: number | '-';
  CapitalEfficiency_3ma: number | '-';
  CapitalEfficiency_12ma: number | '-';
  CapitalEfficiency_24ma: number | '-';

  CapitalManagement: number | '-';
  CapitalManagement_3ma: number | '-';
  CapitalManagement_12ma: number | '-';
  CapitalManagement_24ma: number | '-';

  OperatingEfficiency: number | '-';
  OperatingEfficiency_3ma: number | '-';
  OperatingEfficiency_12ma: number | '-';
  OperatingEfficiency_24ma: number | '-';

  Momo: number | '-';
  Momo_3ma: number | '-';
  Momo_12ma: number | '-';
  Momo_24ma: number | '-';
};

export type CyFiStatsRowMeta = {
  ChTimestamp: Date;
  CompanyID: string;
  Grouping: string;
  DataType: string;
  UnderwritingStatus: string;
  MarketCapitalization: number | '-';

  SectorScore: Meta;
  SectorScore_3ma: Meta;
  SectorScore_12ma: Meta;
  SectorScore_24ma: Meta;

  Productivity: Meta;
  Productivity_3ma: Meta;
  Productivity_12ma: Meta;
  Productivity_24ma: Meta;

  Attack: Meta;
  Attack_3ma: Meta;
  Attack_12ma: Meta;
  Attack_24ma: Meta;

  Recon: Meta;
  Recon_3ma: Meta;
  Recon_12ma: Meta;
  Recon_24ma: Meta;

  Exfil: Meta;
  Exfil_3ma: Meta;
  Exfil_12ma: Meta;
  Exfil_24ma: Meta;

  FinancialExposure: Meta;
  FinancialExposure_3ma: Meta;
  FinancialExposure_12ma: Meta;
  FinancialExposure_24ma: Meta;

  CyberRank: Meta;
  CyberRank_3ma: Meta;
  CyberRank_12ma: Meta;
  CyberRank_24ma: Meta;

  CapitalEfficiency: Meta;
  CapitalEfficiency_3ma: Meta;
  CapitalEfficiency_12ma: Meta;
  CapitalEfficiency_24ma: Meta;

  CapitalManagement: Meta;
  CapitalManagement_3ma: Meta;
  CapitalManagement_12ma: Meta;
  CapitalManagement_24ma: Meta;

  OperatingEfficiency: Meta;
  OperatingEfficiency_3ma: Meta;
  OperatingEfficiency_12ma: Meta;
  OperatingEfficiency_24ma: Meta;

  Momo: Meta;
  Momo_3ma: Meta;
  Momo_12ma: Meta;
  Momo_24ma: Meta;
};

const somedate = new Date();
export const emptyCyFiStatsRow: CyFiStatsRow = {
  ChTimestamp: somedate,
  CompanyID: '-',
  Grouping: '-',
  DataType: '-',
  UnderwritingStatus: '',
  MarketCapitalization: 0,

  SectorScore: 0,
  SectorScore_3ma: 0,
  SectorScore_12ma: 0,
  SectorScore_24ma: 0,

  Productivity: 0,
  Productivity_3ma: 0,
  Productivity_12ma: 0,
  Productivity_24ma: 0,

  Attack: 0,
  Attack_3ma: 0,
  Attack_12ma: 0,
  Attack_24ma: 0,

  Recon: 0,
  Recon_3ma: 0,
  Recon_12ma: 0,
  Recon_24ma: 0,

  Exfil: 0,
  Exfil_3ma: 0,
  Exfil_12ma: 0,
  Exfil_24ma: 0,

  FinancialExposure: 0,
  FinancialExposure_3ma: 0,
  FinancialExposure_12ma: 0,
  FinancialExposure_24ma: 0,

  CyberRank: 0,
  CyberRank_3ma: 0,
  CyberRank_12ma: 0,
  CyberRank_24ma: 0,

  CapitalEfficiency: 0,
  CapitalEfficiency_3ma: 0,
  CapitalEfficiency_12ma: 0,
  CapitalEfficiency_24ma: 0,

  CapitalManagement: 0,
  CapitalManagement_3ma: 0,
  CapitalManagement_12ma: 0,
  CapitalManagement_24ma: 0,

  OperatingEfficiency: 0,
  OperatingEfficiency_3ma: 0,
  OperatingEfficiency_12ma: 0,
  OperatingEfficiency_24ma: 0,

  Momo: 0,
  Momo_3ma: 0,
  Momo_12ma: 0,
  Momo_24ma: 0,
  Ticker: '',
};

export const cyFiStatsMetaFieldNames: string[] = [
  'SectorScore',
  'SectorScore_3ma',
  'SectorScore_12ma',
  'SectorScore_24ma',

  'Productivity',
  'Productivity_3ma',
  'Productivity_12ma',
  'Productivity_24ma',

  'Attack',
  'Attack_3ma',
  'Attack_12ma',
  'Attack_24ma',

  'Recon',
  'Recon_3ma',
  'Recon_12ma',
  'Recon_24ma',

  'Exfil',
  'Exfil_3ma',
  'Exfil_12ma',
  'Exfil_24ma',

  'FinancialExposure',
  'FinancialExposure_3ma',
  'FinancialExposure_12ma',
  'FinancialExposure_24ma',

  'CyberRank',
  'CyberRank_3ma',
  'CyberRank_12ma',
  'CyberRank_24ma',

  'CapitalEfficiency',
  'CapitalEfficiency_3ma',
  'CapitalEfficiency_12ma',
  'CapitalEfficiency_24ma',

  'CapitalManagement',
  'CapitalManagement_3ma',
  'CapitalManagement_12ma',
  'CapitalManagement_24ma',

  'OperatingEfficiency',
  'OperatingEfficiency_3ma',
  'OperatingEfficiency_12ma',
  'OperatingEfficiency_24ma',

  'Momo',
  'Momo_3ma',
  'Momo_12ma',
  'Momo_24ma',
];
