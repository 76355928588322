import { store } from '@/store';
import { Route, NavigationGuardNext } from 'vue-router';

import CustomerRootPage from '@/pages/customer-pages/index.vue';
import CustomerCompanyPage from '@/pages/customer-pages/Company/index.vue';

import { companyRoutes } from './company-routes';
import { companyRoutes as policyCompanyRoutes } from './policy/policy-routes';

const routes = [
  {
    component: CustomerRootPage,
    path: '/advisory',
    name: 'CustomerRootPage',
    redirect: '/advisory/Company',
    beforeEnter: (_to: Route, _from: Route, next: NavigationGuardNext) => {
      console.warn('CustomerRootPage guard');
      if (store.getters.isRestrictedUser) {
        if (store.getters.isAdvisoryUser) {
          next();
        }
      } else {
        next({ path: store.getters.userRedirectPath });
      }
    },
    children: [
      {
        component: CustomerCompanyPage,
        path: 'Company/:companyId?',
        name: 'CustomerCompanyPage',
        children: [...companyRoutes],
      },
    ],
  },

  {
    component: CustomerRootPage,
    path: '/policy',
    name: 'CustomerRootPage_policy',
    redirect: '/policy/Company',
    children: [
      {
        component: CustomerCompanyPage,
        path: 'Company/:companyId?',
        name: 'CustomerCompanyPage_policy',
        children: [...policyCompanyRoutes],
      },
    ],
  },
];

export default routes;
