import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';

const RANGECOND_CLAIM_CURRENT = 'Claim#current';

export interface ClaimProcessItem {
  Date: Date;
  ClaimPaidDate?: Date;
  ConfirmationOfLossDate?: Date;
}

const prepareClaimProcessItem = (item: any): ClaimProcessItem => ({
  ...item,
  Date: new Date(item['Date']),
  ClaimPaidDate: item['ClaimPaidDate'] === '-' ? undefined : new Date(item['ClaimPaidDate']),
  ConfirmationOfLossDate:
    item['ConfirmationOfLossDate'] === '-' ? undefined : new Date(item['ConfirmationOfLossDate']),
});

@Module
class ClaimProcessModule extends VuexModule {
  private claimCurrent: ClaimProcessItem | undefined = undefined;

  @Action
  public async fetchCompanyClaimCurrent() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_CLAIM_CURRENT)
      .then((dataList) => {
        if (dataList[0]) {
          store.commit('setClaimProcessCurrent', dataList[0]);
        } else {
          throw new Error(RANGECOND_CLAIM_CURRENT + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        store.commit('setClaimProcessCurrent', undefined);
      });
  }

  @Mutation
  public setClaimProcessCurrent(data: any) {
    if (data) {
      this.claimCurrent = prepareClaimProcessItem(data);
    } else {
      this.claimCurrent = data;
    }
  }

  get claimCurrentData(): ClaimProcessModule['claimCurrent'] {
    return this.claimCurrent;
  }
}
export default ClaimProcessModule;
