import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const drawLabelXTop = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  labels: string[],
  vp: Rectangle
) {
  const { x1, x2, y1, y2 } = this._options.vpOffset;
  const { offset } = this._options;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  for (let i = 0; i < plotData.length; i++) {
    const x = plotData[i].x + offset.x;
    const y = rect.y1 + offset.y;
    this.label.draw(ctx, new Point(x, y), labels[i]);
  }
};
