import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { Nullable } from '@/types';
import axios from 'axios';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
import { BreachProbabilityModule, BreachProbabilityRisk } from './breach-probability';
const { severityApiUrl } = awsConfig;

const RANGECOND_LOSS_SEVERITY = 'LossSeverity#current';

export enum LossSeverityRiskGroup {
  'Low' = 'Low',
  'Medium' = 'Medium',
  'Medium-High' = 'Medium-High',
  'High' = 'High',
  'Highest' = 'Highest',
}

const riskBreachMap: Record<BreachProbabilityRisk, LossSeverityRiskGroup | null> = {
  [BreachProbabilityRisk.LowRiskProb]: LossSeverityRiskGroup['Low'],
  [BreachProbabilityRisk.LowMediumRiskProb]: null,
  [BreachProbabilityRisk.MediumRiskProb]: LossSeverityRiskGroup['Medium'],
  [BreachProbabilityRisk.MediumMediumHighRiskProb]: null,
  [BreachProbabilityRisk.MediumHighRiskProb]: LossSeverityRiskGroup['Medium-High'],
  [BreachProbabilityRisk.MediumHighHighRiskProb]: null,
  [BreachProbabilityRisk.HighRiskProb]: LossSeverityRiskGroup['High'],
  [BreachProbabilityRisk.HighHighestRiskProb]: null,
  [BreachProbabilityRisk.HighestRiskProb]: LossSeverityRiskGroup['Highest'],
};

interface LossSeverityQueryResponse {
  RiskGroup?: LossSeverityRiskGroup;
  DataType?: string;
  LossSeverityPlot?: string;
  DataPoints?: string;
}

export enum LossSeverityUnit {
  mln = 'mln',
  k = 'k',
}

export type LossSeverityPlotItem = {
  LossSeverityAxisXCurrency: 'USD' | 'EUR';
  LossSeverityAxisXUnit: LossSeverityUnit;
  LossSeverityAxisX: number;
  LossSeverityAxisXRaw: number;
  FrequencyAxisY: number;
  FrequencyAxisYLegend: string;
  NetPresentValue?: number;
  NetPresentValueLegend?: LossSeverityUnit;
};

type LossSeverityPlotsData = Partial<Record<LossSeverityRiskGroup, LossSeverityPlotItem[]>>;

const parseLossSeverityPlotPoints = (item: string): LossSeverityPlotItem[] => {
  let arr: LossSeverityPlotItem[] = [];
  try {
    const obj = JSON.parse(item);
    if (Array.isArray(obj)) {
      arr = [...obj];
    } else if (obj.hasOwnProperty('DataPoints')) {
      arr = [...obj.DataPoints];
    }
  } catch (e) {
    console.error(e);
  }
  return arr.map((el) => {
    switch (el.LossSeverityAxisXUnit) {
      case LossSeverityUnit.mln:
        el.LossSeverityAxisXRaw = el.LossSeverityAxisX * 1_000_000;
        break;
      case LossSeverityUnit.k:
        el.LossSeverityAxisXRaw = el.LossSeverityAxisX * 1_000;
        break;
    }
    return el;
  });
};

@Module({ dynamic: true, store, name: 'lossSeverity' })
class LossSeverity extends VuexModule {
  private plotsData: LossSeverityPlotsData = {};
  private plotsDataWithNPV: LossSeverityPlotsData = {};
  private riskGroup: Nullable<LossSeverityRiskGroup> = null;
  private weightedNpv: { id: string; data: Partial<Record<LossSeverityRiskGroup, number>> }[] = [];

  @Action({ commit: 'setLossSeverityPlots' })
  public fetchLossSeverityPlots() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_LOSS_SEVERITY)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_LOSS_SEVERITY + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setLossSeverityPlots(data: LossSeverityQueryResponse[]) {
    if (Array.isArray(data)) {
      const [p1, p2] = data.reduce(
        ([acc1, acc2], cur) => {
          const plotField = cur.LossSeverityPlot || cur.DataPoints;
          if (cur.RiskGroup && plotField) {
            if (!cur.DataType?.includes('#current#')) {
              this.riskGroup = cur.RiskGroup;
            } else {
              const items = parseLossSeverityPlotPoints(plotField);
              acc1[cur.RiskGroup] = [...items];
              acc2[cur.RiskGroup] = [...items.filter((el) => el.NetPresentValue)];
            }
          }
          return [acc1, acc2];
        },
        [{}, {}] as [LossSeverityPlotsData, LossSeverityPlotsData]
      );
      Object.assign(this.plotsData, p1);
      Object.assign(this.plotsDataWithNPV, p2);
    } else {
      this.riskGroup = null;
      Object.assign(this.plotsData, {});
      Object.assign(this.plotsDataWithNPV, {});
    }

    //set risk
    if (BreachProbabilityModule.breachCurrentData) {
      this.riskGroup = null;
      const res = Object.entries(BreachProbabilityModule.breachCurrentData.scheme).find(
        ([_key, value]) => {
          return BreachProbabilityModule.breachCurrentData?.BreachProbability === value;
        }
      );
      if (res) {
        this.riskGroup = riskBreachMap[res[0] as BreachProbabilityRisk];
      }
    }
  }

  get plots() {
    return this.plotsData;
  }

  get plotsWithNPV() {
    return this.plotsDataWithNPV;
  }

  get currentRiskGroup() {
    return this.riskGroup;
  }

  get currentPlot() {
    return this.plotsWithNPV[LossSeverityModule.currentRiskGroup || 'Low'];
  }

  get plotsWithNPVLength() {
    return this.currentPlot?.length || 0;
  }

  get currency() {
    if (!this.currentPlot) return 'USD';
    return this.currentPlot[0].LossSeverityAxisXCurrency;
  }

  get unit() {
    if (!this.currentPlot) return LossSeverityUnit.mln;
    return this.currentPlot[0].LossSeverityAxisXUnit;
  }

  @Action
  public async fetchWeightedNpv({
    severityFrom,
    severityTo,
    id,
  }: {
    severityFrom: number;
    severityTo: number;
    id: string;
  }) {
    if (this.weightedNpv.find((el) => el.id === id)) return;
    const cognitoToken = await window.CbhRosie.getAuthToken();
    const companyId = store.getters.companyId;
    return axios({
      url: `${severityApiUrl}weightedNpv`,
      method: 'post',
      headers: {
        Authorization: cognitoToken,
      },
      data: {
        companyID: companyId,
        severityFrom,
        severityTo,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          store.commit('setWeightedNpv', { id, data: response.data.weightedNpv });
          return response.data;
        }
        throw new Error();
      })
      .catch(() => {
        console.error(`load weightedNpv data failed`);
        store.commit('setWeightedNpv', { id, data: [] });
      });
  }

  @Mutation
  public setWeightedNpv({ id, data }: { id: string; data: any[] }) {
    const res = data.reduce((pre, cur) => {
      pre[cur.RiskGroup] = cur.Weighted_NPV;
      return pre;
    }, {} as typeof LossSeverityModule.weightedNpv);
    this.weightedNpv.splice(this.weightedNpv.length - 1, 0, { id, data: res });
  }

  get weightedNpvMap() {
    return this.weightedNpv;
  }
}

export const LossSeverityModule = getModule(LossSeverity);
