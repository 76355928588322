import { Chart } from '../../../../../../../libs/chart-lib/index';
import { Ticks } from '../../../../../../../libs/chart-lib/index';

// @ts-ignore
// import { customLoadDataFromCsv, csvToCols, bezier } from "cbh_helpers";

// common decoration variables
const yTicksDrawOptions: [number, number, string] = [-4, 1, '#FFFFFF80'];
const yGridDrawOptions: [boolean, string, number, number[]] = [false, '#FFFFFF33', 1, [1, 2]];
const canvasPaddings = [0, 0, 0, 0];

export let chart_axis: Chart;
export let chart_1: Chart;
export let chart_2: Chart;
export let chart_3: Chart;
export let chart_4: Chart;

// @ts-ignore

export function createChart_2(container: HTMLElement, data: any[]) {
  let row_forums: number[] = [];
  let row_pastes: number[] = [];
  let row_osint: number[] = [];
  let xLabels: string[] = [];

  // @ts-ignore
  //chart = new Chart(container, [0, 900], [0, 2000]);
  [xLabels, row_forums, row_pastes, row_osint] = [...data];
  const xMax = row_forums.length;

  let chart = chart_2;

  chart = new Chart(container, [0, xMax], [0, 10]);

  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(false, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(true, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-forums', 'bar_chart', 1, '#272727', '#8F73CA66', 2); // forums
  chart.addPlot('bar-chart-pastes', 'bar_chart', 1, '#272727', '#5B3F9566', 2); // pastes
  chart.addPlot('bar-chart-osint', 'bar_chart', 1, '#272727', '#5C5C5C66', 2); // osint

  // создаем Series
  const row_2 = row_forums.map((el, index) => el + row_pastes[index]);
  const row_3 = row_forums.map((el, index) => el + row_pastes[index] + row_osint[index]);
  chart.addSeriesRow('bar-chart-2', [row_3]).setPlotsIds('bar-chart-osint');
  chart.addSeriesRow('bar-chart-2', [row_2]).setPlotsIds('bar-chart-pastes');
  chart.addSeriesRow('bar-chart-1', [row_forums]).setPlotsIds('bar-chart-forums');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-forums')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#8F73CA', 2);
  chart
    .findPlotById('bar-chart-pastes')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#5B3F95', 2);
  chart
    .findPlotById('bar-chart-osint')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#5C5C5C', 2);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val')); //по экстремумам оси X
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);
  // yAxis.setMinMaxStatic(chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max)); //scale to fit по Y
  // yAxis.setMinMaxStatic([chart.yAxis.min - gapY * chart.yAxis.length, chart.yAxis.max + gapY * chart.yAxis.length]); //добавляем по отступам как на сайте
  // задаем отступы для области отрисовки
  chart.setCanvasPaddings(...canvasPaddings);

  chart.refresh();
  return chart;
}

export function createChart_3(container: HTMLElement, data: any[]) {
  let row_markets: number[] = [];
  let row_exits: number[] = [];
  let xLabels: string[] = [];

  // @ts-ignore
  //chart = new Chart(container, [0, 900], [0, 2000]);
  [xLabels, row_markets, row_exits] = [...data];
  const xMax = row_markets.length;

  let chart = chart_3;

  chart = new Chart(container, [0, xMax], [0, 10]);

  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(false, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(false, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-markets', 'bar_chart', 1, '#272727', '#C2674066', 2); // markets
  chart.addPlot('bar-chart-exits', 'bar_chart', 1, '#272727', '#50312366', 2); // exits

  // создаем Series
  const row_2 = row_markets.map((el, index) => el + row_exits[index]);
  chart.addSeriesRow('bar-chart-2', [row_2]).setPlotsIds('bar-chart-exits');
  chart.addSeriesRow('bar-chart-1', [row_markets]).setPlotsIds('bar-chart-markets');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-markets')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#C26740', 2);
  chart
    .findPlotById('bar-chart-exits')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#503123', 2);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val')); //по экстремумам оси X
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);
  // yAxis.setMinMaxStatic(chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max)); //scale to fit по Y
  // yAxis.setMinMaxStatic([chart.yAxis.min - gapY * chart.yAxis.length, chart.yAxis.max + gapY * chart.yAxis.length]); //добавляем по отступам как на сайте
  // задаем отступы для области отрисовки
  chart.setCanvasPaddings(...canvasPaddings);

  chart.refresh();
  return chart;
}

export function createChart_4(container: HTMLElement, data: any[]) {
  let row_clients: number[] = [];
  let xLabels: string[] = [];

  // @ts-ignore
  //chart = new Chart(container, [0, 900], [0, 2000]);
  [xLabels, row_clients] = [...data];
  const xMax = row_clients.length;

  let chart = chart_4;

  chart = new Chart(container, [0, xMax], [0, 10]);

  const { xAxis, yAxis } = chart;

  // ось X
  xAxis.display = false;
  xAxis.ticks.label.setOptions(false);

  // ось Y
  yAxis.setOptions('end');
  yAxis.ticks.settickDrawOptions(...yTicksDrawOptions);
  yAxis.ticks.setOptions(false, 'fixedCount', [2]);
  yAxis.ticks.label
    .setOptions(false, '#B2B2B2', 'right', 0)
    .setFontOptions(12, 'Inter', 'normal')
    .setCenterX(0)
    .setOffset(10, 0);
  yAxis.ticks.setCustomLabels(['LOW', 'MEDIUM', 'HIGH']);
  yAxis.grid.setOptions(...yGridDrawOptions);

  // создаем Plots
  chart.addPlot('bar-chart-clients', 'bar_chart', 1, '#272727', '#CFA05866', 2); // clients

  // создаем Series
  chart.addSeriesRow('bar-chart-1', [row_clients]).setPlotsIds('bar-chart-clients');

  // создаем Tooltips
  chart
    .findPlotById('bar-chart-clients')
    ?.addTooltip('ttId', 'bar_chart_highlighter', 1, '#272727', '#CFA058', 2);

  // настраиваем Min Max осей
  xAxis.setMinMaxStatic(chart.data.findExtremes('val')); //по экстремумам оси X
  xAxis.setMinMaxStatic([chart.xAxis.min - 0.5, chart.xAxis.max + 0.5]);
  const [extYmin, extYmax] = chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max);
  if (extYmax !== extYmin) {
    // yAxis.setMinMaxStatic(chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max)); //scale to fit по Y
  }
  // yAxis.setMinMaxStatic([chart.yAxis.min - gapY * chart.yAxis.length, chart.yAxis.max + gapY * chart.yAxis.length]); //добавляем по отступам как на сайте
  // задаем отступы для области отрисовки
  chart.setCanvasPaddings(...canvasPaddings);

  chart.refresh();
  return chart;
}
