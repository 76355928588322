
import { Component, Vue } from 'vue-property-decorator';
import Triggers from './triggers.vue';
import MaliciousActivity from './maliciousActivity.vue';

@Component({
  components: {
    triggers: Triggers,
    malicious_activity: MaliciousActivity,
  },
})
export default class FAQ extends Vue {
  get faqContent(): string | undefined {
    const faqKey: string | undefined = this.$route.meta?.faq;
    return faqKey;
  }
}
