import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { parseCbhDate } from '@/utilities';

const RANGECOND_MARKET_CHANGE_LIST = 'PercentChangeVsMarket#';

export interface MarketChangeItem {
  PercentChange: number;
  Date: Date;
  PercentPeriodEndDate?: Date;
  PercentPeriodStartDate?: Date;
  CalculationPeriodStartDate?: Date;
  Status: 'Activated' | 'Loss confirmation';
  PercentPeriodNumberOfCalendarDays: number;
  CalculationPeriodEndDate?: Date;
  SecondTriggerPeriodEndDate?: Date;
  CalculationPeriodDaysRemaining: number;
  SecondTriggerPeriodStartDate?: Date;
}

@Module
class MarketChangeModule extends VuexModule {
  private marketChangeList: Array<MarketChangeItem> = [];

  get companyMarketChange(): MarketChangeItem | undefined {
    return this.marketChangeList.slice(-1)[0];
  }

  get companyMarketChangeList() {
    return this.marketChangeList;
  }

  @Mutation
  public setCompanyMarketChange(list: any[]) {
    this.marketChangeList = list.map((data) => {
      const res: MarketChangeItem = {
        Status: data.Status,
        PercentChange: Number(data.PercentChange),
        PercentPeriodNumberOfCalendarDays: Number(data.PercentPeriodNumberOfCalendarDays),
        CalculationPeriodDaysRemaining: Number(data.CalculationPeriodDaysRemaining),
        Date: new Date(data.Date),
        PercentPeriodEndDate: parseCbhDate(data.PercentPeriodEndDate),
        PercentPeriodStartDate: parseCbhDate(data.PercentPeriodStartDate),
        CalculationPeriodStartDate: parseCbhDate(data.CalculationPeriodStartDate),
        CalculationPeriodEndDate: parseCbhDate(data.CalculationPeriodEndDate),
        SecondTriggerPeriodEndDate: parseCbhDate(data.SecondTriggerPeriodEndDate),
        SecondTriggerPeriodStartDate: parseCbhDate(data.SecondTriggerPeriodStartDate),
      };
      return res;
    });
  }

  @Action
  public async fetchMarketChangeList() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_MARKET_CHANGE_LIST)
      .then((data) => {
        store.commit('setCompanyMarketChange', data);
      })
      .catch((err) => {
        store.commit('setCompanyMarketChange', []);
        console.error(`load MarketChange failed`);
      });
  }
}
export default MarketChangeModule;
