// Peer Benchmarking Pages
import CustomGroupPage from '@/pages/customer-pages/Company/PeerBenchmarkingPage/peers-subpages/CustomGroup/index.vue';
import SectorPeersPage from '@/pages/customer-pages/Company/PeerBenchmarkingPage/peers-subpages/SectorPeers/index.vue';
import { getCustomerCompanyPageTitle } from './utils';

export const peerbenchmarkingRoutes = [
  {
    component: SectorPeersPage,
    path: 'sector_peers',
    name: 'CustomerSectorPeers',
    meta: {
      companyRoute: true,
      peersbenchmarkingRoute: true,
      title: 'Peer Benchmarking',
      menuTitle: 'Peer Benchmarking',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: CustomGroupPage,
    path: 'custom_group',
    name: 'CustomerCustomGroup',
    meta: {
      companyRoute: true,
      peersbenchmarkingRoute: true,
      title: 'Peer Benchmarking',
      menuTitle: 'Peer Benchmarking',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
];
