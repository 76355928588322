import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const drawTriangleCursorX = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle
) {
  const { x1, x2, y1, y2 } = this._options.vpOffset;
  const { size, offset } = this._options;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  for (let i = 0; i < plotData.length; i++) {
    const x = plotData[i].x + offset.x;
    const y = rect.y2 + offset.y;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + size.x, y + size.y);
    ctx.lineTo(x - size.x, y + size.y);
    ctx.closePath();
    ctx.fill();
  }
};
