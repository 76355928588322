import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { drawRoundRect } from '../../utils';
import { Tooltip, tooltipObject } from '../Tooltip';

const MARGIN_LEFT = 18;

export const drawLossSeverityLabel = function (this: Tooltip, t: tooltipObject) {
  const { ctx, coord, ind } = t;

  const titleText = 'NPV';
  const titleCoord = new Point(coord.x, coord.y - this.label.fontSize);
  const titleRect = this.label.getlabelRect(t.ctx, titleCoord, titleText);

  const valueText = (this.labels && this.labels[ind]) || '';
  const valueCoord = new Point(coord.x, coord.y + 4);
  const valueRect = this.label.getlabelRect(t.ctx, valueCoord, valueText);

  const DX = coord.x - valueRect.x1 + MARGIN_LEFT;
  const backRect = new Rectangle(valueRect.x1 + DX, titleRect.y1, valueRect.x2 + DX, valueRect.y2);

  const padding = [5, 8, 5, 5];
  const radius = 8;
  const borderWidth = this._options.lineWidth;

  ctx.fillStyle = this._options.lineColor;
  drawRoundRect(
    ctx,
    backRect.x1 - padding[0] - borderWidth + 0,
    backRect.y1 - padding[1] - borderWidth + 0,
    backRect.width + padding[0] + padding[2] + borderWidth + borderWidth,
    backRect.height + padding[1] + padding[3] + borderWidth + borderWidth,
    radius
  );
  ctx.fill();
  ctx.fillStyle = this._options.brushColor;
  drawRoundRect(
    ctx,
    backRect.x1 - padding[0] + 0,
    backRect.y1 - padding[1] + 0,
    backRect.width + padding[0] + padding[2],
    backRect.height + padding[1] + padding[3],
    radius
  );
  ctx.fill();

  const lableColor = this.label.color;

  this.label.draw(t.ctx, new Point(valueCoord.x + DX, valueCoord.y), valueText);
  this.label.color = '#808080';
  this.label.draw(t.ctx, new Point(titleCoord.x + DX, titleCoord.y), titleText);
  this.label.color = lableColor;
};
