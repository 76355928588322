import { store } from '../../index';
import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyReports } from '@/api-rosie/company/reports';
// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

@Module
class ReportsModule extends VuexModule {
  private ReportsList: any[] = [];

  get companyReports() {
    return this.ReportsList;
  }

  @Mutation
  public setCompanyReports(list: any[]) {
    this.ReportsList = list;
  }

  @Action
  public async queryReports(id: string) {
    return queryCompanyReports(id)
      .then((data) => {
        store.commit('setCompanyReports', data);
      })
      .catch((err) => {
        store.commit('setCompanyReports', []);
        console.error(`load Reports failed`);
      })
      .finally(() => {
        // store.commit('setSubsidiariesLoading', false);
      });
  }

  @Action
  public async fetchReportUrl(reportKey: string) {
    const cognitoToken = await window.CbhRosie.getAuthToken();
    return axios({
      url: `${apiGateWayUrl}reporturl`,
      method: 'post',
      headers: {
        Authorization: cognitoToken,
      },
      data: JSON.stringify({ reportKey: reportKey }),
    })
      .then(async (response) => {
        const url = response.data.url;
        window.open(url, '_blank');
      })
      .catch((error) => {
        console.error(`fetchReportUrl failed: `, error);
      });
  }
}
export default ReportsModule;
