import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyCyberAlerts } from '@/api-rosie/company/cyber-alerts';

/*
{
    "AttackPrevious": "Medium",
    "Date": "2023-02-24",
    "DataType": "CyberAlert#2023-02-24",
    "Grouping": "257b697cf425287eb848_MOC",
    "AttackCurrentColor": "3",
    "ChTimestamp": "2023-02-01",
    "ReconPrevious": "Low",
    "ExfilCurrentColor": "3",
    "ExfilPrevious": "Medium",
    "ExfilCurrent": "Medium",
    "ReconCurrent": "Medium",
    "LossProbCurrent": "1.0",
    "LossProbPrevious": "0.0",
    "ReconCurrentColor": "3",
    "AttackCurrent": "Medium"
}
*/

export interface CyberAlertItem {
  Date: Date;
  ChTimestamp: Date;
}

@Module
class CyberAlertsModule extends VuexModule {
  private cyberAlertsList: CyberAlertItem[] = [];

  get companyCyberAlerts() {
    return this.cyberAlertsList;
  }

  @Mutation
  public setCompanyCyberAlerts(list: any[]) {
    this.cyberAlertsList.splice(
      0,
      this.cyberAlertsList.length,
      ...list.map((el) => ({
        ...el,
        Date: new Date(el['Date']),
        ChTimestamp: new Date(el['ChTimestamp']),
      }))
    );
  }

  @Action
  public async queryCyberAlerts(id: string) {
    return queryCompanyCyberAlerts(id)
      .then((data) => {
        store.commit('setCompanyCyberAlerts', data);
      })
      .catch((err) => {
        store.commit('setCompanyCyberAlerts', []);
        console.error(`load cyberAlerts failed`);
      });
  }
}
export default CyberAlertsModule;
