
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop() private count!: number;
  @Prop() private loaded!: number;
  steps: string[] = [];

  created() {
    for (let i = 0; i < this.count; i++) {
      if (i < this.loaded) {
        this.steps.push('loaded');
      } else {
        this.steps.push('');
      }
    }
  }

  @Watch('loaded')
  onLoadedChanged(val: string, oldVal: string) {
    this.steps = this.steps.map((val, ind) => {
      return ind < this.loaded ? 'loaded' : '';
    });
  }

  get stepWidth(): string {
    return 100 / this.count + '%';
  }
}
