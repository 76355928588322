
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ChartCell from '../chart-cell/ChartCell.vue';

@Component({
  components: {
    ChartCell,
  },
})
export default class SectorPeersTableCharts extends Vue {
  private sqrColor = {
    0: '#D93F3059',
    1: '#ECBA4459',
    2: '#3D894C80',
    NaN: '#6464641C',
  };
  private sortedField = 'companyName';
  private sortedDirection: 'up' | 'down' = 'down';
  private charts: any[] = [[], [], []];

  addChartToCol(colNumber: number, e: any) {
    const col = this.charts[colNumber];
    col.forEach((chart: any) => {
      e.bindOtherChartTooltips(chart);
      chart.bindOtherChartTooltips(e);
    });
    col.push(e);
  }

  get companyName() {
    return this.$store.state.CompanyModule.info.companyName;
  }

  public changeSort(field: string) {
    if (this.sortedField === field) {
      this.sortedDirection = this.sortedDirection === 'up' ? 'down' : 'up';
    } else {
      this.sortedField = field;
      this.sortedDirection = 'up';
    }
  }

  get sortedTable() {
    const initTable = [...this.tableData];
    initTable.sort((a: any, b: any) => {
      if (a[this.sortedField] > b[this.sortedField]) {
        return this.sortedDirection === 'up' ? 1 : -1;
      }
      if (a[this.sortedField] < b[this.sortedField]) {
        return this.sortedDirection === 'down' ? 1 : -1;
      }
      return 0;
    });
    return initTable;
  }

  get tableData(): any[] {
    return this.$store.getters.companySectorPeersChartsData;
  }
}
