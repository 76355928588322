
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LogoutBtn extends Vue {
  logout() {
    this.$store.commit('setLoadedOnce', false);
    window.CbhRosie.signOut().then(() => {
      this.$store.commit('setUserName', null);
      this.$store.commit('setDecodedToken', null);
      this.$router.push('/Login/userlogin');
      this.$store.dispatch('cleanUpCompaniesInitialData');
    });
  }
}
