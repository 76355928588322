import { Series } from '../Series';
import { Rectangle } from '../Rectangle';
import { Point } from '../Point';
import { Transformer } from '../Transformer';
import { SeriesBase } from './SeriesBase';

export interface MetaItem {
  color?: string;
  size?: number;
  angle?: number;
}

export class SeriesXY extends SeriesBase implements Series {
  plotLabels: string[] = [];
  plotMetaArr: Array<MetaItem> = [];
  metaArr?: Array<MetaItem> = [];
  labels?: string[];
  callbacks?: Array<(params: any) => void>;
  plotCallbacks: Array<(params: any) => void> = [];

  constructor(
    id: string,
    container: HTMLElement,
    seriesData: number[][],
    labels?: string[],
    metaArr?: Array<MetaItem>
  ) {
    super(id, container, seriesData);
    this.canvas.canvas.style.zIndex = '3';
    if (labels) this.labels = labels;
    if (metaArr) this.metaArr = metaArr;
  }

  replaceSeriesData(
    seriesData_to: number[][],
    animate: boolean,
    labels?: string[],
    metaArr?: Array<MetaItem>
  ) {
    if (labels) this.labels = labels;
    if (metaArr) this.metaArr = metaArr;
    this.seriesData = this.getInitialData(seriesData_to);
    this.extremes = this.findExtremes();
    if (animate) this.onSeriesDataChanged.dispatch(this);
  }

  getInitialData(initialData: number[][]): number[][] {
    const resultData: number[][] = [];

    const x: number[] = [];
    const y: number[] = [];

    initialData[0].forEach((element, index) => {
      x.push(element);
      y.push(initialData[1][index]);
    });

    resultData.push(x);
    resultData.push(y);

    return resultData;
  }

  generatePlotData(axisRect: Rectangle, vp: Rectangle): Point[][] {
    const seriesData = this.getDataRange('ind', axisRect.x1, axisRect.x2);
    // const seriesData = this.seriesData.slice();

    const plotData: Point[][] = [];

    const transformer = new Transformer();

    const plotDataRow: Point[] = [];

    const dataRowX = seriesData[0];
    const dataRowY = seriesData[1];

    dataRowX.forEach((element, ind) => {
      const seriesPoint = new Point(dataRowX[ind], dataRowY[ind]);
      const plotPoint = transformer.getVeiwportCoord(axisRect, vp, seriesPoint);
      plotDataRow.push(new Point(plotPoint.x, plotPoint.y));
    });

    plotData.push(plotDataRow);

    return plotData;
  }

  getDataRange(type: string, min: number, max: number): number[][] {
    const data: number[][] = [];
    this.plotLabels.splice(0, this.plotLabels.length);
    this.plotMetaArr.splice(0, this.plotMetaArr.length);
    if (this.plotCallbacks) {
      this.plotCallbacks.splice(0, this.plotCallbacks.length);
    }

    const x: number[] = [];
    const y: number[] = [];

    const dataRowX = this.seriesData[0].slice();
    const dataRowY = this.seriesData[1].slice();

    dataRowX.forEach((el, i) => {
      if (el >= min && el <= max) {
        x.push(dataRowX[i]);
        y.push(dataRowY[i]);
        if (this.labels) this.plotLabels.push(this.labels[i]);
        if (this.metaArr) this.plotMetaArr.push(this.metaArr[i]);
        if (this.callbacks) this.plotCallbacks.push(this.callbacks[i]);
      }
    });

    data.push(x);
    data.push(y);

    return data;
  }

  setCallbacks(callbacks: SeriesXY['callbacks']) {
    this.callbacks = callbacks;
    return this;
  }
}
