import { Canvas } from './Canvas';
import { Data } from './Data';
import { Plot } from './plot/Plot';
import { Axis } from './Axis';
import { Rectangle } from './Rectangle';
import { Point } from './Point';
import { Series } from './Series';
import { BackGround } from './BackGround';
import { Signal } from 'signals';
import { SeriesBase } from './series/SeriesBase';
import { SeriesXY } from './series/SeriesXY';

// interfaces
import { Tooltip, tooltipObject } from './tooltip/Tooltip';
import { pushOverlapCoordsY } from './utils';

export class Chart {
  container: HTMLElement;
  canvasTT: Canvas;
  data: Data;
  plots: Plot[];
  xAxis: Axis;
  yAxis: Axis;
  hasBorder: boolean = false;
  clipSeriesCanvas: boolean = false;
  background?: BackGround;

  tooltipsDataIndexUpdated: Signal;

  constructor(container: HTMLElement, xMinMax: number[], yMinMax: number[]) {
    //signals
    this.tooltipsDataIndexUpdated = new Signal();
    this.container = container;
    this.canvasTT = new Canvas(container);
    this.canvasTT.turnOnListenres();
    this.canvasTT.canvas.style.zIndex = '4';

    this.data = new Data();
    this.plots = [];

    this.xAxis = new Axis(xMinMax, 'horizontal', container);
    this.yAxis = new Axis(yMinMax, 'vertical', container);

    //bind
    this.tooltipsDraw = this.tooltipsDraw.bind(this);
    this.seriesReDraw = this.seriesReDraw.bind(this);
    this.seriesReDraw_Static = this.seriesReDraw_Static.bind(this);

    //call methods
    this.bindChildSignals();
  }

  refresh() {
    this.xAxis.refresh();
    this.yAxis.refresh();
    this.tooltipsDraw(undefined, true);
  }

  switchResolution() {
    this.xAxis.canvas.squareRes = true;
    this.yAxis.canvas.squareRes = true;
    this.canvasTT.squareRes = true;

    if (this.background) this.background.canvas.squareRes = true;

    this.data.seriesStorage.forEach((series, ind) => {
      series.canvas.squareRes = true;
    });
  }

  bindChildSignals() {
    this.xAxis.onRefreshed.add(() => {
      this.seriesUpdatePlotData();
    });

    this.yAxis.onRefreshed.add(() => {
      this.seriesUpdatePlotData();
    });

    this.xAxis.onMinMaxSetted.add((hasPlotAnimation) => {
      if (hasPlotAnimation) this.seriesUpdatePlotData();
      this.tooltipsDraw(undefined, true);
    });

    this.yAxis.onMinMaxSetted.add((hasPlotAnimation) => {
      if (hasPlotAnimation) this.seriesUpdatePlotData();
      this.tooltipsDraw(undefined, true);
    });

    this.canvasTT.mouseMoved.add(this.tooltipsDraw);
    this.canvasTT.mouseOuted.add(() => {
      this.tooltipsDraw(undefined, true);
    });
    this.canvasTT.touchEnded.add(() => {
      this.tooltipsDraw(undefined, true);
    });
    this.canvasTT.resized.add(() => {
      this.tooltipsDraw(undefined, true);
    });
  }

  bindOtherChartTooltips(otherChart: Chart) {
    this.canvasTT.mouseOuted.add(() => {
      const coords = this.canvasTT.mouseCoords;
      otherChart.tooltipsDraw(coords, true);
    });
    this.canvasTT.mouseMoved.add(() => {
      const coords = this.canvasTT.mouseCoords;
      otherChart.tooltipsDraw(coords);
    });
  }

  get axisRect(): Rectangle {
    return new Rectangle(this.xAxis.min, this.yAxis.min, this.xAxis.max, this.yAxis.max);
  }

  seriesUpdatePlotData(series?: Series) {
    if (series) {
      series.updatePlotData(this.axisRect, series.canvas.viewport);
      return;
    }
    this.data.seriesStorage.forEach((series) => {
      series.updatePlotData(this.axisRect, series.canvas.viewport);
    });
  }

  seriesReDraw(series: Series) {
    const canvas = series.canvas;
    canvas.clear();
    if (this.clipSeriesCanvas || series.clipRadius) canvas.clipCanvas(series.clipRadius);

    series.plots.forEach((plotId) => {
      const plot: Plot | null = this.findPlotById(plotId);
      if (plot && canvas.ctx) {
        plot.drawPlot(
          true,
          canvas.ctx,
          series.plotDataArr,
          canvas.viewport,
          series.plotLabels || [],
          series.plotMetaArr || []
        );
      }
    });
  }

  seriesReDraw_Static(series: Series) {
    const canvas = series.canvas;
    canvas.clear();
    if (this.clipSeriesCanvas || series.clipRadius) canvas.clipCanvas(series.clipRadius);

    series.plots.forEach((plotId) => {
      const plot: Plot | null = this.findPlotById(plotId);
      if (plot && canvas.ctx) {
        plot.drawPlot(
          false,
          canvas.ctx,
          series.plotDataArr,
          canvas.viewport,
          series.plotLabels || [],
          series.plotMetaArr || []
        );
      }
    });
  }

  setCanvasPaddings(...paddings: number[]) {
    this.canvasTT.setPaddings(...paddings);
    this.xAxis.canvas.setPaddings(...paddings);
    this.yAxis.canvas.setPaddings(...paddings);

    if (this.background) this.background.canvas.setPaddings(...paddings);

    this.data.seriesStorage.forEach((series, ind) => {
      series.canvas.setPaddings(...paddings);
    });
  }

  addBackGround(type: string) {
    this.background = new BackGround(type, this.container);

    this.xAxis.ticks.onCoordsChanged.add(() => {
      this.backgroundDraw();
    });

    this.yAxis.ticks.onCoordsChanged.add(() => {
      this.backgroundDraw();
    });

    this.background.canvas.resized.add(() => {
      this.backgroundDraw();
    });

    this.backgroundDraw();
  }

  backgroundDraw() {
    if (this.background) this.background.draw(this.xAxis.ticks.coords, this.yAxis.ticks.coords);
  }

  addPlot(id: string, type: Plot['type'], ...options: any) {
    const plot = new Plot(id, type, ...options);
    this.plots.push(plot);
    return plot;
  }

  findPlotById(id: string): Plot | null {
    const plots: Plot[] = this.plots.filter((plot) => {
      return plot.id === id;
    });
    if (plots.length !== 0) return plots[0];
    return null;
  }

  addSeries(id: string, seriesData: number[][], labels?: string[], metaArr?: SeriesXY['metaArr']) {
    const newSeries: Series = new SeriesXY(id, this.container, seriesData, labels, metaArr);

    newSeries.canvas.setPaddings(
      this.canvasTT.top,
      this.canvasTT.right,
      this.canvasTT.bottom,
      this.canvasTT.left
    );
    newSeries.onPlotDataChanged.add(this.seriesReDraw);
    newSeries.onPlotDataChanged_Static.add(this.seriesReDraw_Static);
    newSeries.onSeriesDataChanged.add((series) => {
      series.updatePlotData(this.axisRect, series.canvas.viewport);
    });
    newSeries.canvas.resized.add(() => {
      newSeries.updatePlotData(this.axisRect, newSeries.canvas.viewport, true);
    });

    this.data.seriesStorage.push(newSeries);

    return newSeries;
  }

  addSeriesRow(id: string, seriesData: number[][]) {
    const newSeries: Series = new SeriesBase(id, this.container, seriesData);
    newSeries.canvas.setPaddings(
      this.canvasTT.top,
      this.canvasTT.right,
      this.canvasTT.bottom,
      this.canvasTT.left
    );

    newSeries.onPlotDataChanged.add(this.seriesReDraw);
    newSeries.onPlotDataChanged_Static.add(this.seriesReDraw_Static);

    newSeries.onSeriesDataChanged.add((series) => {
      series.updatePlotData(this.axisRect, series.canvas.viewport);
    });
    newSeries.canvas.resized.add(() => {
      newSeries.updatePlotData(this.axisRect, newSeries.canvas.viewport, true);
    });

    this.data.seriesStorage.push(newSeries);

    return newSeries;
  }

  switchDataAnimation(hasAnimation: boolean, duration?: number) {
    this.data.seriesStorage.forEach((series) => {
      series.hasAnimation = hasAnimation;
      if (duration) series.animationDuration = duration;
    });
  }

  tooltipsDraw(coords?: Point, drawLast?: boolean) {
    const drawQueue: { tooltip: Tooltip; tt: tooltipObject }[] = [];
    const { mouseCoords, viewport, ctx } = this.canvasTT;
    const mouseXY: Point = coords || mouseCoords;
    const seriesX = this.xAxis.min + (mouseXY.x * this.xAxis.length) / viewport.width;
    const seriesY = this.yAxis.max - (mouseXY.y * this.yAxis.length) / viewport.height;
    const sriesP = new Point(seriesX, seriesY);

    const overlapMap: Map<Tooltip['type'], { tooltip: Tooltip; tt: tooltipObject }[]> = new Map();

    const tt: tooltipObject = {
      ctx,
      vp: viewport,
      coord: new Point(0, 0),
      data: new Point(0, 0),
      ind: 0,
      step: 0,
    };

    this.data.seriesStorage.forEach((series) => {
      const [pointData, tt_ind] = series.getClosestDataPointX(sriesP);
      const tooltipCoordX = series.getClosestPlotPointX(
        new Point(mouseXY.x + this.canvasTT.left, mouseXY.y + this.canvasTT.top)
      );

      /*
      const [pointDataXY, tt_ind_XY] = series.getClosestDataPointXY(sriesP);
      const tooltipCoordXY = series.getClosestPlotPointXY(
        new Point(mouseXY.x + this.canvasTT.left, mouseXY.y + this.canvasTT.top)
      );
      */

      // common options
      tt.ind = tt_ind;
      tt.coord = new Point(tooltipCoordX.x, tooltipCoordX.y);
      tt.data = pointData;

      series.plots.forEach((plotId) => {
        const plot: Plot | null = this.findPlotById(plotId);
        if (plot) {
          plot.tooltips.forEach((tooltip) => {
            this.tooltipsDataIndexUpdated.dispatch({ tooltip, tt });

            switch (tooltip.type) {
              case 'loss_severity_label':
                const field = overlapMap.get(tooltip.type);
                if (field) {
                  field.push({ tooltip, tt: { ...tt } });
                } else {
                  overlapMap.set(tooltip.type, [{ tooltip, tt: { ...tt } }]);
                }
                break;

              case 'circle_series':
              case 'simple_label':
                drawQueue.push({ tooltip, tt: { ...tt } });
                break;

              case 'bar_chart_highlighter':
              case 'bar_chart_fullheight':
                tt.step = plot._options.step;
                drawQueue.push({ tooltip, tt: { ...tt } });
                break;

              case 'policy_hovered_labels':
              case 'policy_hovered_labels_2leg':
                tt.coord = new Point(mouseXY.x + this.canvasTT.left, mouseXY.y + this.canvasTT.top);
                tt.plotData = series.plotDataArr;
                tt.plotLabels = series.plotLabels || [];
                tt.canvas = this.canvasTT;
                tt.plotCallbacks = series.callbacks;
                drawQueue.push({ tooltip, tt: { ...tt } });
                break;

              default:
                tt.step = this.xAxis.ticks.plotStepX;
                drawQueue.push({ tooltip, tt: { ...tt } });
                break;
            }
          });
        }
      });
    });

    overlapMap.forEach((arr) => {
      const newCoords = pushOverlapCoordsY(
        [...arr.map(({ tt }) => new Point(tt.coord.x, tt.coord.y))],
        50,
        50
      );
      arr.forEach(({ tooltip, tt }, index) => {
        drawQueue.push({ tooltip, tt: { ...tt, coord: newCoords[index] } });
      });
    });

    // clear canvas
    this.canvasTT.clear();
    // draw all tts
    drawQueue.forEach(({ tooltip, tt }) => tooltip.drawTooltip(tt));
  }
}
