
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CyDataWithAlerts } from '@/store/modules/company-tabs/cydata';

@Component
export default class Alert extends Vue {
  @Prop() data!: CyDataWithAlerts;

  get routerCompanyId(): string {
    return this.$route.params.companyId;
  }
}
