// Company Pages

const CustomerOverviewPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/Overview/index.vue'
  );
const TimelinePage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/Timeline/TimelinePage.vue'
  );
const CustomerCyFiPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/CyFI/index.vue'
  );
const ThreadDataPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/ThreatData/index.vue'
  );
const FullReportPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/FullReport/index.vue'
  );
const SubsidiariesPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/Subsidiaries/index.vue'
  );
const PeerBenchmarkingPage = () =>
  import(
    /* webpackChunkName: "customerAdvisory" */ '@/pages/customer-pages/Company/PeerBenchmarkingPage/index.vue'
  );

// children
import { peerbenchmarkingRoutes } from './peerbenchmarking-routes';
import { getCustomerCompanyPageTitle } from './utils';
import { RouteConfig } from 'vue-router';

// children
export const companyRoutes: RouteConfig[] = [
  {
    component: CustomerOverviewPage,
    path: 'overview',
    name: 'CustomerOverviewPage',
    meta: {
      companyRoute: true,
      title: 'Overview',
      menuTitle: 'Overview',
      faq: 'triggers',
      getTitle: getCustomerCompanyPageTitle,
      // faqLink: 'Learn more about Rating',
    },
  },

  {
    component: TimelinePage,
    path: 'overview-timeline',
    name: 'CustomerOverviewTimelinePage',
    meta: {
      companyRoute: true,
      title: 'Overview - Timeline',
      menuTitle: 'Overview - Timeline',
      getTitle: getCustomerCompanyPageTitle,
      tabVisibility: false,
    },
  },

  {
    component: CustomerCyFiPage,
    path: 'cy_fi_analysis',
    name: 'CustomerCyFiPage',
    meta: {
      companyRoute: true,
      title: 'CyFi™ Analysis',
      menuTitle: 'CyFi™ Analysis',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: ThreadDataPage,
    path: 'malicious_activity',
    name: 'ThreadDataPage',
    meta: {
      companyRoute: true,
      title: 'Malicious Activity',
      menuTitle: 'Malicious Activity',
      faq: 'malicious_activity',
      faqLink: 'Learn more about Malicious Activity',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  /*
  {
    component: AlertsPage,
    path: 'alerts',
    name: 'AlertsPage',
    meta: { companyRoute: true, title: 'Alerts' },
  },
  */

  {
    component: PeerBenchmarkingPage,
    path: 'peer_benchmarking',
    name: 'CustomerPeerBenchmarking',
    redirect: { name: 'CustomerSectorPeers' },
    meta: {
      companyRoute: true,
      title: 'Peer Benchmarking',
      menuTitle: 'Peer Benchmarking',
      getTitle: getCustomerCompanyPageTitle,
    },
    children: [...peerbenchmarkingRoutes],
  },

  {
    component: FullReportPage,
    path: 'full_report',
    name: 'CustomerFullReportPage',
    meta: {
      companyRoute: true,
      title: 'Full Report',
      menuTitle: 'Full Report',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: SubsidiariesPage,
    path: 'subsidiaries',
    name: 'SubsidiariesPage',
    meta: {
      companyRoute: true,
      title: 'Subsidiaries',
      menuTitle: 'Subsidiaries',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
];

export const companyRoutes_policy: RouteConfig[] = [
  {
    component: CustomerOverviewPage,
    path: 'overview',
    name: 'CustomerOverviewPage_policy',
    meta: {
      companyRoute: true,
      title: 'Overview',
      menuTitle: 'Overview',
      faq: 'triggers',
      getTitle: getCustomerCompanyPageTitle,
      // faqLink: 'Learn more about Rating',
    },
  },

  {
    component: TimelinePage,
    path: 'overview-timeline',
    name: 'CustomerOverviewTimelinePage',
    meta: {
      companyRoute: true,
      title: 'Overview - Timeline',
      menuTitle: 'Overview - Timeline',
      getTitle: getCustomerCompanyPageTitle,
      tabVisibility: false,
    },
  },

  {
    component: ThreadDataPage,
    path: 'malicious_activity',
    name: 'ThreadDataPage_policy',
    meta: {
      companyRoute: true,
      title: 'Malicious Activity',
      menuTitle: 'Malicious Activity',
      faq: 'malicious_activity',
      faqLink: 'Learn more about Malicious Activity',
      getTitle: getCustomerCompanyPageTitle,
    },
  },

  {
    component: SubsidiariesPage,
    path: 'subsidiaries',
    name: 'SubsidiariesPage_policy',
    meta: {
      companyRoute: true,
      title: 'Subsidiaries',
      menuTitle: 'Subsidiaries',
      getTitle: getCustomerCompanyPageTitle,
    },
  },
];
