import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { drawRoundRect } from '../../utils';

export const drawSeverityMidLabel = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  labels: string[],
  vp: Rectangle
) {
  const { vpOffset } = this._options;
  const { x1, x2, y1, y2 } = vpOffset;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);

  if (plotData.length < 2) {
    console.warn('drawSeverityMidLabel: plotData length < 2');
    return;
  }

  const valueText = labels[0] || '';
  if (!valueText) return;

  const p1 = plotData[0];
  const p2 = plotData[plotData.length - 1];

  const midX = p1.x + Math.round((p2.x - p1.x) * 0.5);
  const coord = new Point(midX, rect.y1);

  const titleText = 'WGH.NPV';
  const titleCoord = new Point(coord.x, coord.y + 14);
  const titleRect = this.label.getlabelRect(ctx, titleCoord, titleText);

  const valueCoord = new Point(titleCoord.x, titleCoord.y + this.label.fontSize);
  const valueRect = this.label.getlabelRect(ctx, valueCoord, valueText);

  const padding = [16, 7, 16, 3];
  const radius = 8;
  const borderWidth = this._options.lineWidth;

  const backRect = new Rectangle(valueRect.x1, titleRect.y1, valueRect.x2, valueRect.y2);
  ctx.fillStyle = this._options.lineColor;
  drawRoundRect(
    ctx,
    backRect.x1 - padding[0] - borderWidth + 0,
    backRect.y1 - padding[1] - borderWidth + 0,
    backRect.width + padding[0] + padding[2] + borderWidth + borderWidth,
    backRect.height + padding[1] + padding[3] + borderWidth + borderWidth,
    radius
  );
  ctx.fill();
  ctx.fillStyle = this._options.brushColor;
  drawRoundRect(
    ctx,
    backRect.x1 - padding[0] + 0,
    backRect.y1 - padding[1] + 0,
    backRect.width + padding[0] + padding[2],
    backRect.height + padding[1] + padding[3],
    radius
  );
  ctx.fill();

  const lableColor = this.label.color;
  const fontSize = this.label.fontSize;
  this.label.draw(ctx, new Point(valueCoord.x, valueCoord.y), valueText);
  this.label.color = '#808080';
  this.label.fontSize = 14;
  this.label.draw(ctx, new Point(titleCoord.x, titleCoord.y), titleText);
  this.label.color = lableColor;
  this.label.fontSize = fontSize;
};
