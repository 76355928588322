import { Plot } from '../Plot';
import { Point } from '../../Point';
import { MetaItem } from '../../series/SeriesXY';

export const drawPolicyGradientChart = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  plotMetaArr: MetaItem[]
) {
  if (plotData.length < 2) return;
  const { offset } = this._options;
  const start = plotData[0];
  const end = plotData[plotData.length - 1];
  let gradient;
  const getGradientValueByInd = (coord: Point): number => {
    return coord.x / end.x;
  };
  if (!plotMetaArr.some((el) => !el)) {
    gradient = ctx.createLinearGradient(start.x, start.y, end.x, end.y);
    gradient.addColorStop(0, plotMetaArr[0].color || '#ffffff');
    gradient.addColorStop(1, plotMetaArr[plotMetaArr.length - 1].color || '#ffffff');
  }
  ctx.beginPath();
  for (let i = 0; i < plotData.length; i++) {
    const p = plotData[i];
    ctx.lineTo(p.x + offset.x, p.y + offset.y);
    if (gradient) {
      gradient.addColorStop(getGradientValueByInd(p), plotMetaArr[i].color || '#ffffff');
    }
  }

  if (gradient) ctx.strokeStyle = gradient;
  ctx.globalAlpha = this._options.opacity;
  ctx.stroke();
};
