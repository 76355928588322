import { Point } from '../../Point';
import { Tooltip, tooltipObject } from '../Tooltip';

export const drawPolicyHoveredLabels2leg = function (this: Tooltip, t: tooltipObject) {
  const { ctx, plotData, plotLabels, coord, canvas, plotCallbacks, vp } = t;

  if (!(plotData && plotLabels)) return;
  if (plotData.length !== 2) return;

  if (this._options.textLine) {
    const { before, length, after } = this._options.textLine;
    const startP = plotData[0];
    const endP = plotData[1];
    const labelText = plotLabels[0];
    const labelCoord = new Point(
      startP.x + Math.round((endP.x - startP.x) / 2),
      startP.y - before - length - after
    );

    ctx.strokeStyle = this._options.lineColor;
    ctx.lineWidth = this._options.lineWidth;
    ctx.fillStyle = this._options.brushColor;

    const midY = startP.y - before - Math.round((startP.y - before - labelCoord.y) / 2);
    const quotY = midY + Math.round((startP.y - before - labelCoord.y) / 4);
    ctx.beginPath();
    ctx.moveTo(labelCoord.x, labelCoord.y);
    ctx.lineTo(labelCoord.x, midY);
    ctx.bezierCurveTo(startP.x, quotY, startP.x, quotY, startP.x, startP.y - before);
    ctx.moveTo(labelCoord.x, midY);
    ctx.bezierCurveTo(endP.x, quotY, endP.x, quotY, endP.x, endP.y - before);
    ctx.stroke();

    this.label.draw(ctx, labelCoord, labelText, coord);

    if (canvas) {
      if (this.onClickBinding) this.onClickBinding.detach();
      this.onClickBinding = canvas.onCanvasClicked.add(() => {
        if (this.label.isCoordInClickArea(ctx, labelCoord, labelText, coord)) {
          if (plotCallbacks) {
            const labelRect = this.label.getlabelRect(ctx, labelCoord, labelText);
            plotCallbacks[0]({
              left: labelCoord.x,
              bottom: vp.height - labelRect.y1,
            });
          }
        }
      });
    }
  }
};
