import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { MetaItem } from '../../series/SeriesXY';

export const drawPolicyBackgroundGradient = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle,
  plotMetaArr: MetaItem[]
) {
  if (plotData.length < 2) return;
  const { mainSize, offset } = this._options;
  const start = plotData[0];
  const end = plotData[plotData.length - 1];
  let gradient;
  const getGradientValueByInd = (coord: Point): number => {
    return coord.x / (end.x + mainSize);
  };
  if (!plotMetaArr.some((el) => !el)) {
    gradient = ctx.createLinearGradient(start.x, start.y, end.x, end.y);
    gradient.addColorStop(getGradientValueByInd(start), plotMetaArr[0].color || '#ffffff');
    gradient.addColorStop(
      getGradientValueByInd(end),
      plotMetaArr[plotData.length - 1].color || '#ffffff'
    );
  }

  ctx.beginPath();
  ctx.arc(start.x + offset.x, start.y + offset.y, mainSize, -0.5 * Math.PI, 0.5 * Math.PI, true);
  ctx.moveTo(start.x + offset.x, start.y + offset.y + mainSize);

  for (let i = 1; i < plotData.length - 1; i++) {
    const p = plotData[i];
    ctx.lineTo(p.x + offset.x, p.y + offset.y + mainSize);
    if (gradient) {
      gradient.addColorStop(getGradientValueByInd(p), plotMetaArr[i].color || '#ffffff');
    }
  }

  ctx.lineTo(end.x + offset.x, end.y + offset.y + mainSize);
  ctx.arc(end.x + offset.x, end.y + offset.y, mainSize, 0.5 * Math.PI, -0.5 * Math.PI, true);
  ctx.lineTo(start.x + offset.x, start.y + offset.y - mainSize);

  ctx.closePath();

  if (gradient) ctx.fillStyle = gradient;
  ctx.globalAlpha = this._options.opacity;
  ctx.fill();
};
