import { store } from '@/store';

const AdminRootPage = () => import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/index.vue');
const PreviouslyViewed = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/PreviouslyViewed/index.vue');
const CompanyPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/Company.vue');

import { companyRoutes } from './company-routes';

const routes = [
  {
    component: AdminRootPage,
    path: '/admin',
    name: 'AdminRootPage',
    redirect: '/admin/PreviouslyViewed',
    meta: {
      adminRoute: true,
    },
    // @ts-ignore
    beforeEnter: (to, from, next) => {
      console.warn('AdminRootPage guard');
      if (!store.getters.isRestrictedUser) {
        next();
      } else {
        next({ path: store.getters.userRedirectPath });
      }
    },
    children: [
      {
        component: PreviouslyViewed,
        path: '/admin/PreviouslyViewed',
        name: 'PreviouslyViewed',
        meta: {
          adminRoute: true,
          getTitle: () => 'Admin - Previously viewed',
        },
      },
      {
        component: CompanyPage,
        path: '/admin/Company/:companyId',
        name: 'CompanyPage',
        redirect: { name: 'CompanyOverview' },
        meta: {
          adminRoute: true,
        },
        children: [...companyRoutes],
      },
    ],
  },
];

export default routes;
