import * as ddb from './ddbUtils-config';

import { CyFiStatsRow } from '@/types';

import { _queryJSON, _toNumber, parseCyFiStatsRowMeta } from './utils';

// const RANGECOND_list = 'CyFiStats#20'; // current CyFi stats
const RANGECOND_cybervalues = 'CyberValues#'; // all CyberValues
const RANGECOND_raep = 'RAEProps#2020';

// checked 08.08.2020
const RANGECOND_current = 'CyFiStats#current#'; // ✅ should cover the left panel of the overview page
const RANGECOND_companyScaledCyber = 'CompanyScaledCyber#2'; // CompanyScaledCyber#2 ✅ datatype CompanyScaledCyber#<timestamp> will do the right side plot on the overview page
const RANGECOND_cyfiList = 'CyFiStats#'; // ✅ the CyFi Scores page

// ✅ should cover the left panel of the overview page
export const queryExecutiveSummary = async (companyID: string) => {
  const q = _queryJSON(companyID, 'ExecutiveSummary#');
  return ddb.executeQuery(q).then((data: any) => {
    let items: any = [];
    items = [...items, ...data.Items];
    return items;
  });
};

// right side plot on the overview page,
// ✅ working 🆘 data values are higher than 0..10
export const queryCompanyScaledCyber = async (companyID: string): Promise<any[]> => {
  const q = _queryJSON(companyID, RANGECOND_companyScaledCyber);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any) => {
    return {
      ChTimestamp: el.ChTimestamp,
      CompanyID: el.CompanyID,
      RawValue: _toNumber(el.RawValue),
      Clients: _toNumber(el.Clients),
      Exits: _toNumber(el.Exits),
      Forums: _toNumber(el.Forums),
      Grouping: el.Grouping,
      Markets: _toNumber(el.Markets),
      Osint: _toNumber(el.Osint),
      Pastes: _toNumber(el.Pastes),
    };
  });
  return mappedResult;
};

// ✅ should cover the left panel of the overview page
export const queryCyFiStatsCurrent = async (companyID: string): Promise<CyFiStatsRow[]> => {
  const q = _queryJSON(companyID, RANGECOND_current);
  return ddb.executeQuery(q).then((data: any) => {
    let items: any = [];
    items = [...items, ...data.Items];
    const cyfiStatsList = items.map((row: any) => {
      const newRow = parseCyFiStatsRowMeta(row, q);
      return newRow;
    });
    if (cyfiStatsList.length !== 0) return cyfiStatsList;
    return [];
  });
};

// ✅ the CyFi Scores page
export const queryCyFiStatsList = async (companyID: string): Promise<CyFiStatsRow[]> => {
  const q = _queryJSON(companyID, RANGECOND_cyfiList);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const cyfiStatsList = items.map((row: any) => {
    // const newRow: CyFiStatsRow = parseCyFiStatsRow(row);
    const newRow = parseCyFiStatsRowMeta(row, q);
    return newRow;
  });
  return cyfiStatsList;
};

export const queryCyberValues = async (companyID: string): Promise<any[]> => {
  const q = _queryJSON(companyID, RANGECOND_raep);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any) => {
    return {
      ChTimestamp: el.ChTimestamp,
      Clients: _toNumber(el.Clients),
      Exits: _toNumber(el.Exits),
      Forums: _toNumber(el.Forums),
      Grouping: _toNumber(el.Grouping),
      Markets: _toNumber(el.Markets),
      Osint: _toNumber(el.Osint),
      Pastes: _toNumber(el.Pastes),
    };
  });
  return mappedResult;
};
