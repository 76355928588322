import Vue from 'vue';
import Vuex from 'vuex';

import Session from './modules/session';
import Companies from './modules/companies';
import HelpersModule from './modules/helpers';
import CompanyModule from './modules/company';
import CustomGroup from './modules/company-tabs/custom-group';
import ChartsData from './modules/company-tabs/overview-charts';

import SubsidiariesModule from './modules/company-tabs/subsidiaries';
import CyDataModule from './modules/company-tabs/cydata';
import FiDataModule from './modules/company-tabs/fidata';
import CyFiScoresModule from './modules/company-tabs/cyfiscores';
import { SectorPeersModule } from './modules/company-tabs/sector-peers';

import ExecutiveSummaryModule from './modules/company-tabs/executiveSummary';
import RaeAlertModule from './modules/company-tabs/rae-alert';

import InitialLoadings from './initial-loadings';
import ScaledRaeDataModule from './modules/company-tabs/scaled-rae-data';

import EodHistoricalModule from './modules/company-tabs/eod-historical';
import CorporateEventsModdule from './modules/company-tabs/corporate-events';

import CyberAlertsModule from './modules/company-tabs/cyber-alerts';
import MarketChangeModule from './modules/company-tabs/market-change';

import ReportsModule from './modules/company-tabs/reports';
import PolicyTimelineModule from './modules/company-tabs/policy-timiline';

import ClaimProcessModule from './modules/company-tabs/claim-process';
import CompanyPeerCompsModule from './modules/company-tabs/company-peer-comps';

import MicroTrendModule from './modules/company-tabs/micro-trend';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    Session,
    InitialLoadings,
    Companies,
    CompanyModule,
    CustomGroup,
    ChartsData,
    SubsidiariesModule,
    CyDataModule,
    FiDataModule,
    CyFiScoresModule,
    SectorPeersModule,
    HelpersModule,
    ExecutiveSummaryModule,
    RaeAlertModule,
    ScaledRaeDataModule,
    EodHistoricalModule,
    CorporateEventsModdule,
    CyberAlertsModule,
    MarketChangeModule,
    ReportsModule,
    PolicyTimelineModule,
    ClaimProcessModule,
    CompanyPeerCompsModule,
    MicroTrendModule,
  },
});
