import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const line = function (
  this: Plot,
  orientation: 'horizontal' | 'vertical',
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle
) {
  const { x1, x2, y1, y2 } = this._options.vpOffset;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  ctx.setLineDash(this._options.lineDash);
  for (let i = 0; i < plotData.length; i++) {
    ctx.beginPath();
    switch (orientation) {
      case 'horizontal':
        ctx.moveTo(rect.x1, plotData[i].y - this._options.offset.y);
        ctx.lineTo(rect.x2, plotData[i].y - this._options.offset.y);
        break;
      case 'vertical':
        ctx.moveTo(plotData[i].x + this._options.offset.x, rect.y1);
        ctx.lineTo(plotData[i].x + this._options.offset.x, rect.y2);
        break;
    }
    ctx.stroke();
  }
};
