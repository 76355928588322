
import { Component, Vue, Prop } from 'vue-property-decorator';
import SqrValue from '@/components/SqrValueMeta.vue';

@Component({
  components: {
    SqrValue,
  },
})
export default class SectorPeersTable extends Vue {
  @Prop() tableData!: any[];
  @Prop() hasDeleteBtn!: boolean;
  companyName = this.$store.state.CompanyModule.info.companyName;

  /* opacity
  private colorOptionsBasic = [
    { color: '#6464641C', condition: (val: number) => isNaN(val) },
    { color: '#D93F3059', condition: (val: number) => val >= 0 && val <= 2 },
    { color: '#ECBA4459', condition: (val: number) => val > 2 && val < 4 },
    { color: '#3D894C80', condition: (val: number) => val >= 4 && val <= 5 },
  ];
  */

  colorOptionsBasic = [
    { color: '#ADADAD', condition: (val: number) => isNaN(val) },
    { color: '#E65049', condition: (val: number) => val >= 0 && val <= 2 },
    { color: '#F29A53', condition: (val: number) => val > 2 && val < 4 },
    { color: '#3D894C', condition: (val: number) => val >= 4 && val <= 5 },
  ];

  colorOptionsMax3 = [
    { color: '#6464641C', condition: (val: number) => isNaN(val) },
    { color: '#D93F3059', condition: (val: number) => val >= 0 && val <= 1 },
    { color: '#ECBA4459', condition: (val: number) => val > 1 && val <= 2 },
    { color: '#3D894C80', condition: (val: number) => val > 2 && val <= 3 },
  ];

  sortedField = 'DisplayOrder';
  sortedDirection: 'up' | 'down' = 'up';

  get riskScoresOptions() {
    return this.$store.getters.riskScoresOptions;
  }

  get fiDataTableOptions() {
    return this.$store.getters.fiDataTableOptions;
  }

  get currentCompanyInfo() {
    return this.$store.getters.companyInfo;
  }

  get sortedTable() {
    const initTable = [...this.tableData];
    initTable.sort((a: any, b: any) => {
      if (a[this.sortedField] > b[this.sortedField]) {
        return this.sortedDirection === 'up' ? 1 : -1;
      }
      if (a[this.sortedField] < b[this.sortedField]) {
        return this.sortedDirection === 'down' ? 1 : -1;
      }
      return 0;
    });
    return initTable;
  }

  public fakeCompanyName() {
    function makeStr(length: any) {
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    let result = '';
    const wordCount = 1 + Math.floor(Math.random() * 3);
    for (let i = 0; i < wordCount; i++) {
      result += makeStr(3 + Math.floor(Math.random() * 7)) + ' ';
    }

    return result;
  }
}
