import { Plot } from '../Plot';
import { Point } from '../../Point';
import { MetaItem } from '../../series/SeriesXY';

export const drawPolicyBarsChart = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  plotMetaArr: MetaItem[]
) {
  for (let i = 0; i < plotData.length; i++) {
    const { mainSize, offset } = this._options;
    let barHeight = 20;
    if (plotMetaArr[i]) {
      const { color, size } = plotMetaArr[i];
      if (color) ctx.fillStyle = color;
      if (size) barHeight = size;
    }

    const p = new Point(plotData[i].x + offset.x, plotData[i].y + offset.y);
    ctx.beginPath();
    ctx.moveTo(p.x - mainSize, p.y);
    ctx.lineTo(p.x - mainSize, p.y - barHeight * 0.5);
    ctx.arc(p.x, p.y - barHeight * 0.5, mainSize, -1 * Math.PI, 0 * Math.PI);
    ctx.lineTo(p.x + mainSize, p.y + barHeight * 0.5);
    ctx.arc(p.x, p.y + barHeight * 0.5, mainSize, 0, 1 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }
};
