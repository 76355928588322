import { Rectangle } from '../Rectangle';
import { Point } from '../Point';
import { Label } from '../Label';

interface tooltipDrawOptions {
  lineWidth: number;
  lineColor: string;
  brushColor: string;
  mainSize: number;
  lineDash: number[];
  textLine?: textLineOptions;
}

export interface tooltipObject {
  ctx: CanvasRenderingContext2D;
  vp: Rectangle;
  coord: Point;
  data: Point;
  ind: number;
  step: number;
  plotData?: Point[];
  plotLabels?: string[];
  canvas?: Canvas;
  plotCallbacks?: Required<SeriesXY['callbacks']>;
}
// drawing methods
import { drawBarChartHighLighter } from './drawings/bar-chart-highlighter';
import { drawBarChartFullHeight } from './drawings/bar-chart-fullheight';
import { drawDateXBottom } from './drawings/date-x-bottom';
import { drawLabelSquareMarker } from './drawings/label-with-markers';
import { drawPolicyHoveredLabels } from './drawings/policy-hovered-labels';
import { textLineOptions } from '../plot/Plot';
import { Canvas } from '../Canvas';
import { SeriesXY } from '../series/SeriesXY';
import { drawPolicyHoveredLabels2leg } from './drawings/policy-hovered-labels-2leg';
import { drawLossSeverityLabel } from './drawings/loss-severity-label';
import { drawLabelXStart } from './drawings/draw-label-x-start';
import { drawSimpleLabel } from './drawings/draw-simple-label';
import { drawCircleSeries } from './drawings/draw-circle-series';
import { drawLineVerticalFull } from './drawings/draw-line-vertical-full';

export class Tooltip {
  _id: string;
  type:
    | 'bar_chart_highlighter'
    | 'bar_chart_fullheight'
    | 'circle_series'
    | 'line_vertical_full'
    | 'label_x_start'
    | 'date_x_bottom'
    | 'simple_label'
    | 'simple_label_square_markers'
    | 'policy_hovered_labels'
    | 'policy_hovered_labels_2leg'
    | 'loss_severity_label';
  _options: tooltipDrawOptions;
  labels?: any[];
  label: Label;
  onClickBinding?: signals.SignalBinding<any>;

  constructor(id: string, type: Tooltip['type'], ...options: any) {
    this._id = id;
    this.type = type;

    this._options = {
      lineWidth: 1,
      lineColor: '#000000',
      brushColor: '#000000',
      mainSize: 2,
      lineDash: [],
    };

    this.label = new Label();
    this.setOptions(options);
  }

  get id(): string {
    return this._id;
  }

  setOptionsPartially(options: Partial<tooltipDrawOptions>) {
    this._options = { ...this._options, ...options };
    return this;
  }

  setOptions(options: any[]) {
    switch (this.type) {
      case 'bar_chart_highlighter':
      case 'bar_chart_fullheight':
      case 'circle_series':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        this._options.mainSize = options[3];
        break;

      case 'label_x_start':
      case 'date_x_bottom':
      case 'simple_label_square_markers':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        this._options.mainSize = options[3];
        this.labels = options[4];
        break;

      case 'simple_label':
        this._options.lineWidth = options[0];
        this._options.lineColor = options[1];
        this._options.brushColor = options[2];
        this.labels = options[3];
        break;
    }
  }

  setLabels(labels: Tooltip['labels']) {
    this.labels = labels;
    return this;
  }

  drawTooltip(t: tooltipObject) {
    t.ctx.beginPath();
    switch (this.type) {
      case 'date_x_bottom':
        drawDateXBottom.call(this, t);
        break;

      case 'bar_chart_highlighter':
        drawBarChartHighLighter.call(this, t);
        break;

      case 'bar_chart_fullheight':
        drawBarChartFullHeight.call(this, t);
        break;

      case 'circle_series':
        drawCircleSeries.call(this, t);
        break;

      case 'line_vertical_full':
        drawLineVerticalFull.call(this, t);
        break;

      case 'label_x_start':
        drawLabelXStart.call(this, t);
        break;

      case 'simple_label':
        drawSimpleLabel.call(this, t);
        break;

      case 'policy_hovered_labels':
        drawPolicyHoveredLabels.call(this, t);
        break;

      case 'policy_hovered_labels_2leg':
        drawPolicyHoveredLabels2leg.call(this, t);
        break;

      case 'simple_label_square_markers':
        drawLabelSquareMarker.call(this, t);
        break;

      case 'loss_severity_label':
        drawLossSeverityLabel.call(this, t);
        break;
    }
  }
}
