import { Tooltip, tooltipObject } from '../Tooltip';

export const drawCircleSeries = function (this: Tooltip, t: tooltipObject) {
  t.ctx.strokeStyle = this._options.lineColor;
  t.ctx.lineWidth = this._options.lineWidth;
  t.ctx.fillStyle = this._options.brushColor;
  t.ctx.setLineDash(this._options.lineDash);
  t.ctx.beginPath();
  t.ctx.arc(t.coord.x, t.coord.y, this._options.mainSize, 0, Math.PI * 2, true);
  t.ctx.closePath();
  t.ctx.fill();
  t.ctx.stroke();
};
