import { store } from '../../index';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { ChTimeItem, getCbhTimeItem } from '@/utilities';

const RANGECOND_EARLY_WARNING = 'EarlyWarning#';

type ValueWithColor = {
  value: number;
  color: number;
};

export type EarlyWarningItem = {
  chTimeItem: ChTimeItem;
  WeekCommencing: string;
  ExecutionAndPersistence: ValueWithColor;
  MitreScore: ValueWithColor;
  InitialAccess1: ValueWithColor;
  InitialAccess2: ValueWithColor;
  InitialAccess3: ValueWithColor;
  ResourceDevelopment1: ValueWithColor;
  ResourceDevelopment2: ValueWithColor;
  Reconnaissance: ValueWithColor;
  HasSpike: Boolean;
  PeerGroup: 'sector' | 'industry';
};

const parseEarlyWarningItem = (item: any): EarlyWarningItem => ({
  chTimeItem: getCbhTimeItem(item['WeekCommencing']),
  WeekCommencing: item['WeekCommencing'],
  ExecutionAndPersistence: {
    value: Number(item['ExecutionAndPersistence']),
    color: Number(item['ExecutionAndPersistenceColor']),
  },
  MitreScore: {
    value: Number(item['MitreScore']),
    color: Number(item['MitreScoreColor']),
  },
  InitialAccess1: {
    value: Number(item['InitialAccess1']),
    color: Number(item['InitialAccess1Color']),
  },
  InitialAccess2: {
    value: Number(item['InitialAccess2']),
    color: Number(item['InitialAccess2Color']),
  },
  InitialAccess3: {
    value: Number(item['InitialAccess3']),
    color: Number(item['InitialAccess3Color']),
  },
  ResourceDevelopment1: {
    value: Number(item['ResourceDevelopment1']),
    color: Number(item['ResourceDevelopment1Color']),
  },
  ResourceDevelopment2: {
    value: Number(item['ResourceDevelopment2']),
    color: Number(item['ResourceDevelopment2Color']),
  },
  Reconnaissance: {
    value: Number(item['Reconnaissance']),
    color: Number(item['ReconnaissanceColor']),
  },
  HasSpike: item['HasSpike'] === 'TRUE' ? true : false,
  PeerGroup: item['PeerGroup'],
});

@Module({ dynamic: true, store, name: 'earlyWarning' })
class EarlyWarning extends VuexModule {
  private sectorYearMap: Record<number, EarlyWarningItem[]> = {};
  private industryYearMap: Record<number, EarlyWarningItem[]> = {};

  @Action({ commit: 'setEarlyWarningData' })
  public fetchEarlyWarningData() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_EARLY_WARNING)
      .then((data) => {
        if (data.length > 0) {
          return data;
        } else {
          throw new Error(RANGECOND_EARLY_WARNING + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  @Mutation
  public setEarlyWarningData(data: any[]) {
    if (data) {
      const [sectorMap, industryMap] = data.reduce<Record<string, EarlyWarningItem[]>[]>(
        (pre, cur) => {
          const [sMap, iMap] = pre;
          const parsedItem = parseEarlyWarningItem(cur);
          const { chTimeItem, PeerGroup } = parsedItem;
          switch (PeerGroup) {
            case 'sector':
              if (sMap[chTimeItem.year]) {
                sMap[chTimeItem.year].push(parsedItem);
                break;
              }
              sMap[chTimeItem.year] = [parsedItem];
              break;
            case 'industry':
              if (iMap[chTimeItem.year]) {
                iMap[chTimeItem.year].push(parsedItem);
                break;
              }
              iMap[chTimeItem.year] = [parsedItem];
              break;
          }
          return [sMap, iMap];
        },
        [{}, {}]
      );
      Object.assign(this.sectorYearMap, sectorMap);
      Object.assign(this.industryYearMap, industryMap);
    } else {
      Object.assign(this.sectorYearMap, {});
      Object.assign(this.industryYearMap, {});
    }
  }

  get sectorMap() {
    return this.sectorYearMap;
  }

  get industryMap() {
    return this.industryYearMap;
  }
}

export const EarlyWarningModule = getModule(EarlyWarning);
