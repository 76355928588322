
import { Component, Vue } from 'vue-property-decorator';

import Footer from '../Footer.vue';
import Preloader from '@/components/LoaderPage.vue';
import CompanyMenu from './CompanyMenu.vue';
import Alert from '@/components/Alert.vue';
import { EarlyWarningModule } from '@/store/modules/company-tabs/early-warning';
import { BreachProbabilityModule } from '@/store/modules/company-tabs/breach-probability';
import { LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import { RiskPostureAnalysisModule } from '@/store/modules/company-tabs/risk-posture-analysis';
import { PeerBenchmarkingModule } from '@/store/modules/company-tabs/peer-benchmarking';

@Component({
  components: {
    Preloader,
    CompanyMenu,
    Alert,
    Footer,
  },
})
export default class CompanyPage extends Vue {
  dataIsLoaded: boolean = false;

  mounted() {
    this.loadData();
  }

  loadData(isFirstLoad: boolean = false) {
    this.dataIsLoaded = false;
    // @ts-ignore
    let id: string = this.$route.params.companyId;
    if (id !== 'undefined') {
      this.$store.dispatch('loadCompanyTabsData', id).then(async () => {
        await Promise.allSettled([
          EarlyWarningModule.fetchEarlyWarningData(),
          BreachProbabilityModule.fetchCompanyBreachProbabilityCurrent(),
          LossSeverityModule.fetchLossSeverityPlots(),
          RiskPostureAnalysisModule.fetchRiskPostureAnalysisData(),
          PeerBenchmarkingModule.fetchPeerBenchmarkingTableData(),
          PeerBenchmarkingModule.fetchPeerBenchmarkingListData(),
        ]);

        if (isFirstLoad) {
          // TODO
          // this.goToFirstRoute(id);
        }
        this.dataIsLoaded = true;

        let title = 'Intangic CyFi™ Dashboard';
        if (this.$route.meta?.getTitle) {
          title = this.$route.meta.getTitle(this.$route.meta.title);
        }
        document.title = title;
      });
    } else {
      this.dataIsLoaded = true;
      console.warn('company ID is undefined');
    }
  }
}
