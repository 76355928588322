import { Plot } from '../Plot';
import { Point } from '../../Point';

export const drawCircle = function (this: Plot, ctx: CanvasRenderingContext2D, plotData: Point[]) {
  for (let i = 0; i < plotData.length; i++) {
    const { mainSize, offset, lineColor, brushColor, lineDash, lineWidth } = this._options;
    const p = new Point(plotData[i].x + offset.x, plotData[i].y + offset.y);
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = lineWidth;
    ctx.fillStyle = brushColor;
    ctx.setLineDash(lineDash);
    ctx.beginPath();
    ctx.arc(p.x, p.y, mainSize, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }
};
