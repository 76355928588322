const RANGECOND_corporateEvent = 'CyberAlert#';

import * as ddb from '../ddbUtils-config';
import { _queryJSON } from '../utils';

export const queryCompanyCyberAlerts = async (companyID: string) => {
  const q = _queryJSON(`${companyID}`, RANGECOND_corporateEvent);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  const mappedResult = items.map((el: any) => {
    /*
    const newRow = {
      Grouping: el.Grouping,
      DataType: el.DataType,
      name: el.name,
      image: el.image === '--' ? null : el.image,
      domain: el.domain === '--' ? null : el.domain,
    };
    */
    return el;
  });
  return mappedResult;
};
