var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alert__container"},[_c('img',{staticClass:"icon",class:{
      high: _vm.data.RaeRisk === 'High',
      medium: _vm.data.RaeRisk === 'Medium',
      low: _vm.data.RaeRisk === 'Low',
    },attrs:{"src":require("../assets/alert-svg-icon.svg")}}),_c('div',{staticClass:"text__container",class:{
      high: _vm.data.RaeRisk === 'High',
      medium: _vm.data.RaeRisk === 'Medium',
      low: _vm.data.RaeRisk === 'Low',
    }},[_c('p',{staticClass:"message"},[_vm._v("Alert: Dropped to "+_vm._s(_vm.data.RaeRisk)+" Risk")]),_c('p',{staticClass:"date-link"},[_c('span',[_vm._v(_vm._s(new Date(_vm.data.timeStamp).toLocaleDateString('eu')))]),_c('router-link',{attrs:{"to":{ name: 'CompanyCyData', params: { companyId: _vm.routerCompanyId } }}},[_vm._v(" Learn more ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }