import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';
import { parseCbhDate } from '@/utilities';

const RANGECOND_POLICY_TIMELINE_CURRENT = 'PolicyTimeline#current';

export interface PolicyTimelineItem {
  PolicyStartDate?: Date;
  PolicyEndDate?: Date;
  FirstTriggerDate?: Date;
  SecondTriggerDate?: Date;
  SecondTriggerChTimestamp?: Date;
  FirstTriggerDeactivateDate?: Date;
}

@Module
class PolicyTimelineModule extends VuexModule {
  private data: PolicyTimelineItem | undefined = undefined;

  @Action
  public async fetchCompanyPolicyTimelineCurrent() {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, RANGECOND_POLICY_TIMELINE_CURRENT)
      .then((data) => {
        if (data[0]) {
          store.commit('setCompanyPolicyTimelineCurrent', data[0]);
        } else {
          throw new Error(RANGECOND_POLICY_TIMELINE_CURRENT + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        store.commit('setCompanyPolicyTimelineCurrent', undefined);
      });
  }

  @Mutation
  public setCompanyPolicyTimelineCurrent(data: any) {
    if (data) {
      this.data = {
        ...data,
        PolicyStartDate: data['PolicyStartDate']
          ? parseCbhDate(data['PolicyStartDate'])
          : undefined,
        PolicyEndDate: data['PolicyEndDate'] ? parseCbhDate(data['PolicyEndDate']) : undefined,
        FirstTriggerDate: data['FirstTriggerDate']
          ? parseCbhDate(data['FirstTriggerDate'])
          : undefined,
        SecondTriggerDate: data['SecondTriggerDate']
          ? parseCbhDate(data['SecondTriggerDate'])
          : undefined,
        SecondTriggerChTimestamp: data['SecondTriggerChTimestamp']
          ? parseCbhDate(data['SecondTriggerChTimestamp'])
          : undefined,
        FirstTriggerDeactivateDate: data['FirstTriggerDeactivateDate']
          ? parseCbhDate(data['FirstTriggerDeactivateDate'])
          : undefined,
      };
    } else {
      this.data = data;
    }
  }

  get policyTimelineData(): PolicyTimelineModule['data'] {
    return this.data;
  }

  get secondTriggerDate() {
    return this.policyTimelineData?.SecondTriggerDate;
  }
}
export default PolicyTimelineModule;
