// Company Pages
const CompanyOverview = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/Overview/overview-container.vue' //webpackMode
  );
const CyDataPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/CyDataPage/index.vue');
const CyFiScoresPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/CyFiScoresPage/index.vue');
const FiDataPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/FiData/index.vue');

const SubsidiariesPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/Subsidiaries/index.vue');
const PeerBenchmarkingPage = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/PeerBenchmarkingPage/index.vue'
  );
const FullReportPage = () =>
  import(/* webpackChunkName: "admin" */ '@/pages/customer-pages/Company/FullReport/index.vue');
const ExecutiveSummaryEdit = () =>
  import(
    /* webpackChunkName: "admin" */ '@/pages/admin-pages/Company/Overview/ExecutiveSummaryEdit.vue'
  );

// children
import { peerbenchmarkingRoutes } from './peerbenchmarking-routes';
import { getAdminCompanyPageTitle } from './utils';

export const companyRoutes = [
  {
    component: CompanyOverview,
    path: 'overview',
    name: 'CompanyOverview',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Overview',
      getTitle: getAdminCompanyPageTitle,
    },
    children: [
      {
        component: ExecutiveSummaryEdit,
        path: 'executive-summary-edit',
        name: 'ExecutiveSummaryEdit',
        meta: {
          adminRoute: true,
          companyRoute: true,
          title: 'ExecutiveSummaryEdit',
          getTitle: getAdminCompanyPageTitle,
        },
      },
    ],
  },

  {
    component: CyFiScoresPage,
    path: 'cy_fi_scores',
    name: 'CompanyCyFiScores',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'CyFi scores',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: CyDataPage,
    path: 'cy_data',
    name: 'CompanyCyData',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Cy Data',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: FiDataPage,
    path: 'fi_data',
    name: 'CompanyFiData',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Fi Data',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: SubsidiariesPage,
    path: 'subsidiaries',
    name: 'CompanySubsidiaries',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Subsidiaries',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  /*
	{	component: NewsDataPage, path: '/news_data', name: 'CompanyNewsData',
		meta: { companyRoute: true, title: 'News Data' },
	},
	*/

  {
    component: FullReportPage,
    path: 'full_report',
    name: 'FullReportPage',
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Full Report',
      getTitle: getAdminCompanyPageTitle,
    },
  },

  {
    component: PeerBenchmarkingPage,
    path: 'peer_benchmarking',
    name: 'CompanyPeerBenchmarking',
    redirect: { name: 'SectorPeers' },
    meta: {
      adminRoute: true,
      companyRoute: true,
      title: 'Peer Benchmarking',
      getTitle: getAdminCompanyPageTitle,
    },
    children: [...peerbenchmarkingRoutes],
  },
];
