const RANGECOND = 'CompanyProfile#current';

import * as ddb from '../ddbUtils-config';
import { _queryJSON } from '../utils';

export const queryCompanyProfile = async (companyID: string) => {
  const q = _queryJSON(companyID, RANGECOND);
  let items: any = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  return items;
};
