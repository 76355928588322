import { store } from '../index';
import jwt_decode from 'jwt-decode';

import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

const storage = window.localStorage;

@Module
class Session extends VuexModule {
  private sessionUsername: string | null = null;
  private sessionDecodedToken: any = null;
  private loginError: string = '';
  private remember: boolean = storage.getItem('remeberOnThisComputer') === 'true' ? true : false;

  @Mutation
  public setRemember(value: boolean) {
    this.remember = value;
    storage.setItem('remeberOnThisComputer', String(this.remember));
    console.debug('setRemember', this.remember);
  }

  get rememberOnLogn(): boolean {
    return this.remember;
  }

  @Action
  async updateSessionInfo(token: string) {
    return Promise.all([
      store.dispatch('updateSessionUserName'),
      store.dispatch('updateToken', token),
    ]);
  }

  @Action
  updateSessionUserName() {
    const username: string = window.CbhRosie.getUserName();
    store.commit('setUserName', username);
    return true;
  }

  @Mutation
  public setUserName(username: string) {
    this.sessionUsername = username;
  }

  get username(): string | null {
    return this.sessionUsername;
  }

  @Action
  updateToken(token: string) {
    const decoded = jwt_decode(token);
    store.commit('setDecodedToken', decoded);
    if (this.isRestrictedUser) {
      store.commit('setTheme', 'light');
    } else {
      store.commit('setTheme', '');
    }
    return true;
  }

  @Mutation
  public setDecodedToken(token: any) {
    this.sessionDecodedToken = token;
  }

  get isRestrictedUser(): boolean {
    if (this.sessionDecodedToken && this.sessionDecodedToken['custom:companyID']) {
      return true;
    }
    return false;
  }

  get isAdvisoryUser(): boolean {
    if (
      this.sessionDecodedToken &&
      this.sessionDecodedToken['cognito:groups'] &&
      this.sessionDecodedToken['cognito:groups'][0]
    ) {
      return String(this.sessionDecodedToken['cognito:groups'][0]).includes('Advisory');
    }
    return false;
  }

  get isPolicyUser(): boolean {
    return this.isRestrictedUser && !this.isAdvisoryUser;
  }

  get userRedirectPath(): string {
    if (this.isRestrictedUser) {
      return `/policy/Company/${this.sessionDecodedToken['custom:companyID']}/overview`;
    }
    return '/admin/PreviouslyViewed';
  }

  get customerCompanyId() {
    if (this.isRestrictedUser) {
      return this.sessionDecodedToken['custom:companyID'];
    }
    return null;
  }

  @Mutation
  public setLoginError(error: string) {
    this.loginError = error;
  }

  get sessionLoginError(): string {
    return this.loginError;
  }

  @Mutation
  public setTheme(theme: string) {
    const htmlElement = document.documentElement;
    if (theme === '') {
      htmlElement.removeAttribute('theme');
      console.log('🎈 Theme: removed');
    } else {
      htmlElement.setAttribute('theme', theme);
      console.log('🎈 Set theme: = ', theme);
    }
  }

  get theme() {
    return document.documentElement.getAttribute('theme');
  }
}
export default Session;
