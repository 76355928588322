import { Plot } from '../Plot';
import { Point } from '../../Point';

export const drawCurveLine = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[]
) {
  ctx.lineCap = 'round';

  ctx.setLineDash(this._options.lineDash);
  ctx.beginPath();
  ctx.moveTo(plotData[0].x, plotData[0].y);

  for (let i = 1; i < plotData.length - 1; i++) {
    const xc = (plotData[i].x + plotData[i + 1].x) / 2;
    const yc = (plotData[i].y + plotData[i + 1].y) / 2;
    ctx.quadraticCurveTo(plotData[i].x, plotData[i].y, xc, yc);
  }

  ctx.lineTo(plotData[plotData.length - 1].x, plotData[plotData.length - 1].y);

  ctx.stroke();
};
