
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Meta } from '@/types/meta';
// @ts-ignore
import ClickOutside from 'vue-click-outside';

@Component({ directives: { ClickOutside } })
export default class MetaLabel extends Vue {
  @Prop() valueMeta!: Meta;
  @Prop() displayedValue?: any;

  showDropDown = false;
  preventClosing = false;

  get reported(): boolean {
    return this.$route.query.reported === this.fullDataType;
  }

  get fullDataType(): string {
    return (this.valueMeta.rowDataType + '#' + this.valueMeta.fieldName).split('#').join(' ');
  }

  get reportLink() {
    const searchParams = new URLSearchParams();
    searchParams.append('reported', this.fullDataType);
    const query = '&' + searchParams.toString();
    const link = window.location.origin + '/#' + this.$route.fullPath + query;
    return link;
  }

  toggleDropDown() {
    if (this.$route.query.reported) return;
    this.showDropDown = !this.showDropDown;
    this.preventClosing = true;
  }

  closeDropDown() {
    if (this.preventClosing) {
      this.preventClosing = false;
      return;
    }
    if (this.showDropDown) this.showDropDown = false;
  }

  closeReport() {
    this.$router.replace({
      path: this.$route.path,
      query: { ...this.$route.query, reported: undefined },
    });
  }
}
