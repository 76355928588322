import { Plot } from '../Plot';
import { Point } from '../../Point';
import { MetaItem } from '../../series/SeriesXY';

export const drawArrowsWithAngle = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  plotMetaArr: MetaItem[]
) {
  const { mainSize, offset } = this._options;
  const halfWidth = Math.round(mainSize * 0.5);
  const headWidth = Math.round(halfWidth * 0.3);
  const headHeight = Math.round(halfWidth * 0.65);

  for (let i = 0; i < plotData.length; i++) {
    const p = new Point(plotData[i].x + offset.x, plotData[i].y + offset.y);
    const angle = plotMetaArr[i]?.angle;
    ctx.save();
    ctx.translate(p.x, p.y);
    if (angle) ctx.rotate((Math.PI / 180) * angle);
    ctx.beginPath();
    ctx.moveTo(-halfWidth, 0);
    ctx.lineTo(halfWidth, 0);
    ctx.lineTo(headWidth, 0 - headHeight);
    ctx.moveTo(halfWidth, 0);
    ctx.lineTo(headWidth, 0 + headHeight);
    ctx.stroke();
    ctx.translate(-p.x, -p.y);
    ctx.restore();
  }
};
