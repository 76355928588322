import * as AWS from 'aws-sdk';

const parseDDBDataResponse: Function = function (
  data: Record<string, any>
): Array<Record<string, string>> | null {
  if ('Items' in data) {
    const res: Array<Record<string, string>> = [];
    for (const it of data.Items) {
      const row: Record<string, string> = {};
      const keys = Object.keys(it);
      keys.forEach((el) => {
        if (it[el].S == 'NA') {
          row[el] = '  --';
        } else {
          row[el] = it[el].S;
        }
      });
      res.push(row);
    }
    return res;
  } else {
    return null;
  }
};

const getAllData = async (
  ddb: AWS.DynamoDB.DocumentClient,
  query: any
): Promise<AWS.DynamoDB.QueryOutput> => {
  const data: AWS.DynamoDB.QueryOutput = await ddb.query(query).promise();

  if (data['Items'] == undefined) {
    throw new Error('Items not defined');
  }

  if (data.LastEvaluatedKey) {
    query.ExclusiveStartKey = data.LastEvaluatedKey;
    const restOfData: AWS.DynamoDB.QueryOutput = await getAllData(ddb, query);
    if (restOfData['Items']!.length > 0 && data['Items'].length > 0) {
      // If previous and current query outputs have Items => Merge them
      data.Items = [...data['Items'], ...restOfData['Items']!];
      return data;
    } else if (restOfData['Items']!.length > 0 && data['Items'].length == 0) {
      // If the current query output does not have any Items => Only use the previous query outputs
      return restOfData;
    } else {
      // If the previous and/or the current outputs are empty => Simply return the current output
      return data;
    }
  } else {
    return data;
  }
};

export const executeQuery: Function = function (
  query: any,
  paginated: boolean = false
): Promise<any> {
  return new Promise((resolve, reject) => {
    if (AWS.config.credentials == null || AWS.config.credentials === undefined) {
      console.error('No credentials found');
      reject('No credentials found');
    }
    // It is actually of type CognitoIdentityCredentials but it is not defined
    const cred: any = AWS.config.credentials;

    cred.get((err: Error) => {
      if (!err) {
        const ddb = new AWS.DynamoDB.DocumentClient({ apiVersion: '2012-08-10' });
        if (paginated) {
          getAllData(ddb, query)
            .then((data: AWS.DynamoDB.QueryOutput) => {
              const parsedOutput = parseDDBDataResponse(data);
              resolve(parsedOutput);
            })
            .catch((err: Error) => {
              reject(err);
            });
        } else {
          ddb.query(query, (err: AWS.AWSError, data: AWS.DynamoDB.QueryOutput) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        }
      } else {
        reject(err);
      }
    });
  });
};
