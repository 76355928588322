import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';

export const lineTrianglesVertical = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle
) {
  const { x1, x2, y1, y2 } = this._options.vpOffset;
  const { mainSize } = this._options;
  const rect = new Rectangle(vp.x1 + x1, vp.y1 + y1, vp.x2 + x2, vp.y2 + y2);
  for (let i = 0; i < plotData.length; i++) {
    ctx.beginPath();
    ctx.moveTo(plotData[i].x, rect.y1);
    ctx.lineTo(plotData[i].x - mainSize * 0.5, rect.y1 - mainSize);
    ctx.lineTo(plotData[i].x + mainSize * 0.5, rect.y1 - mainSize);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(plotData[i].x, rect.y2);
    ctx.lineTo(plotData[i].x - mainSize * 0.5, rect.y2 + mainSize);
    ctx.lineTo(plotData[i].x + mainSize * 0.5, rect.y2 + mainSize);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  }
};
