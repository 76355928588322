import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '../company';
import qs from 'qs';

// @ts-ignore
import awsConfig from '../../../environments/awsconfig.js';
const { apiGateWayUrl } = awsConfig;

@Module
class EodHistoricalModule extends VuexModule {
  private stockMonthPriceData: any[] = [];

  get companyMonthStockPrice() {
    return this.stockMonthPriceData;
  }

  @Mutation
  public setMonthStockPriceData(data: any[]) {
    this.stockMonthPriceData = data;
  }

  @Action
  public async fetchMonthStockPrice(companyID: string) {
    try {
      const codeExchage = store.getters.companyEod;
      if (!codeExchage) {
        console.warn('EODlist has no data for: ', companyID);
        throw new Error('cant make fetchStockPrice request without codeExchage');
      }
      const timeNow = new Date();
      const timeMonthAgo = new Date();
      timeMonthAgo.setMonth(timeMonthAgo.getMonth() - 1);

      const timeToStr = (date: Date): string => {
        const timeArr = date
          .toLocaleDateString('en', { year: 'numeric', month: '2-digit', day: '2-digit' })
          .split('/');
        return [timeArr[2], timeArr[0], timeArr[1]].join('-');
      };

      const q = qs.stringify({
        codeExchange: `${codeExchage.code}.${codeExchage.exchange}`,
        from: timeToStr(timeMonthAgo),
        to: timeToStr(timeNow),
        period: 'd',
      });

      const cognitoToken = await window.CbhRosie.getAuthToken();
      return api({
        url: `${apiGateWayUrl}fiData/stockPrice/?${q}`,
        method: 'get',
        headers: {
          Authorization: cognitoToken,
        },
      })
        .then(async (response) => {
          const result = response.data;
          if (result.success) {
            store.commit('setMonthStockPriceData', result.data);
          } else {
            throw new Error(`AWS  /fiData/stockPrice success = false`);
          }
        })
        .catch((error) => {
          console.error(`load stockPriceData failed`);
          store.commit('setMonthStockPriceData', []);
        });
    } catch (err) {
      // @ts-ignore
      console.error(err.message);
      store.commit('setMonthStockPriceData', []);
    }
  }
}
export default EodHistoricalModule;
