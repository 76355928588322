import { Ticks } from '../Ticks';
import { Rectangle } from '../../Rectangle';
import { Point } from '../../Point';
import { Transformer } from '../../Transformer';

export const generateTimestampMonthStart = function (
  this: Ticks,
  min: number,
  max: number,
  vp: Rectangle,
  dateToLabelFunc: (date: Date) => string = (date: Date) =>
    date.toLocaleString('en-us', { month: 'short', timeZone: 'UTC' }).toLocaleUpperCase()
): Point[] {
  const coords: Point[] = [];
  this.values = [];
  this.labels = [];

  const transformer = new Transformer();
  const fromRect = new Rectangle(min, 0, max, 1);

  const startTimestamp = min;
  const startDate = new Date(startTimestamp);
  const endTimestamp = max;
  const startDateFirstMonthDay_timestamp = Date.UTC(
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(),
    1
  );

  let curTimeStamp =
    startTimestamp === startDateFirstMonthDay_timestamp
      ? startDateFirstMonthDay_timestamp
      : Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, 1);

  let i = 0;

  while (curTimeStamp <= endTimestamp) {
    i++;
    const value = curTimeStamp;
    const coordPoint = transformer.getVeiwportCoord(fromRect, vp, new Point(value, 0));
    coords.push(coordPoint);
    this.values.push(value);
    const curDate = new Date(curTimeStamp);
    this.labels.push(dateToLabelFunc(curDate));
    curTimeStamp = Date.UTC(curDate.getUTCFullYear(), curDate.getUTCMonth() + 1, 1);
    if (i > 1000) throw new Error('generateTimestampMonthStart infinite loop');
  }

  return coords;
};
