
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CollapseSection extends Vue {
  isCollapsed: boolean = true;

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
