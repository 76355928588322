import { Plot } from '../Plot';
import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { MetaItem } from '../../series/SeriesXY';

export const drawPolicyCircles = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[],
  vp: Rectangle,
  plotMetaArr: MetaItem[]
) {
  for (let i = 0; i < plotData.length; i++) {
    const p = plotData[i];
    const { mainSize, offset } = this._options;
    if (plotMetaArr[i]) {
      const { color } = plotMetaArr[i];
      if (color) ctx.fillStyle = color;
    }
    ctx.beginPath();
    ctx.arc(p.x + offset.x, p.y + offset.y, mainSize, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();
  }
};
