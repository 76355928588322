import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { queryCompanyScaledRaeData } from '@/api-rosie/company/scaled-rae-data';

import { ScaledRaeData } from '@/types';

@Module
class ScaledRaeDataModule extends VuexModule {
  private scaledRaeAlertList: ScaledRaeData[] = [];

  @Action
  public async fetchScaledRaeData(id: string) {
    return queryCompanyScaledRaeData(id)
      .then((data) => {
        store.commit('setScaledRaeData', data);
      })
      .catch((err) => {
        console.error(`🚫 load scaled-rae-alert failed`);
        store.commit('setScaledRaeData', []);
      });
  }

  @Mutation
  public setScaledRaeData(items: ScaledRaeData[]) {
    this.scaledRaeAlertList.splice(0, this.scaledRaeAlertList.length, ...items);
  }

  get companyScaledRaeDataList(): ScaledRaeData[] {
    const list: ScaledRaeData[] = this.scaledRaeAlertList;
    return [...list.reverse()];
  }
}
export default ScaledRaeDataModule;
