import * as ddb from './ddbUtils-config';
// @ts-ignore
import awsConfig from '../environments/awsconfig.js';
const { tableName } = awsConfig;

import { CyFiStatsRow, cyFiStatsMetaFieldNames } from '@/types';
import { Meta } from '@/types/meta';

export const _toNumber = (value: string): number => {
  if (isNaN(+value)) return 0;
  return +value;
};

export const _toNumberDash = (value: string): number | '-' => {
  if (isNaN(+value)) return '-';
  if (value === '') return '-';
  return +value;
};

export const _queryJSON = (id: string, rangecond: string): any => {
  return {
    TableName: tableName,
    KeyConditionExpression: '#HASH = :hashKey and begins_with(#RANGE, :rangeCond)',
    ExpressionAttributeNames: {
      '#HASH': 'Grouping',
      '#RANGE': 'DataType',
    },
    ExpressionAttributeValues: {
      ':hashKey': id,
      ':rangeCond': rangecond,
    },
  };
};

export const fetchDdbQuery = async (companyID: string, rangeCond: string) => {
  const q = _queryJSON(companyID, rangeCond);
  let items: unknown[] = [];
  let data = await ddb.executeQuery(q);
  items = [...items, ...data.Items];
  while (typeof data.LastEvaluatedKey != 'undefined') {
    q.ExclusiveStartKey = data.LastEvaluatedKey;
    data = await ddb.executeQuery(q);
    items = [...items, ...data.Items];
  }
  return items;
};

// helper
export const parseCyFiStatsRow = (row: any): CyFiStatsRow => {
  return {
    ...row,
    ChTimestamp: new Date(row.ChTimestamp),
    CompanyID: row.CompanyID,
    Grouping: row.Grouping,
    DataType: row.DataType,
    UnderwritingStatus: row.UnderwritingStatus,
    MarketCapitalization: _toNumberDash(row.MarketCapitalization),

    SectorScore: _toNumberDash(row.SectorScore),
    SectorScore_3ma: _toNumberDash(row.SectorScore_3ma),
    SectorScore_12ma: _toNumberDash(row.SectorScore_12ma),
    SectorScore_24ma: _toNumberDash(row.SectorScore_24ma),

    Productivity: _toNumberDash(row.Productivity),
    Productivity_3ma: _toNumberDash(row.Productivity_3ma),
    Productivity_12ma: _toNumberDash(row.Productivity_12ma),
    Productivity_24ma: _toNumberDash(row.Productivity_24ma),

    Attack: _toNumberDash(row.Attack),
    Attack_3ma: _toNumberDash(row.Attack_3ma),
    Attack_12ma: _toNumberDash(row.Attack_12ma),
    Attack_24ma: _toNumberDash(row.Attack_24ma),

    Recon: _toNumberDash(row.Recon),
    Recon_3ma: _toNumberDash(row.Recon_3ma),
    Recon_12ma: _toNumberDash(row.Recon_12ma),
    Recon_24ma: _toNumberDash(row.Recon_24ma),

    Exfil: _toNumberDash(row.Exfil),
    Exfil_3ma: _toNumberDash(row.Exfil_3ma),
    Exfil_12ma: _toNumberDash(row.Exfil_12ma),
    Exfil_24ma: _toNumberDash(row.Exfil_24ma),

    FinancialExposure: _toNumberDash(row.FinancialExposure),
    FinancialExposure_3ma: _toNumberDash(row.FinancialExposure_3ma),
    FinancialExposure_12ma: _toNumberDash(row.FinancialExposure_12ma),
    FinancialExposure_24ma: _toNumberDash(row.FinancialExposure_24ma),

    CyberRank: _toNumberDash(row.CyberRank),
    CyberRank_3ma: _toNumberDash(row.CyberRank_3ma),
    CyberRank_12ma: _toNumberDash(row.CyberRank_12ma),
    CyberRank_24ma: _toNumberDash(row.CyberRank_24ma),

    CapitalEfficiency: _toNumberDash(row.CapitalEfficiency),
    CapitalEfficiency_3ma: _toNumberDash(row.CapitalEfficiency_3ma),
    CapitalEfficiency_12ma: _toNumberDash(row.CapitalEfficiency_12ma),
    CapitalEfficiency_24ma: _toNumberDash(row.CapitalEfficiency_24ma),

    CapitalManagement: _toNumberDash(row.CapitalManagement),
    CapitalManagement_3ma: _toNumberDash(row.CapitalManagement_3ma),
    CapitalManagement_12ma: _toNumberDash(row.CapitalManagement_12ma),
    CapitalManagement_24ma: _toNumberDash(row.CapitalManagement_24ma),

    OperatingEfficiency: _toNumberDash(row.OperatingEfficiency),
    OperatingEfficiency_3ma: _toNumberDash(row.OperatingEfficiency_3ma),
    OperatingEfficiency_12ma: _toNumberDash(row.OperatingEfficiency_12ma),
    OperatingEfficiency_24ma: _toNumberDash(row.OperatingEfficiency_24ma),

    Momo: _toNumberDash(row.Momo),
    Momo_3ma: _toNumberDash(row.Momo_3ma),
    Momo_12ma: _toNumberDash(row.Momo_12ma),
    Momo_24ma: _toNumberDash(row.Momo_24ma),
  };
};

export const parseCyFiStatsRowMeta = (row: any, q: any) => {
  return {
    ...row,
    DisplayOrder: _toNumber(row.DisplayOrder),
    ChTimestamp: new Date(row.ChTimestamp),
    MarketCapitalization: _toNumberDash(row.MarketCapitalization),
    ...cyFiStatsMetaFieldNames.reduce((prev: { [key: string]: any }, fieldName) => {
      prev[fieldName] = new Meta(row, fieldName, q, _toNumberDash);
      return prev;
    }, {}),
  };
};
