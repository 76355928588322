import { Point } from '../../Point';
import { Rectangle } from '../../Rectangle';
import { Tooltip, tooltipObject } from '../Tooltip';

const fitInViewport = (labelCoord: Point, viewport: Rectangle, labelRect: Rectangle) => {
  if (labelRect.x1 < viewport.x1) {
    labelCoord.x = labelCoord.x + (viewport.x1 - labelRect.x1);
  }
  if (labelRect.x2 > viewport.x2) {
    labelCoord.x = labelCoord.x - (labelRect.x2 - viewport.x2);
  }
};

export const drawPolicyHoveredLabels = function (this: Tooltip, t: tooltipObject) {
  const { ctx, plotData, plotLabels, coord, canvas, plotCallbacks, vp } = t;

  if (!(plotData && plotLabels)) return;

  for (let i = 0; i < plotData.length; i++) {
    ctx.strokeStyle = this._options.lineColor;
    ctx.lineWidth = this._options.lineWidth;
    ctx.fillStyle = this._options.brushColor;
    const p = plotData[i];
    const labelText = plotLabels[i];
    let labelCoord = new Point(p.x, p.y);
    if (this._options.textLine) {
      const { before, length, after } = this._options.textLine;
      labelCoord = new Point(p.x, p.y - before - length - after);
      ctx.beginPath();
      ctx.moveTo(p.x, p.y - before);
      ctx.lineTo(p.x, p.y - before - length);
      ctx.closePath();
      ctx.stroke();
    }
    if (canvas) {
      const labelRect = this.label.getlabelRect(ctx, labelCoord, labelText);
      fitInViewport(labelCoord, canvas.viewport, labelRect);
    }
    this.label.draw(ctx, labelCoord, labelText, coord);
  }

  if (canvas) {
    if (this.onClickBinding) this.onClickBinding.detach();
    this.onClickBinding = canvas.onCanvasClicked.add(() => {
      for (let i = 0; i < plotData.length; i++) {
        const p = plotData[i];
        const labelText = plotLabels[i];
        let labelCoord = new Point(p.x, p.y);
        if (this._options.textLine) {
          const { before, length, after } = this._options.textLine;
          labelCoord = new Point(p.x, p.y - before - length - after);
        }
        const labelRect = this.label.getlabelRect(ctx, labelCoord, labelText);
        fitInViewport(labelCoord, canvas.viewport, labelRect);
        if (this.label.isCoordInClickArea(ctx, labelCoord, labelText, coord)) {
          if (plotCallbacks) {
            const labelRect = this.label.getlabelRect(ctx, labelCoord, labelText);
            plotCallbacks[i]({
              left: labelCoord.x,
              bottom: vp.height - labelRect.y1,
            });
          }
        }
      }
    });
  }
};
