import { Plot } from '../Plot';
import { Point } from '../../Point';

export const drawPolicyBackgroundPattern = function (
  this: Plot,
  ctx: CanvasRenderingContext2D,
  plotData: Point[]
) {
  if (plotData.length < 2) return;
  const { mainSize, offset } = this._options;
  const start = plotData[0];
  const end = plotData[plotData.length - 1];

  ctx.beginPath();
  ctx.arc(start.x + offset.x, start.y + offset.y, mainSize, -0.5 * Math.PI, 0.5 * Math.PI, true);
  ctx.moveTo(start.x + offset.x, start.y + offset.y + mainSize);

  for (let i = 1; i < plotData.length - 1; i++) {
    const p = plotData[i];
    ctx.lineTo(p.x + offset.x, p.y + offset.y + mainSize);
  }

  ctx.lineTo(end.x + offset.x, end.y + offset.y + mainSize);
  ctx.arc(end.x + offset.x, end.y + offset.y, mainSize, 0.5 * Math.PI, -0.5 * Math.PI, true);
  ctx.lineTo(start.x + offset.x, start.y + offset.y - mainSize);

  ctx.closePath();

  ctx.globalAlpha = this._options.opacity;

  const patternCanvas = document.createElement('canvas');
  const patternContext = patternCanvas.getContext('2d');

  patternCanvas.width = 12;
  patternCanvas.height = start.y + offset.y + mainSize;
  if (patternContext) {
    patternContext.strokeStyle = this._options.lineColor;
    patternContext.lineWidth = this._options.lineWidth;
    patternContext.lineCap = 'round';
    patternContext.beginPath();
    for (let i = -2; i <= 2; i++) {
      patternContext.moveTo(i * 4, start.y + offset.y + mainSize);
      patternContext.lineTo(12 + i * 4, start.y + offset.y - mainSize);
    }
    patternContext.stroke();
  }
  const pattern = ctx.createPattern(patternCanvas, 'repeat-x');
  if (pattern) {
    ctx.fillStyle = pattern;
  }
  ctx.fill();
};
