import * as AWS from 'aws-sdk';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { store } from '@/store';

export class Rosie {
  config: any;
  // userPool: AmazonCognitoIdentity.CognitoUserPool;

  constructor(config: any) {
    this.config = config;
    AWS.config.region = config.poolData.region;
    // this.userPool = this.initUserPool(this.config);
  }

  initCockieStorage(): AmazonCognitoIdentity.CookieStorage {
    const domain = window.location.hostname;
    console.debug(domain);
    const expireDays = store.getters.rememberOnLogn ? 365 : 1;
    return new AmazonCognitoIdentity.CookieStorage({
      domain: domain,
      expires: expireDays,
      secure: false,
    });
  }

  initUserPool(config: any): AmazonCognitoIdentity.CognitoUserPool {
    const poolData = {
      UserPoolId: config.poolData.UserPoolId,
      ClientId: config.poolData.ClientId,
      // region: config.poolData.region,
      Storage: this.initCockieStorage(),
    };
    return new AmazonCognitoIdentity.CognitoUserPool(poolData);
  }

  logIn(
    email: string,
    password: string,
    succesCallback: () => void,
    errorCallback: (err: AWS.AWSError) => void
  ) {
    // this.userPool = this.initUserPool(this.config);
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const cognitoUser: AmazonCognitoIdentity.CognitoUser = this.createCognitoUser(email);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res: any): void => {
        const token = res.getIdToken().getJwtToken();
        this.updateAwsCreds(token).then(() => {
          succesCallback();
        });
      },
      onFailure: (err: AWS.AWSError): void => {
        this.showError(err.message);
        errorCallback(err);
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        console.error('newPasswordRequired');
      },
    });
  }

  signOut() {
    return new Promise<string>((resolve, reject) => {
      const userPool = this.initUserPool(this.config);
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser !== null) {
        const msg = 'user: ' + this.getUserName() + ' was succesfeully signout';
        cognitoUser.signOut();
        resolve(msg);
      }
      reject(new Error('failed to signOut user'));
    });
  }

  async checkAuth(): Promise<string> {
    return this.getAuthToken();
  }

  // helper methods
  showError(msg: string): void {
    console.error('Error: ', msg);
  }

  createCognitoUser(email: string): AmazonCognitoIdentity.CognitoUser {
    const userPool = this.initUserPool(this.config);
    return new AmazonCognitoIdentity.CognitoUser({
      Username: email,
      Pool: userPool,
      Storage: this.initCockieStorage(),
    });
  }

  getUserName() {
    const userPool = this.initUserPool(this.config);
    const currUser = userPool.getCurrentUser();
    if (currUser != null) {
      return currUser.getUsername();
    } else {
      console.error('Current User not found');
      return '';
    }
  }

  getAuthToken(): Promise<string> {
    const userPool = this.initUserPool(this.config);
    const cognitoUser = userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.getSession((err: Error, session: any) => {
          if (err) {
            reject(err);
          } else if (!session.isValid()) {
            reject(new Error('session.isValid() = false'));
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        });
      } else {
        reject(new Error('cognitoUser not exists'));
      }
    });
  }

  updateAwsCreds(token: string) {
    const providerName =
      'cognito-idp.' + AWS.config.region + '.amazonaws.com/' + this.config.poolData.UserPoolId;
    // @ts-ignore
    const loginProp: Record<string, string> = {};
    loginProp[providerName] = token;
    const newCreds = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.config.identityPoolID,
      Logins: loginProp,
    });
    AWS.config.update({ credentials: newCreds });

    return new Promise((resolve, reject) => {
      // @ts-ignore
      AWS.config.credentials.get((error) => {
        if (error) {
          // @ts-ignore
          AWS.config.credentials.refresh((error) => {
            if (error) {
              console.error('credentials refresh error:', error);
              reject(error);
            } else {
              console.warn('credentials refreshed');
              resolve(true);
            }
          });
        } else {
          console.warn('credentials updated');
          resolve(true);
        }
      });
    });
  }
}
