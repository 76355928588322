import { store } from '../../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { fetchDdbQuery } from '@/api-rosie/utils';

const RANGECOND_PEER_COMPS_CURRENT = 'CompanyPeerComps#';

const preparePeerCompItem = (item: any): PeerCompItem => ({
  DataType: String(item['DataType']),
  PeerCompanyId: String(item['DataType']).split('#')[2],
  GroupingCompanyName: String(item['GroupingCompanyName']),
  CurrentLossRank: Number(item['CurrentLossRank']),
  Prior1MonthLossOdds: Number(item['Prior1MonthLossOdds']),
  Prior2MonthLossOdds: Number(item['Prior2MonthLossOdds']),
  Grouping: String(item['Grouping']),
  CompanyName: String(item['CompanyName']),
  Prior4MonthLossOdds: Number(item['Prior4MonthLossOdds']),
  Prior3MonthLossOdds: Number(item['Prior3MonthLossOdds']),
  Prior5MonthLossOdds: Number(item['Prior5MonthLossOdds']),
  CurrentLossOdds: Number(item['CurrentLossOdds']),
  ChTimestamp: new Date(item['ChTimestamp']),
  GroupingCompanyID: String(item['GroupingCompanyID']),
  CurrentBreachProb: Number(item['CurrentBreachProb']),
  CurrentBreachRank: Number(item['CurrentBreachRank']),
  Prior1MonthBreachProb: Number(item['Prior1MonthBreachProb']),
  Prior2MonthBreachProb: Number(item['Prior2MonthBreachProb']),
  Prior3MonthBreachProb: Number(item['Prior3MonthBreachProb']),
  Prior4MonthBreachProb: Number(item['Prior4MonthBreachProb']),
  Prior5MonthBreachProb: Number(item['Prior5MonthBreachProb']),
});

export interface PeerCompItem {
  DataType: string;
  PeerCompanyId: string;
  GroupingCompanyName: string;
  CurrentLossRank: number;
  Prior1MonthLossOdds: number;
  Prior2MonthLossOdds: number;
  Grouping: string;
  CompanyName: string;
  Prior4MonthLossOdds: number;
  ChTimestamp: Date;
  Prior3MonthLossOdds: number;
  Prior5MonthLossOdds: number;
  GroupingCompanyID: string;
  CurrentLossOdds: number;
  CurrentBreachProb: number;
  CurrentBreachRank: number;
  Prior1MonthBreachProb: number;
  Prior2MonthBreachProb: number;
  Prior3MonthBreachProb: number;
  Prior4MonthBreachProb: number;
  Prior5MonthBreachProb: number;
}

@Module
class CompanyPeerCompsModule extends VuexModule {
  private list: PeerCompItem[] = [];

  @Action
  public async fetchCompanyPeerComps(chTimestamp: string) {
    const companyId = store.getters.companyId;
    return fetchDdbQuery(companyId, `${RANGECOND_PEER_COMPS_CURRENT}${chTimestamp}`)
      .then((dataList) => {
        if (dataList) {
          store.commit('setCompanyPeerComps', dataList);
        } else {
          throw new Error(RANGECOND_PEER_COMPS_CURRENT + 'request is empty');
        }
      })
      .catch((err) => {
        console.error(err);
        store.commit('setCompanyPeerComps', []);
      });
  }

  @Mutation
  public setCompanyPeerComps(data: any[]) {
    let resArr: PeerCompItem[] = [];
    if (Array.isArray(data)) {
      resArr = data.map((el) => preparePeerCompItem(el));
    }
    this.list.splice(0, this.list.length, ...resArr);
  }

  get companyPeerCompsList() {
    return this.list;
  }
}
export default CompanyPeerCompsModule;
