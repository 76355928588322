import Vue from 'vue';
import App from './App.vue';
import { store } from './store';
import router from './router';

import WebFont from 'webfontloader';
import VueClipboard from 'vue-clipboard2';
// @ts-ignore
import ClickOutside from 'vue-click-outside';
// import { initAmazonCognito } from './api-rosie/rosie-AWS';
// initAmazonCognito();
import { Rosie } from './api-rosie/Rosie';
// @ts-ignore
import awsConfig from './environments/awsconfig.js';

declare global {
  interface Window {
    CbhRosie: Rosie;
  }
}

window.CbhRosie = new Rosie(awsConfig);

Vue.use(VueClipboard);
Vue.directive('click-outside', ClickOutside);

// fonts loader
WebFont.load({
  custom: {
    families: ['Transcript Pro', 'Akkurat Pro', 'Inter', 'JetBrains Mono'],
  },
  async active() {
    Vue.config.productionTip = false;
    try {
      await window.CbhRosie.checkAuth().then((token: string) => {
        return window.CbhRosie.updateAwsCreds(token);
      });
    } catch (e) {
      console.warn('Initial auth failed');
    }
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount('#app');
  },
});
